import Cookies from 'js-cookie';
import {environment} from '@/commons/configs/Config';

export function hasAccess(power) {
    let minPower = 20;
    return power >= minPower;
}


export function getToken() {
    const userToken = Cookies.get(environment.tokenName);
    return userToken;
}

export async function validateLogin(userToken) {
    if (userToken === null || userToken === undefined || userToken === "") {
        return null;
    }

    const result = fetch(environment.apiUrlOne + '/details/account/', {
        method: 'GET',
        headers: {
            'Authorization': userToken
        }
    })

    const rest = await result;
    return rest
}

export async function getUser(userToken) {
    let res = await validateLogin(userToken);
    let json = await res.json();

    return json;
}

export function isLoginValid(r) {
    if (r === null) {
        return false;
    }
    if (r.status === 200) {
        return true;
    } else if (r.status === 401) {
        Cookies.remove(environment.tokenName);
    }
    return false;
}

export async function getMinecraft(token) {
    if (token === null || token === undefined || token === "") {
        return null;
    }

    const result = fetch(environment.apiUrlOne + '/details/minecraft/', {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })

    const rest = await result;
    return rest
}

export async function getMinecraftOther(id) {
    let token = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/details/minecraft/' + id, {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })

    return await result
}

export async function getTwitchOther(id) {
    let token = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/details/twitch/' + id, {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })

    return await result
}

export async function getTelegramOther(id) {
    let token = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlTwo + '/telegram/get/' + id, {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })

    return await result
}

export async function getDetails(token) {
    if (token === null || token === undefined || token === "") {
        token = Cookies.get(environment.tokenName);
    }
    const result = fetch(environment.apiUrlOne + '/details/account/', {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })

    const rest = await result;
    return rest
}

export async function getAllRoles(token) {
    const result = fetch(environment.apiUrlOne + '/details/allRoles/', {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })

    const rest = await result;
    return rest
}

export async function getUserPower(token) {
    let res = await getDetails(token);
    let rolesRes = await getAllRoles(token);
    if (rolesRes.status !== 200) {
        return -1;
    }
    if (res.status !== 200) {
        return -1;
    }
    let rolesJson = await rolesRes.json();

    let json = await res.json();
    let role = json["role"];

    for (let i = 0; i < rolesJson.length; i++) {
        if (rolesJson[i]["alias"].toLowerCase() === role.toLowerCase()) {
            return rolesJson[i]["power"];
        }
    }
    return 0;
}

export async function isMinecraft(r) {
    if (r.status === 200) {
        return true;
    } else {
        return false;
    }
}