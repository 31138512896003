import { saveAs } from 'file-saver';

const defaultBaseUrl: string = 'https://storage.bunnycdn.com';

export default class BunnyCDNStorage {
  private baseUrl: string;
  private apiKey: string;

  constructor(apiKey: string, storageZoneName: string, region?: string) {
    this.baseUrl = region ? `https://${region}.storage.bunnycdn.com/${storageZoneName}/` : `${defaultBaseUrl}/${storageZoneName}/`;
    this.apiKey = apiKey;
  }

  private getOptions(method: string, body?: Blob | File | Buffer): RequestInit {
    const headers: HeadersInit = {
      'Accept': 'application/json',
      'AccessKey': this.apiKey
    };

    return {
      method,
      headers,
      body: body ? body : undefined,
    };
  }

  async list(path: string = ''): Promise<any> {
    const url = `${this.baseUrl}${path}`;
    const options = this.getOptions('GET');
    const response = await fetch(url, options);
    return response.json();
  }

  async delete(path: string): Promise<any> {
    const url = `${this.baseUrl}${path}`;
    const options = this.getOptions('DELETE');
    const response = await fetch(url, options);
    return response.json();
  }

  async upload(file: Blob | File | Buffer, remotePath?: string): Promise<any> {
    if (typeof remotePath === 'undefined') {
      remotePath = '';
    }
    const url = `${this.baseUrl}${remotePath}`;
    const options = this.getOptions('PUT', file);
    const response = await fetch(url, options);
    return response.json();
  }

  async download(filePath: string): Promise<void> {
    const url = `${this.baseUrl}${filePath}`;
    const options = this.getOptions('GET');
    const response = await fetch(url, options);
    const blob = await response.blob();
    saveAs(blob, filePath.split('/').pop() || 'download');
  }
}