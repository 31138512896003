import styles from "./DownloadObject.module.css";

class DownloadObject {
    constructor(name, description, onlySub, onlyVip, hasAccess, downloadName, id) {
        this.name = name;
        this.description = description;
        this.onlySub = onlySub;
        this.onlyVip = onlyVip;
        this.hasAccess = hasAccess;
        this.downloadName = downloadName;
        this.id = id;
    }


    getDownloadName() {
        return this.downloadName;
    }

    getId() {
        return this.id;
    }

    render(onClickCallback) {
        const isDisabled = !this.hasAccess;
        const onClick = isDisabled ? undefined : () => onClickCallback();

        return (
            <>
                <div className={styles.downloadElement}>
                    <span className={styles.downloadTitle}>
                        {this.name}
                    </span><br/>
                    <span className={styles.downloadDescription}
                          dangerouslySetInnerHTML={{__html: this.description}}/><br/>
                    {this.onlySub || this.onlyVip ? <div>Requisiti download:</div> : null}
                    {this.onlySub ? <div>Devi essere Sub{this.hasAccess ? <span className={styles.check}/> :
                        <span className={styles.cross}/>} </div> : null}
                    {this.onlyVip ? <div>Devi essere VIP{this.hasAccess ? <span className={styles.check}/> :
                        <span className={styles.cross}/>} </div> : null}


                    <div className="downloadObjectButtons">
                        <div className="downloadObjectButton">
                            <button id={"button_download__" + this.getId()} className="downloadButton"
                                    onClick={onClick}
                                    disabled={isDisabled}>
                                {this.hasAccess ? "Scarica Ora" : "Download Bloccato"}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DownloadObject;