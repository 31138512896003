import React, {useRef, useState} from 'react';
import {usePdf} from '@mikecousins/react-pdf';
import styles from './Pdf.module.css';

const MyPdfViewer = (props: { file: any; }) => {
    let {file} = props;
    let finalFile = file.file;

    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);
    const {pdfDocument} = usePdf({
        file: finalFile,
        page,
        canvasRef,
        scale: 1.5
    });

    if (pdfDocument === undefined) return null;

    return (
        <div className={styles.pdfGrid}>
            {!pdfDocument && <span>Loading...</span>}
            <div className={styles.pdfCanvas}>
                <canvas ref={canvasRef}/>
            </div>
            <div className={styles.pdfButton}>

                {Boolean(pdfDocument && pdfDocument.numPages) && (
                    <>
                        <button disabled={page === 1}
                                onClick={() => setPage(page - 1)}>
                            Previous
                        </button>
                        {page} / {pdfDocument.numPages}
                        <button
                            disabled={page === pdfDocument.numPages}
                            onClick={() => setPage(page + 1)}>
                            Next
                        </button>
                    </>
                )}
                <br/>
                {Boolean(pdfDocument && pdfDocument.numPages) && (
                    <div className={styles.pdfDownload}>
                        <a download={"EBLCraft-TOS.pdf"} href={finalFile}>
                            <button>
                                Scarica File
                            </button>
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default MyPdfViewer;
