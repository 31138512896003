import './Events.css'

import eblEvents from "./images/minecraft_title.png";
import title from "./images/game_event.png";

import React from "react";
import ReactPlayer from "react-player";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

const Events = () => {
    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div className="container">
                        <div className={"events-title"}>
                            <img src={eblEvents} alt={"EBLCraft - Events"}/>
                            <br/>
                            <img src={title} alt={"EBLCraft - Game"}/>
                            <br/>
                            <span>EVENTO TERMINATO</span>
                        </div>

                        <br/>
                        <br/>

                        <div className={"events-video"}>
                            <h2>Video Spiegazione</h2><br/>
                            <ReactPlayer url="https://www.youtube.com/watch?v=T2PzBuwsEAg" controls={true}
                                         playing={true}/>
                        </div>
                        <div className={"events-video"}>
                            <h2>Video Download ModPack</h2><br/>
                            <ReactPlayer url={"Inserire URL Video"} controls={true}/>
                        </div>
                        <div className={"events-link"}>
                            <a href={"https://tinyurl.com/eblevent"} target={"_blank"} rel={"noreferrer"}>Link
                                ModPack</a>
                        </div>
                        <br/>
                        <br/>

                        <div className={"events-faq"}>
                            <h2>FAQ</h2>
                            <b>❓Che vuol dire Minecraft Premium?</b><br/>
                            <span>Vuol dire un account di Minecraft pagato e non scaricato gratuitamente.</span>
                            <br/><br/>
                            <b>❓Sono da telefono, posso entrare?</b><br/>
                            <span>Non si possono installare modpack, quindi No.</span>
                            <br/><br/>
                            <b>❓Sono da console, posso entrare?</b><br/>
                            <span>Non si possono installare modpack, quindi No.</span>
                            <br/><br/>
                            <b>❓Uso la Bedrock Edition su PC, posso entrare?</b><br/>
                            <span>Non si possono installare modpack, quindi No.</span>
                            <br/><br/>
                            <b>❓Uso la Java Edition sul pc, posso entrare?</b><br/>
                            <span>Se hai la Java Edition ti basterà scaricare il modpack e curseforge per poterlo avviare, dopodichè potrai entrare!</span>
                            <br/><br/>
                            <b>❓Basta che entro tra le 15 e le 16 per partecipare?</b><br/>
                            <span>No, la lobby è aperta dalle 15 ma per poter partecipare dovrai essere online alle ore 16:00 di Sabato quando partirà l'evento. Vi consigliamo di non entrare all'ultimo in quanto non possiamo garantire che parteciperete.</span>
                            <br/><br/>
                            <b>❓Posso entrare dopo le 16:00?</b><br/>
                            <span>Se non eri online a inizio evento No</span>
                            <br/><br/>
                            <b>❓Sono entrato alle 15 ma sono uscito prima dell'inizio evento.</b><br/>
                            <span>In questo caso purtroppo non potrai partecipare all'evento</span>
                            <br/><br/>
                            <b>❓Sono morto e non posso più rientrare. Posso avere un altra vita?</b><br/>
                            <span>No, la vita è una sola</span>
                            <br/><br/>
                            <b>❓Sono morto subito, che faccio?</b><br/>
                            <span>Indipendentemente da quando muori hai comunque una sola vita.</span>
                            <br/><br/>
                            <b>❓Posso scegliere il mio team?</b><br/>
                            <span>No, i team verranno assegnati in automatico.<br/>A evento avviato tuttavia potrete muovermi liberamente e unirvi a altri team, tradire il vostro, o essere nomadi solitari.</span>
                            <br/><br/>
                            <b>❓Come cambio team?</b><br/>
                            <span>Non c'è modo di cambiare la targhetta colorata del nome, semplicemente unisciti a un altro team.</span>
                            <br/><br/>
                            <b>❓Devo andare a 0 0?</b><br/>
                            <span>No, puoi andare dove vuoi, ricorda che non è un UHC, lo scopo e creare una civiltà, non vincere.</span>
                            <br/><br/>
                            <b>❓Il bordo si stringerà?</b><br/>
                            <span>No, ricorda che non è un UHC</span>
                            <br/><br/>
                            <b>❓Com'è strutturato il mondo?</b><br/>
                            <span>Il modo di gioco è un normalissimo mondo vanilla con qualche chicca aggiuntiva (sempre con blocchi vanilla).</span>
                            <br/><br/>
                            <b>❓Posso invitare amici che non hanno la SUB?</b><br/>
                            <span>Sì! L'evento è aperto a qualsiasi utente di Minecraft purchè abbia la Java Edition e sia Premium!</span>
                            <br/><br/>
                            <b>❓Ci saranno altri eventi simili?</b><br/>
                            <span>Se l'evento funzionerà per bene potrebbero esserci nuovi eventi simili!</span>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default Events;