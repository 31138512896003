import styles from './HomePage.module.css';
import ApiInterface from "@/commons/api/ApiInterface"
import React, {useEffect, useState} from "react";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import Redirects from "@/commons/utils/Redirects"


const AdminPage = () => {
    const [getUsername, setUsername] = useState("");

    useEffect(() => {
        ApiInterface.getSelf().then(r => {
            setUsername(r.username);
        });
    });

    return (
        <>
            <Background/>
            <div className={styles.admin__body}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={styles.admin__content}>
                    <div>
                        <h3>Pagina Admin</h3>
                        <div className={styles.content__global}>
                            <div>
                                <p>Benvenuto {getUsername}, <br/>
                                    Questo è il tuo pannello di controllo di EBLCraft. <br/>
                                    Da qui, avrai un accesso completo a tutti gli strumenti di gestione e
                                    amministrazione dei siti EBLCraft.</p>
                                <br/>
                            </div>
                        </div>
                        <div className={styles.categories}>
                            <div className={`${styles.subCategory} ${styles.usersManagement}`}>
                                <h2>Gestione Utenti</h2>
                                <div className={styles.buttonGroup}>
                                    {homeButton("Gestione Utenti", "profiles")}
                                    {homeButton("Informazioni Utenti", "infos")}
                                </div>
                            </div>

                            <div className={`${styles.subCategory} ${styles.reportsManagement}`}>
                                <h2>Gestione dei Report</h2>
                                <div className={styles.buttonGroup}>
                                    {homeButton("Report Utenti", "/reports")}
                                    {homeButton("Categorie Report", "/categories")}
                                </div>
                            </div>

                            <div className={`${styles.subCategory} ${styles.filesManagement}`}>
                                <h2>Gestione dei File</h2>
                                <div className={styles.buttonGroup}>
                                    {/*homeButton("Controllo Files", "")*/}
                                    {homeButton("Aiuti Moderazione", "https://github.com/EBLPlugins/ModerationFiles/")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}
export default AdminPage;

const homeButton = (text: any, link: any) => {
    return (
        <div className={styles.button}>
            <button onClick={(e) => {
                Redirects.send(link, e)
            }}>{text}</button>
        </div>
    )
}