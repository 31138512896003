import styles from "./WhoAmIPage.module.css"

const WhoAmIPage = () => {
    return (
        <div className={styles.whoami_box}>
            <h2 className={styles.title}>Mi presento</h2>
            <p className={styles.text}>Il mio nome è Simone, ma molti mi conoscono come ErenBlaze. Sono un content
                creator noto per i miei contenuti legati ai videogiochi, in particolare a <span
                    className={styles.bold}>Minecraft</span>.</p>

            <h2 className={styles.title}>Cosa faccio</h2>
            <p className={styles.text}>Ho guadagnato popolarità grazie alla mia personalità divertente e coinvolgente, e
                alla mia abilità nel creare contenuti avvincenti per i miei spettatori. Fin dai primi anni della mia
                carriera online, ho dimostrato una grande passione per i giochi, in particolare per Minecraft, che è
                diventato il fulcro dei miei contenuti.</p>

            <h2 className={styles.title}>Il mio canale YouTube</h2>
            <p className={styles.text}>Il mio canale YouTube conta centinaia di migliaia di iscritti e presenta una
                vasta gamma di video, tra cui gameplay, sfide e molto altro legato a Minecraft e non solo. La mia
                abilità nel creare storie coinvolgenti all'interno del gioco e nel coinvolgere la mia community mi ha
                reso un punto di riferimento per gli appassionati di Minecraft in tutta Italia.</p>

            <h2 className={styles.title}>Streaming su Twitch</h2>
            <p className={styles.text}>Oltre a YouTube, sono attivo anche nello streaming su Twitch, dove interagisco in
                tempo reale con i miei fan, gioco in diretta e condivido momenti divertenti ed emozionanti.</p>

            <h2 className={styles.title}>La mia community</h2>
            <p className={styles.text}>La mia dedizione e il mio impegno sono rivolti alla costruzione di una community
                accogliente e appassionata, aperta a chiunque volesse unirsi.</p>
        </div>
    );
}

export default WhoAmIPage;