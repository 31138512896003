import Redirects from "../../utils/Redirects";

class Social {

    constructor(title, icon, link) {
        this.title = title;
        this.icon = icon;
        this.link = link;
    }

    renderer() {
        return (
            <socialElement id={this.title} onClick={(e) => {
                Redirects.send(this.link, e)
            }}>
                <img src={this.icon} alt={this.title}/>
                <socialElementText>{this.title}</socialElementText>
            </socialElement>
        )
    }

}

export default Social;