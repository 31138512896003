import './Tickets.css';
import {createRoot} from "react-dom/client";
import Categories from "@/commons/objects/category/Categories"
import Category from "@/commons/objects/category/Category"
import FormModel from "@/commons/objects/form/FormModel";
import FormElementBuilder from "@/commons/objects/form/FormElementBuilder";
import ReactQuill from "react-quill";
import React, {createElement, useEffect, useRef, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import {createPortal} from "react-dom";
import ApiInterface from "@/commons/api/ApiInterface";
import './ReactQuill-Custom.css'
import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';
import {useInfoBox} from "@/commons/infoBox/InfoBox";
import {getReportText} from "@/commons/tickets/TicketsCommons";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";


const Tickets = () => {
    const {addInfo} = useInfoBox();
    let isStaff, hasMc, isSub, isVip, isEmailVerified;

    let allCats = new Categories();
    let selCat = 0;
    const childMap = new Map();
    const changed = new Map();
    const portals = new Map();
    const roots = new Map();
    const quillRef = useRef(null);
    const [value, setValue] = useState('');
    const [showBox, setShowBox] = useState(false);
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'align': []}, {'indent': '-1'}, {'indent': '+1'}],
            [{'color': []}, {'background': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['link', 'image', 'video'],
            ['blockquote', 'code-block'],
            [{'font': []}, {'size': ['small', false, 'large']}]
        ]
    };

    function checkCategory() {
        let url = new URL(window.location.href);
        let categoryValues = url.searchParams.getAll('defaultCategory'); // Lista di tutti i valori 'defaultCategory'

        for (let i = 0; i < categoryValues.length; i++) {
            let category = categoryValues[i];

            let selection = document.getElementById("categorySelection");
            selection.value = category;
            selection.dispatchEvent(new Event('change'));


            url.searchParams.delete('defaultCategory');
            window.history.replaceState({}, '', url);
        }
    }

    async function loadAllCategories() {
        let cats = await getCategories();
        if (cats.status === 200) {
            let json = await cats.json();

            // crea un'istanza della classe Categories
            allCats = new Categories();
            allCats.addAllCat(json)
            const hiddenCat = allCats.clone();
            const visibleCat = allCats.clone();

            hiddenCat.getAllByHidden(true);
            visibleCat.getAllByHidden(false);

            hiddenCat.sortCategoriesByOrderId();
            visibleCat.sortCategoriesByOrderId();
            loadCategories(visibleCat);
        }
    }

    async function loadCategories(catToLoad) {
        const categorySelection = document.getElementById("categorySelection")
        categorySelection.innerHTML = "";

        let option = document.createElement("option");
        option.setAttribute("value", "0");
        option.innerText = "Seleziona una categoria";
        option.disabled = true;
        option.selected = true;
        categorySelection.appendChild(option);


        // per ogni elemento dell'array catToLoad...
        for (const cat of catToLoad.categories) {
            const option = document.createElement("option");

            option.setAttribute("value", cat.id);

            option.innerText = cat.name;

            if (cat.orderId <= -1) {
                continue;
            }


            //Continue fa in modo di chiudere lo switch senza aggiungere l'elemento alla select
            //Il break ferma lo switch e esegue il codice dopo lo switch
            if (isStaff) {
                switch (cat.usable.toLowerCase()) {
                    case "separator":
                        option.disabled = true;
                        break;
                    default:
                        break;
                }
            } else {
                if (!isEmailVerified) {
                    option.disabled = true;
                    option.classList.add("disabledOption");
                    option.innerText += "*"
                } else {
                    switch (cat.usable.toLowerCase()) {
                        case "separator":
                            option.disabled = true;
                            break;
                        case "hidden":
                            continue;
                        case "everyone":
                            break;
                        case "staff":
                            if (!isStaff) {
                                continue;
                            }
                            break;
                        case "linked":
                            if (!hasMc) {
                                option.disabled = true;
                                option.classList.add("disabledOption");
                                option.innerText += "*"
                                break;
                            }
                            break;
                        case "subscribers":
                            if (!isSub) {
                                option.disabled = true;
                                option.classList.add("disabledOption");
                                option.innerText += "*"
                                break;
                            }
                            break;
                        case "vips":
                            if (!isVip) {
                                continue;
                            }
                            break;
                        default:
                            option.disabled = true;
                            break;
                    }
                }
            }
            categorySelection.appendChild(option);
        }
        categorySelection.addEventListener("change", handleCategoryChange);
    }

    function clearForm(formPositionID) {
        let root = roots.get(formPositionID);
        if (root) {
            root.unmount();
            roots.delete(formPositionID);
        }
    }

    function renderElement(element, container, callback) {
        let id = container.id;
        let root = roots.get(id);
        if (root) {
            let portal = portals.get(id);
            let newPortal = createPortal(<div>{portal} {element}</div>, container);
            portals.set(id, newPortal);
            root.render(newPortal);
        } else {
            let portal = createPortal(element, container);
            portals.set(id, portal);
            let root = createRoot(container);
            root.render(portal, callback);
            roots.set(id, root);
        }
    }

    function loadForm(formValue, formPositionID) {
        const doc = document.getElementById(formPositionID);

        const model = new FormElementBuilder(formValue);

        const name = model.name;
        childMap.set(name, model);

        const htmlText = doc.innerHTML;
        const htmlContent = createElement('div', {dangerouslySetInnerHTML: {__html: htmlText}});

        const nextDiv = createElement('div', {id: `form-${name}`});

        const element = createElement('div', null, htmlContent, model.build(), nextDiv);

        renderElement(element, doc);

        changed.set(name, false);

        setShowBox(false);
        setTimeout(() => {
            if (model.type === undefined || model.type === null) return
            switch (model.type.toLowerCase()) {
                case 'message':
                    break;
                case 'selection':
                    document.getElementById(name).addEventListener('change', () => elementChange(name, model));
                    break;
                case 'coordinates':
                    document.getElementById("x_" + name).addEventListener('change', () => elementChange(name, model));
                    break;
                case 'text':
                    document.getElementById(name).addEventListener('input', () => elementChange(name, model));
                    break;
                case 'combo':
                    model.childrens.forEach(option => {
                        const opt = new FormElementBuilder(option);
                        const childName = opt.name;
                        let doc = document.getElementById("main_" + childName);
                        doc.addEventListener('click', function (event) {
                            event.preventDefault()
                            checkBoxChange(childName);
                        });
                    });
                    break;
                case "submit":
                    setShowBox(true);
                    document.getElementById(name).addEventListener('click', () => uploadReport(name));
                    break;
            }
        }, 1);
    }


    function checkBoxChange(name) {
        let doc = document.getElementById(name);
        let vis = document.getElementById("vis_" + name);
        if (doc.checked) {
            doc.checked = false;
            vis.classList.remove("checked");
        } else {
            doc.checked = true;
            vis.classList.add("checked");
        }
    }

    function elementChange(name, model) {
        if (changed.get(name) === false) {
            changed.set(name, true);
            clearForm("form-" + model.name);
            model.childrens.map((child) => {
                if (child.formScope.toLowerCase() !== "parameter") {
                    loadForm(child, "form-" + model.name);
                } else {
                    const selectElement = document.querySelector(`select[name='${name}']`);
                    const selectedOptionElement = selectElement.options[selectElement.selectedIndex];
                    const selectedOptionId = selectedOptionElement.id;

                    if (child.name == selectedOptionId) {

                        child.childrens.map((subChild) => {
                            loadForm(subChild, "form-" + model.name);
                        });
                    }
                    return;
                }
            });
        } else {
            if (model.type.toLowerCase() === "selection") {
                changed.set(name, false);
                elementChange(name, model);
            }
        }
    }

    function handleCategoryChange(event) {
        setShowBox(false);
        const categorySelection = event.target;

        const selectedOption = categorySelection.options[categorySelection.selectedIndex];
        const value = selectedOption.value;
        const selectedCat = allCats.getById(value);
        selCat = value;

        const Cat = new Category(selectedCat)

        if (Cat.formattedForm == null && Cat.formattedForm === undefined) {
            clearForm("form-Main")
            //Printa un errore nel div appena cancellato con colore rosso
            renderElement(<div className="alert" role="alert">
                    <p>Nessun form associato a questa categoria. Se pensi che sia un errore, segnalacelo.</p><br/>
                    <p className="smaller-text">Consulta la sezione Info nella Home o verifica su Telegram. Se non dovessi
                        trovare nessun avviso e il problema persiste, segnalacelo!</p>
                </div>
                , document.getElementById("form-Main"));
            return;
        }

        const formValue = new FormModel(Cat.formattedForm);
        if (Array.isArray(formValue)) {
            clearForm("form-Main")
            for (let i = 0; i < formValue.length; i++) {
                loadForm(formValue[i], "form-Main");
            }
        } else {
            clearForm("form-Main")
            loadForm(formValue, "form-Main");
        }
    }

    async function getCategories() {
        const userToken = Cookies.get(environment.tokenName);
        const result = fetch(environment.apiUrlOne + '/category/getAll/', {
            method: 'GET', headers: {
                'Authorization': userToken
            }
        })

        const rest = await result;
        return rest
    }

    function getForm() {
        let formMain = document.getElementById("form-Main");

        let result = "";

        let formBoxes = formMain.getElementsByTagName("form-box");

        for (let i = 0; i < formBoxes.length; i++) {
            let label = formBoxes[i].getElementsByTagName("label")[0];
            let formContent = formBoxes[i].getElementsByTagName("form-content")[0];

            let formBoxContent = "";
            let temp = "";
            let x = 0;
            let type = formContent.getAttribute("name") ? formContent.getAttribute("name") : "";


            for (let j = 0; j < formContent.children.length; j++) {
                let element = formContent.children[j];
                switch (type.toLowerCase()) {
                    case "coords":
                        if (element.tagName.toLowerCase() !== "input") {
                            continue;
                        }
                        if (x === 0) {
                            temp = "\nCoordinate:";
                        }
                        x++;
                        temp += " " + element.value;
                        if (x === 3) {
                            x = 0;
                            temp += "\n";
                            formBoxContent += temp;
                        }
                        break;
                    case "combo":
                        if (element.tagName.toLowerCase() !== "combobox") {
                            continue;
                        }
                        let checked = element.children[0].checked;
                        if (x === 0) {
                            formBoxContent += "\n";
                            x = 1;
                        }
                        if (checked) {
                            formBoxContent += "Yes";
                        } else {
                            formBoxContent += "No";
                        }
                        break;
                    default:
                        if (element.hasAttribute("name")) {
                            if (containsHTML(element.value)) {
                                addInfo("info", "Dati non validi", "Il campo " + element.getAttribute("name") + " non può contenere codice HTML", 10)
                                formBoxContent += "<b>CAMPO NON VALIDO</b>";
                            } else {
                                formBoxContent += element.value;
                            }
                        }
                        break;
                }
            }

            result += label.textContent + " " + formBoxContent + "\n";
        }

        result += "\n" + "<b>Descrizione:</b>" + "\n";

        result = result.replace(/\n/g, "<br>");
        return result;
    }

    async function uploadReport(submitButton) {
        let button = document.getElementById(submitButton);
        button.disabled = true;
        button.innerHTML = "Apro il ticket...";
        button.classList.add("button--loading");

        const text = await getReportText(quillRef.current.getEditor().root.innerHTML, getForm());
        if (text == null) {
            button.disabled = false;
            button.innerText = 'Invia';
            button.classList.remove("button--loading");

            addInfo("alert", "Errore", "C'è stato un errore imprevisto", 10)
            return null;
        }
        const title = document.getElementById("reportTitle").value;

        if (containsHTML(title)) {
            button.disabled = false;
            button.innerText = 'Invia';
            button.classList.remove("button--loading");

            addInfo("info", "Dati non validi", "Il titolo non può contenere codice HTML", 10)
            return null;
        }

        if (title === "" || title == null) {
            button.disabled = false;
            button.innerText = 'Invia';
            button.classList.remove("button--loading");

            addInfo("info", "Dati non validi", "Il titolo non può essere vuoto", 10)
            return;
        }

        button.innerHTML = "Carico il report...";
        const userToken = Cookies.get(environment.tokenName);
        const result = fetch(environment.apiUrlOne + '/posts/create/', {
            method: 'POST', headers: {
                'Authorization': userToken,
                'Content-Type': 'application/json'
            }, body: JSON.stringify({
                title: title,
                categoryId: selCat,
                content: text,
                status: "open",
            })
        }).then(async (response) => {
            if (response.status === 200) {
                const details = await response.json();
                let id = details.id;
                window.location.href = environment.reportsUrl + "post/" + id;

                button.style.backgroundColor = '#307239';
                button.disabled = true;
                button.innerText = 'Inviato';
                button.classList.remove("button--loading");
            } else if (response.status === 418) {
                let json = await response.json();
                if (json != null && json !== "") {
                    addInfo(json["object"]["type"], json["object"]["title"], json["object"]["text"], 7);
                } else {
                    if (json != null && json !== "") {
                        addInfo("alert", "Sei bannato!", "Risulti essere bannato dal sito EBLCraft", 7);
                    }
                }
            } else {
                button.style.backgroundColor = '#7b3a3a';
                button.disabled = false;
                button.classList.remove("button--loading");
                addInfo("alert", "Errore", "Si è verificato un errore durante la pubblicazione del post, se il problema persiste consulta le FAQ del sito o contatta un admin!", 10)
            }
        }).catch((error) => {
            button.style.backgroundColor = '#7b3a3a';
            button.disabled = false;
            button.classList.remove("button--loading");
            addInfo("alert", "Errore", "Si è verificato un errore durante la pubblicazione del post, se il problema persiste consulta le FAQ del sito o contatta un admin!", 10)
            console.error('Error:', error);
        });
        return result
    }


    async function loadData() {
        isStaff = await ApiInterface.isStaffer();
        hasMc = await ApiInterface.hasMc();
        isSub = await ApiInterface.isSub();
        isVip = await ApiInterface.isVip();
        isEmailVerified = await ApiInterface.isEmailVerified();
    }

    function loadInfoBox() {
        if (!isEmailVerified) {
            let element = document.getElementById("Tickets_infoBox");
            element.innerHTML = "Per poter fare un report verifica la tua email<br/>" +
                "Per verificare l'indirizzo email vai nella tua <a href='/profile'>Area Personale</a> e segui i passaggi indicati!"
            return
        }
        if (!hasMc) {
            let element = document.getElementById("Tickets_infoBox");
            element.innerHTML = "*Alcune categorie sono disabilitate in quanto non hai un account minecraft collegato al account EBLNetwork<br/>" +
                "Per collegare il tuo account minecraft vai nella sezione <a href='/profile'>Area Personale</a> e segui i passaggi indicati.<br/>" +
                "<br/> Report per questioni relative al server Minecraft (tralasciando problemi di connessione) verranno ignorati e chiusi."
        }
    }

    useEffect(() => {
        loadData().then(() => {
            loadInfoBox();
            loadAllCategories().then(() => {
                checkCategory()
            });
        })
    }, []);

    useEffect(() => {
        let element = document.getElementById("infoBoxContainer");
        if (element === undefined || element === null) return

        if (showBox) {
            let editorContainer = <div className={"tickets__moreInfoBox"}>
                <label>Informazioni aggiuntive:</label><br/>
                <div id="formDescription" onDrop={handleDropDescription}>
                    <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={value}
                        onChange={setValue}
                        modules={modules}/>
                </div>
            </div>

            const container = document.getElementById('infoBoxContainer');
            const root = createRoot(container);
            root.render(editorContainer);
        }

    }, [showBox])

    function handleDropDescription(e) {
        e.preventDefault();
        e.stopPropagation();

        const file = e.dataTransfer.files[0];

        // Check if dropped file is an image
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = () => {
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection();
                const img = `${reader.result}`;

                // Insert image at cursor position
                quill.insertEmbed(range.index, "image", img);
            };
            reader.readAsDataURL(file);
        } else {
            addInfo("alert", "Errore", "Il file caricato non ci risulta essere un immagine.")
        }
    }

    function categorySelection() {
        let url = new URL(window.location.href);
        let popupDataValues = url.searchParams.getAll('categorySelection'); // Lista di tutti i valori 'popupData'

        for (let i = 0; i < popupDataValues.length; i++) {
            let popupData = popupDataValues[i];

            if (popupData === undefined || popupData === null) {
                continue;
            }

            url.searchParams.delete('categorySelection');
            window.history.replaceState({}, '', url);

            //TODO SELECT
        }
    }

    function containsHTML(str) {
        const regex = /(<([^>]+)>)/ig;
        return regex.test(str);
    }

    return (
        <>
            <meta name="description" content={"Hai bisogno di aiuto? Apri ora un Report su EBLCraft Web!"}/>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div className={"Tickets_ReportsMainBox"}><br/>
                        <span className={"Tickets_ReportsTitle"}>
                    Crea un report
                </span><br/><br/>
                        <span className={"Tickets_infoBox"} id={"Tickets_infoBox"}></span>
                        <div className={"Tickets_reportSuperBox"}>
                            <div id={"reportsTable"} className={"Tickets_ReportsTableBox"}>
                                <div className={"Tickets_mainFormInfo"}>
                                    <div>
                                        <label>Titolo:</label><br/>
                                        <input type="text" id="reportTitle"/><br/><br/>
                                    </div>
                                    <div>
                                        <label>Seleziona Categoria:</label><br/>
                                        <select id={"categorySelection"} defaultValue={1}>
                                            <option disabled value={1}>Seleziona una categoria</option>
                                        </select>
                                    </div>
                                </div>
                                <div id="form-Main">

                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
                <Footer/>
            </div>
        </>

    )
}

export default Tickets;