import {encoded} from "../base64/Encoder";
import ApiInterface from "../api/ApiInterface";

export async function getReportText(quillText, formData) {
    if (formData == null) {
        return null;
    }

    let htmlWithImages = await uploadImages(quillText);
    if (htmlWithImages == null) {
        return null;
    }
    const text = formData + htmlWithImages;

    return encoded(text);
}

export async function getResponseText(quillText) {
    let updatedText = await uploadImages(quillText);
    if (updatedText === null) {
        return null;
    }
    if (isOnlyHtmlTags(updatedText)) {
        if (updatedText.includes("<img"))
            return encoded(updatedText);
        return "empty"
    }
    return encoded(updatedText);
}

async function uploadImages(text) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const imgTags = doc.getElementsByTagName('img');

    for (let i = 0; i < imgTags.length; i++) {
        const img = imgTags[i];
        const src = img.getAttribute('src');

        if (src.startsWith('data:image')) {
            try {
                let dataURI = src;
                let mimeType = dataURI.split(',')[0].split(':')[1].split(';')[0];
                let bytes = window.atob(dataURI.split(',')[1]);
                let data = new Uint8Array(bytes.length);
                for (let i = 0; i < bytes.length; i++) {
                    data[i] = bytes.charCodeAt(i);
                }
                let extension = mimeType.split("/")[1];
                let file = new File([data], "image." + extension);

                // Use FormData API to construct a multipart/form-data request
                let formData = new FormData();
                formData.append('multiFile', file, file.name);

                // Use the uploadFile function in ApiInterface to upload your image
                let imageResponse = await ApiInterface.uploadImage(formData);
                if (imageResponse.status !== 200) {
                    console.error("C'è stato un errore con il caricamento delle immagini!")
                }

                let uid = await imageResponse.text();

                img.setAttribute('src', uid);
            } catch (err) {
                // Handle errors as you see fit
                console.error(err);
            }
        }
    }
    return doc.documentElement.innerHTML;
}

function isOnlyHtmlTags(text) {
    return text.replace(/<[^>]*>?/gm, '').trim() === '';
}