import config from '@/commons/configs/Config.json'
import React, {useState} from "react";

import eblHMM from "@/commons/resources/images/emotes/eblHMM.webp";
import eblHI from "@/commons/resources/images/emotes/eblHI.webp";
import eblMON from "@/commons/resources/images/emotes/eblMON.webp";
import ApiInterface from "@/commons/api/ApiInterface";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import {useInfoBox} from "@/commons/infoBox/InfoBox";

const TwitchAuth = () => {
    const {addInfo} = useInfoBox();
    const [state, setState] = useState(0);

    function connect(userId: string) {
        ApiInterface.connectTwitch(userId).then(async r => {
            if (r.status === 200) {
                setState(1);
            } else if (r.status === 418) {
                let json = await r.json();
                if (json != null && json !== "") {
                    addInfo(json["object"]["type"], json["object"]["title"], json["object"]["text"], 7);
                } else {
                    if (json != null && json !== "") {
                        addInfo("alert", "Sei bannato!", "Risulti essere bannato dal sito EBLCraft", 7);
                    }
                }
            } else {
                setState(2);
            }
        }).catch(() => {
            setState(2);
        })
    }

    async function loadData() {
        if (state !== 0) {
            return;
        }
        let query = new URLSearchParams(window.location.search);
        let code = query.get("code");
        let newQuery = "client_id=" + config.twitch_app_id + "&client_secret=" + config.twitch_app_secret + "&code=" + code + "&grant_type=authorization_code&redirect_uri=" + config.twitch_redirect_uri;


        await fetch("https://id.twitch.tv/oauth2/token?" + newQuery, {
            method: "POST",
        }).then(async tokens => {
            let json = await tokens.json();
            if (tokens.status !== 200) {
                setState(2);
                return;
            }

            let access_token = json.access_token;

            fetch("https://api.twitch.tv/helix/users", {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${access_token}`,
                    "Client-ID": config.twitch_app_id,
                    "Accept": "application/vnd.twitchtv.v5+json"
                }
            }).then(async userData => {
                let json = await userData.json();
                if (tokens.status !== 200) {
                    setState(2);
                    return;
                }
                let userInfo = json.data[0];
                let userId = userInfo.id;

                connect(userId);
            });
        });
    }

    loadData();

    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div>
                        <h1>Connessione a Twitch</h1>
                        {state === 0 && <><p>Verifico i dati...</p><br/> <img src={eblHMM} alt="loading Image"/> </>}
                        {state === 1 && <><p>Connessione effettuata con successo!</p><br/> <img src={eblHI}
                                                                                                alt="success Image"/> </>}
                        {state === 2 && <><p>Errore durante la connessione a Twitch!</p><br/> <img src={eblMON}
                                                                                                   alt="error Image"/> </>}
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default TwitchAuth