import React from 'react';

class FormElementBuilder {
    static formScope
    static type
    static name
    static label
    static defaultValue

    static childrens = [];
    static options = [];

    constructor(FormModel) {
        if (typeof (FormModel) != "object") {
            FormModel = JSON.parse(FormModel);
        }
        this.formScope = FormModel["formScope"];
        this.type = FormModel["type"];
        this.name = FormModel["name"];
        this.label = FormModel["label"];
        this.defaultValue = FormModel["defaultValue"];

        if (Array.isArray(FormModel["childrens"])) {
            let contentObjs = [];
            FormModel["childrens"].forEach((element) => {
                contentObjs.push(element);
            });
            this.childrens = contentObjs;
        }
    }

    build() {
        if (this.type === undefined || this.type === null) {
            return;
        }
        switch (this.type.toLowerCase()) {
            case "text":
                return this.buildText();
                break;
            case "number":
                return this.buildNumber();
                break;
            case "coordinates":
                return this.buildCoordinates();
                break;
            case "selection":
                return this.buildSelector();
                break;
            case "combo":
                return this.buildCombo();
                break;
            case "option":
                return this.label;
                break;
            case "submit":
                return this.buildSubmit();
                break;
            case "message":
                return this.buildMessage();
                break;
            default:
                return "default";
                break;
        }
    }

    buildText() {
        return (
            <form-box>
                <label>{this.label}</label><br/>
                <form-content>
                    <input type={"text"} name={this.name} defaultValue={this.defaultValue} id={this.name}/>
                </form-content>
                <br/>
            </form-box>
        );
    }

    buildNumber() {
        return (
            <form-box>
                <label>{this.label}</label><br/>
                <form-content>
                    <input type={"number"} name={this.name} defaultValue={this.defaultValue} id={this.name}
                           inputMode={"decimal"}/>
                </form-content>
                <br/>
            </form-box>
        );
    }

    buildCoordinates() {
        return (
            <form-box>
                <label>{this.label}</label><br/>
                <form-content name="coords">
                    <span className={"coordsLabel"}>x: </span>
                    <input type={"number"} name={"x_" + this.name} id={"x_" + this.name} step={"0.01"}
                           inputMode={"decimal"}/><br/>
                    <span className={"coordsLabel"}>y: </span>
                    <input type={"number"} name={"y_" + this.name} id={"x_" + this.name} step={"0.01"}
                           inputMode={"decimal"}/><br/>
                    <span className={"coordsLabel"}>z: </span>
                    <input type={"number"} name={"z_" + this.name} id={"x_" + this.name} step={"0.01"}
                           inputMode={"decimal"}/><br/>
                </form-content>
                <br/>
            </form-box>
        );
    }

    buildSelector() {
        return (
            <form-box>
                <label>{this.label}</label><br/>
                <form-content>
                    <select name={this.name} id={this.name} defaultValue={this.defaultValue}>
                        <option disabled value={this.defaultValue}>{this.defaultValue}</option>
                        {this.childrens.map(option => {
                            let opt = new FormElementBuilder(option);
                            if (opt.type.toLowerCase() === "option") {
                                return React.createElement('option', {value: opt.label, id: opt.name}, opt.label);
                            }
                        })}
                    </select>
                </form-content>
                <br/>
            </form-box>
        );
    }

    buildCombo() {
        return (
            <form-box>
                <label id={this.name}>{this.label}</label><br/>
                <form-content name="combo">
                    {this.childrens.map(option => {
                        let opt = new FormElementBuilder(option);
                        return (<comboBox id={"main_" + opt.name} name={"main_" + opt.name}><input type={"checkbox"}
                                                                                                   value={opt.name}
                                                                                                   name={opt.name}
                                                                                                   id={opt.name}/><label
                            htmlFor={opt.name}><span id={"vis_" + opt.name}
                                                     className={"vis-checkbox"}></span>{opt.label}
                        </label></comboBox>);
                    })}
                </form-content>
                <br/>
                <div id={"form-" + this.name}></div>
            </form-box>
        );
    }

    buildSubmit() {
        return (
            <div id={"submitInfos"}>
                <div id={"infoBoxContainer"}></div>
                <button type={"submit"} name={this.name} id={this.name}>{this.label}</button>
            </div>
        );
    }

    buildMessage() {
        return (
            <div>
                <br/>
                <span dangerouslySetInnerHTML={{__html: this.label}}/>
                <br/>
            </div>
        );
    }
}

export default FormElementBuilder;
