import MainApp from "@/www/MainApp";
import ReportsApp from "@/reports/ReportsApp";
import AdminApp from "@/admin/AdminApp";
import StoreApp from "@/store/StoreApp";
import PanelApp from "@/panel/PanelApp";
import LinkApp from "@/link/LinkApp";

export const APPS = [
    {
        subdomain: "www",
        app: MainApp,
        main: true,
    }, {
        subdomain: "link",
        app: LinkApp,
        main: false
    }, {
        subdomain: "reports",
        app: ReportsApp,
        main: false
    }, {
        subdomain: "admin",
        app: AdminApp,
        main: false
    }, {
        subdomain: "store",
        app: StoreApp,
        main: false
    }, {
        subdomain: "panel",
        app: PanelApp,
        main: false
    }
]