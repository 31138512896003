import styles from './ErenBlaze-Downloads.module.css';
import {useEffect, useState} from "react";
import {useInfoBox} from "@/commons/infoBox/InfoBox";
import ApiInterface from "@/commons/api/ApiInterface";

import DownloadObject from "./objects/DownloadObject";

const ErenBlazeDownloads = () => {
    const {addInfo} = useInfoBox();
    const [DownlaodOjects, setDownloadObjects] = useState([]);
    let hasSub;
    let hasVip;
    let hasStaff;


    async function loadUserDetails() {
        hasStaff = await ApiInterface.isStaffer();
        hasSub = await ApiInterface.isSub();
        hasVip = await ApiInterface.isVip();
    }

    function checkAccess(onlySub, onlyVip) {
        if (hasStaff) {
            return true;
        }
        if (onlySub) {
            return hasSub && (!onlyVip || hasVip);
        }
        return true;
    }

    async function loadData(res) {
        let json = await res.json();
        setDownloadObjects([])

        let fileArray = json.files;
        for (let i = 0; i < fileArray.length; i++) {
            let ele = fileArray[i];
            let access = checkAccess(ele.onlySub, ele.onlyVip)
            let downloadElement = new DownloadObject(ele.name, ele.description, ele.onlySub, ele.onlyVip, access, ele.downloadName, i);

            setDownloadObjects(DownlaodOjects => [...DownlaodOjects, downloadElement]);
        }

    }

    useEffect(() => {
        loadUserDetails().then(() => {
            ApiInterface.getAllFiles().then((res) => {
                if (res.status === 200) {
                    loadData(res)
                } else {
                    addInfo("warning", "Attenzione", "Si è verificato un errore durante la richiesta dei file scaricabili ai nostri servizi.<br>Riprova più tardi.")
                }
            })
        });
    }, []);

    let buttonClickAction = (ele) => {
        let button = document.getElementById("button_download__" + ele.getId());
        let buttonText = button.innerHTML;
        button.innerHTML = "Download in corso...";
        button.disabled = true;
        ApiInterface.getFile(ele.getId()).then((res) => {
            if (res.status === 200) {
                button.innerHTML = buttonText;
                button.disabled = false;
                res.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = ele.getDownloadName();  // Inserire qui il nome appropriato del file
                    a.click();
                });
            } else {
                button.innerHTML = buttonText;
                button.disabled = false;
                addInfo("alert", "Errore", "Si è verificato un errore durante il tentativo di download del file.<br>Se il problema persiste apri un ticket.")
            }
        })

    }

    return (
        <>
            <div className={styles.download__content}>
                <span className={styles.download__title}>Lista Downloads!</span><br/><br/>
                <span className={styles.download__subtitle}>In questa pagina puoi trovare tutti i download dedicati ai fan di ErenBlaze!</span><br/><br/>
                <div className={styles.download__objects}>
                    {DownlaodOjects.map((ele) => {
                        return ele.render(() => {
                            buttonClickAction(ele)
                        })
                    })}
                </div>
            </div>
        </>
    );
}

export default ErenBlazeDownloads;