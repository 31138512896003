import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';

export async function getAllFiles() {
    const result = fetch(environment.apiUrlTwo + '/downloads/getFiles/', {
        method: 'GET'
    })

    const rest = await result;
    return rest
}

export async function getFile(fileID) {
    const userToken = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlTwo + '/downloads/file/' + fileID + '/', {
        method: 'GET',
        headers: {
            'Authorization': userToken
        }
    })

    const rest = await result;
    return rest
}

export async function checkFile(file) {
    const formData = new FormData();
    formData.append('file', file); // Assuming 'file' is a File object
    const result = fetch(environment.apiUrlTwo + '/downloads/whoSigned/', {
        method: 'POST',
        body: formData
    })

    const rest = await result;
    return rest
}