import BunnyStorage from "@/commons/bunnycloud/BunnycdnStorage"
const ACCOUNT_API_KEY = "690b6472-e521-4070-a4c6-5b6647e43521b40373af-4def-4753-aaa4-bc1c52f20561";
const STORAGE_NAME = "eblcraft-web";
const STORAGE_PASSWORD = "f8a84467-1733-4b93-880875e0a1b2-2bb8-4d1b";
const ZONE_ID = "2409135";

const bunnyStorage = new BunnyStorage(STORAGE_PASSWORD, STORAGE_NAME);

export async function getFiles(path) {
    return await bunnyStorage.list(path);
}