class Posts {
    static posts;

    constructor() {
        this.posts = [];
    }

    addAllPosts(posts) {
        this.posts = posts;
    }

    addPost(post) {
        this.posts.push(post);
    }

    getPosts() {
        return this.posts;
    }

    clear() {
        this.posts = [];
    }

    getLength() {
        return this.posts.length;
    }

    getAllByCategory(categoryID) {
        let posts = [];
        this.posts.forEach((post) => {
            if (post.category_id === categoryID) {
                posts.push(post);
            }
        })
        return posts;
    }

    getAllByStatus(status) {
        let posts = [];
        this.posts.forEach((post) => {
            if (post.status === status) {
                posts.push(post);
            }
        })
        return posts;
    }
}

export default Posts;