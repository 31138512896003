import styles from "./ErenGuides.module.css";

const ErenGuides = () => {

    return (
        <div className={styles.guide__container}>
            <div className={styles.guide__box}>
                <h1>Guida ai download</h1>
                <span>Per poter scaricare alcuni File è necessario avere la Sub al canale Twitch di ErenBlaze.<br/>
                    Per poter verificare la sub è necessario collegare il proprio account Twitch a quello di EblNetwork; ecco una guida su come fare:<br/><br/>
                    1. In alto a Sinistra clicca su "Accedi"(1.a) o il tuo account(2)<br/>
                    1.a Accedi o Registrati e verifica la tua email<br/><br/>

                    2. Collega il tuo account Minecraft al sito, per farlo dovrai entrare nel server dei Sub e esegui il comando indicato cliccando "Collega Minecraft"<br/>
                    2.a Se non si dispone di un account Minecraft è possibile collegare il proprio account Twitch in modo diretto cliccando "Collega Twitch"<br/>
                    P.S: Si noti che il collegamento diretto di twitch potrebbe essere buggato, in tal caso si prega di aprire un report a riguardo.<br/><br/>

                    3. Una volta collegato il proprio account (Minecraft o twitch) la sub sarà verificata in automatico al momento dei download.<br/><br/>

                    Se si notano ulteriori problemi si prega di aprire un ticket sul sito report.<br/>
                </span>
            </div>
        </div>
    )
}

export default ErenGuides;