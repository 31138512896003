import "./DragNDropManager.css"
import React from 'react';
import {DragNDropFunctions} from "./DragNDropFunctions";
import {DragNDrop} from "./DragNDrop";

let manager;

export class DragNDropManager extends React.Component {
    mainElement;
    elementsMap = new Map();
    popupId = "";
    submitAction;


    jsonTypeList = ["TEXT", "NUMBER", "COORDINATES", "SELECTION", "COMBO", "OPTION", "SUBMIT", "MESSAGE"];

    constructor(props) {
        super(props);
        manager = this;

        manager.popupId = "";

        this.setMainElement(this.newDragNDrop());
        this.mainElement.label = props.mainLabel !== undefined ? props.mainLabel : "Undefined";
        this.mainElement.name = props.mainLine2 !== undefined ? props.mainLine2 : "";
        this.submitAction = props.onSubmit !== undefined ? props.onSubmit : () => {
            alert("onSubmit not defined on DragNDropManager element")
        };
        this.state = {
            submitName: props.submitName !== undefined ? props.submitName : "Invia"
        }
    }

    updateSubmitName = (name) => {
        this.setState({submitName: name});
    };

    loadJson = (json) => {
        if (this.getMainElement() !== undefined) {
            document.getElementById(this.getMainElement().getUID()).innerHTML = "";
        }
        if (json === undefined || json === null) {
            return;
        }

        if (typeof (json) != "object") {
            json = JSON.parse(json);
        }
        if (Array.isArray(json)) {
            json.forEach((item) => {
                let e = this.createElement(item)
                this.mainElement.addChildren(e)
            });
        } else {
            let e = this.createElement(json)
            this.mainElement.addChildren(e)
        }

        manager.forceUpdate();
        this.delay(100).then(() => {
            this.loadChildrens(this.mainElement, null);
            manager.forceUpdate();
        });
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    loadChildrens(dragNDrop, parentElement) {
        if (dragNDrop === undefined || dragNDrop === null) {
            return;
        }

        if (parentElement === undefined || parentElement === null) {
            parentElement = document.getElementById(dragNDrop.UID);
            if (parentElement === undefined || parentElement === null) {
                return;
            }
        }

        let childs = dragNDrop.childrens;
        if (childs !== undefined) {
            childs.forEach((item) => {
                let childElement = document.getElementById(item.UID);
                if (childElement !== undefined && childElement !== null) {
                    parentElement.appendChild(childElement);
                    this.loadChildrens(item, childElement);
                }
            });
        }
    }

    createElement(json) {
        if (json === undefined || json === null) {
            return;
        }
        let element = new DragNDrop();
        element.setType(json.type === undefined ? "TEXT" : json.type);
        element.setScope(json.formScope === undefined ? "FORM" : json.formScope);
        element.setName(json.name === undefined ? "name" : json.name);
        element.setLabel(json.label === undefined ? "label" : json.label);
        element.setDefValue(json.defaultValue === undefined ? "1" : json.defaultValue);
        this.registerElement(element)
        let childs = json.childrens;
        if (childs !== undefined) {
            childs.forEach((item) => {
                let child = this.createElement(item)
                element.addChildren(child);
            });
        }
        return element;
    }

    newDragNDrop() {
        let dragNDrop = new DragNDrop();
        dragNDrop.define("FORM", "text", "name", "label", "defValue");
        this.registerElement(dragNDrop);

        if (this.getMainElement() === undefined) {
            this.setMainElement(dragNDrop);
        } else {
            this.getMainElement().addChildren(dragNDrop);
        }

        return dragNDrop;
    }

    registerElement(element) {
        this.elementsMap.set(element.getUID(), element);
    }

    getElementsMap() {
        return this.elementsMap;
    }

    getElementByUID(UID) {
        return this.elementsMap.get(UID);
    }

    setMainElement(mainElement) {
        this.mainElement = mainElement;
    }

    getMainElement() {
        return this.mainElement;
    }

    handleMouseDown(event) {
        if (event.button === 2) {
            event.preventDefault();
            let doc = event.target;

            manager.popupId = doc.id;

            this.loadEditor()
        }
    }

    handleContestMenu(event) {
        event.preventDefault();
    }

    loadEditor() {
        let id = manager.popupId;

        manager.forceUpdate();

        let element = this.getElementsMap().get(id);

        let popupDoc = document.getElementById("popup-main");
        popupDoc.classList.add("popup-open");

        document.getElementById("popup-label").value = element.label !== undefined ? element.label : "";
        document.getElementById("popup-name").value = element.name !== undefined ? element.name : "";
        document.getElementById("popup-defValue").value = element.defValue !== undefined ? element.defValue : "";
        document.getElementById("popup-type").value = element.type !== undefined ? element.type.toUpperCase() : "TEXT";
    }

    loadNewEditor() {
        manager.popupId = "";

        manager.forceUpdate();

        let popupDoc = document.getElementById("popup-main");
        popupDoc.classList.add("popup-open");

        document.getElementById("popup-label").value = "";
        document.getElementById("popup-name").value = "";
        document.getElementById("popup-defValue").value = "";
        document.getElementById("popup-type").value = "TEXT";
    }

    saveData() {
        let label = document.getElementById("popup-label").value;
        let name = document.getElementById("popup-name").value;
        let defValue = document.getElementById("popup-defValue").value;
        let type = document.getElementById("popup-type").value;
        let id = manager.popupId;
        let scope = type.toUpperCase() === "OPTION" ? "PARAMETER" : "FORM";


        let popupDoc = document.getElementById("popup-main");
        popupDoc.classList.remove("popup-open");


        let element;
        if (id === "") {
            element = manager.newDragNDrop();
            element.define(scope, type, name, label, defValue);
        } else {
            element = manager.getElementByUID(id);
            element.define(scope, type, name, label, defValue);
        }

        manager.forceUpdate();
    };


    closePopUp() {
        let popupDoc = document.getElementById("popup-main");
        popupDoc.classList.remove("popup-open");
    }

    deleteElement() {
        let id = manager.popupId;

        let element;
        if (id === "") {
        } else {
            //rimuovi l'elemento con l'id uguale all'id passato dal div padre con id mainElement.getUID()
            element = document.getElementById(id);
            element.parentNode.removeChild(element);
        }

        this.closePopUp()
        manager.forceUpdate();
    }

    popupRender = () => {
        return (
            <div id={"popup-main"} className={"popup-main"}>
                <div className={`popup-overlay popup-active'}`} id="overlay">
                </div>
                <div className={`popup-content`}>
                    <label>Label (Visibile): </label><br/><input type={"text"} id={"popup-label"}/>
                    <label>Name: </label><br/><input type={"text"} id={"popup-name"}/>
                    <label>Default Value: </label><br/><input type={"text"} id={"popup-defValue"}/>
                    <label>Type: </label><br/>
                    <select id={"popup-type"}>
                        {this.jsonTypeList.map((type) => <option value={type}>{type}</option>)}
                    </select><br/>
                    <button className={"dragNDropButton"}
                            onClick={() => this.saveData()}>{manager.popupId === "" ? "Crea" : "Salva"}</button>
                    {manager.popupId === "" ? "" :
                        <button className={"dragNDropButton"} onClick={() => this.deleteElement()}> Elimina </button>}

                    <button className={"dragNDropButton"} onClick={() => this.closePopUp()}>Indietro</button>
                </div>
            </div>
        );
    }


    render() {
        const {submitName} = this.state;
        let mapElements = Array.from(this.getElementsMap().values());
        //rimuovi mainElement dalla lista
        mapElements = mapElements.filter((item) => item !== this.getMainElement());
        return (
            <>
                {this.popupRender()}
                <div className={"categoryCreateTitle"} id={"categoryCreateTitle"}>
                    {this.mainElement.label}<br/>
                    {this.mainElement.name}
                </div>
                <div className={"superBoxTree"}>
                    <div className={"mainChildren"} id={this.mainElement.getUID()}>
                        {mapElements.map(item => (
                            <div className={"subChildren"} id={item.getUID()} draggable
                                 onDragStart={DragNDropFunctions.handleDragStart}
                                 onDragOver={DragNDropFunctions.handleDragOver}
                                 onDrop={DragNDropFunctions.handleDrop}
                                 onMouseDown={(e) => this.handleMouseDown(e)}
                                 onContextMenu={this.handleContestMenu}>
                                {item.render()}
                            </div>
                        ))}
                    </div>
                </div>
                <button className={"createFormElement"} onClick={() => this.loadNewEditor()}>
                    <div className={"addFormElement"}>+</div>
                </button>
                <button className={"createFormElement"} onClick={() => this.returnData()}>
                    <div className={"addFormElement"}>{submitName}</div>
                </button>
            </>
        );
    }

    returnData() {
        let json = this.getJSONData();
        this.submitAction(json);
    }

    getJSONData() {
        this.build();
        return this.getMainElement().getJSON()["childrens"];
    }

    build() {
        this.buildChildren();
    }

    buildChildren() {
        let mainElement = document.getElementById(this.getMainElement().getUID());
        if (!mainElement) {
            return;
        }

        let UID = this.getMainElement().getUID();
        let element = document.getElementById(UID);
        this.buildChildrenSpecific(element);
    }

    buildChildrenSpecific(element) {
        /*Prendi l'elemento passato, per ogni suo figlio prendi l'elemento DragNDrop dalla mappa (UID, dragNDrop) corrispondente all'id e aggiungilo come figlio dell'elemento,
        poi per ogni figlio di questo figlio ripeti il procedimento*/
        let UID = element.id;
        let elementDragNDrop = this.getElementByUID(UID);
        elementDragNDrop.childrens = [];
        if (!elementDragNDrop) {
            return;
        }
        let children = element.children;
        for (let i = 0; i < children.length; i++) {
            //Is the children a valid element?
            if (children[i].id !== "" && children[i].id !== undefined && children[i].id !== null && children[i].tagName.toUpperCase() === "DIV") {
                let childUID = children[i].id;
                let childDragNDrop = this.getElementByUID(childUID);
                elementDragNDrop.addChildren(childDragNDrop);
                this.buildChildrenSpecific(children[i]);
            }
        }
    }
}