import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomePage from "@/admin/pages/homepage/HomePage";
import React from "react";
import ProfilesManager from "@/admin/pages/profiles/ProfilesManager";
import User from "@/admin/pages/user/User";
import UserTickets from "@/admin/pages/user/tickets/UserTickets";
import AdminReports from "@/admin/pages/reports/adminPage/AdminReports";
import Redirect from "@/commons/utils/Components/Redirect";
import AdminCategories from "@/admin/pages/category/AdminCategories";
import Chats from "@/admin/pages/logs/chats/Chats";
import InfoPage from "@/admin/pages/infos/InfoPage";
import Error from "@/www/pages/error/Error";

const AdminApp: React.FC = () => {
    let path = window.location.pathname;

    if (!path.toLocaleLowerCase().includes("error")) {
        localStorage.setItem("errorName", "pageerror");
    }

    const appContent = createBrowserRouter(
        [{
            path: '/', element: <HomePage/>
        }, {
            path: '/logs/chats', element: <Chats/>
        }, {
            path: '/infos', element: <InfoPage/>
        }, {
            path: '/home', element: <Redirect domain={"www"}/>
        }, {
            path: '/profile', element: <Redirect domain={"www"} to={"profile"}/>
        }, {
            path: '/faq', element: <Redirect domain={"www"} to={"faq"}/>
        }, {
            path: '/admin', element: <Redirect to={"/"}/>
        }, {
            path: '/profiles', element: <ProfilesManager/>
        }, {
            path: '/reports', element: <AdminReports/>
        }, {
            path: '/categories', element: <AdminCategories/>
        }, {
            path: '/user/tickets/:id', element: <UserTickets/>
        }, {
            path: '/user/:id', element: <User/>
        }, {
            path: '*', element: <Error errorCode={"notfound"}/>
        }]
    )

    return (
        <RouterProvider router={appContent}/>
    )
}

export default AdminApp;