import React from 'react';
import {v4 as uuidv4} from 'uuid';

export class DragNDrop extends React.Component {
    static UID;
    static scope;
    static type;
    static name;
    static label;
    static defValue;
    static childrens;

    constructor() {
        super();
        this.childrens = [];
        this.UID = uuidv4();
    }

    define(scope, type, name, label, defValue) {
        this.scope = scope;
        this.type = type;
        this.name = name;
        this.label = label;
        this.defValue = defValue;
    }

    getUID() {
        return this.UID;
    }

    addChildren(childrenDrag) {
        this.childrens.push(childrenDrag);
    }

    removeChildren(childrenDrag) {
        this.childrens = this.childrens.filter((item) => item !== childrenDrag);
    }

    getScope() {
        return this.scope;
    }

    setScope(scope) {
        this.scope = scope;
    }

    getType() {
        return this.type;
    }

    setType(type) {
        this.type = type;
    }

    getName() {
        return this.name;
    }

    setName(name) {
        this.name = name;
    }

    getLabel() {
        return this.label;
    }

    setLabel(label) {
        this.label = label;
    }

    getDefValue() {
        return this.defValue;
    }

    setDefValue(defValue) {
        this.defValue = defValue;
    }

    getChildrens() {
        return this.childrens;
    }

    getJSON() {
        let json = {
            "formScope": this.scope,
            "type": this.type,
            "name": this.name,
            "label": this.label,
            "defaultValue": this.defValue,
            "childrens": this.childrens
        }
        let childrensJSON = [];
        this.childrens.forEach((item) => {
            childrensJSON.push(item.getJSON());
        });
        json.childrens = childrensJSON;
        return json;
    }

    render() {
        return (
            <>
                {this.label} | {this.name}<br/>
                {this.defValue}<br/>
                {this.scope} - {this.type}<br/><br/>
            </>
        )
    }
}