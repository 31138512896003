import Socials from "@/commons/objects/social/Socials";
import Social from "@/commons/objects/social/Social";
import logo_twitch from "@/commons/resources/images/socials/twitch_icon_white.png";
import logo_youtube from "@/commons/resources/images/socials/youtube_icon_white.png";
import logo_instagram from "@/commons/resources/images/socials/instagram_icon_white.png";
import logo_tiktok from "@/commons/resources/images/socials/tiktok_icon_white.png";
import logo_discord from "@/commons/resources/images/socials/discord_icon_white.png";
import logo_telegram from "@/commons/resources/images/socials/telegram_icon_white.png";
import logo_amazon from "@/commons/resources/images/socials/amazon_icon_white.png";
import styles from "./SocialPage.module.css";

const ErenSocialPage = () => {

    let ErenBlazeSocials = new Socials();
    ErenBlazeSocials.setTitle("I miei Socials");
    ErenBlazeSocials.add(new Social("Twitch", logo_twitch, "https://www.twitch.tv/erenblaze"));
    ErenBlazeSocials.add(new Social("YouTube", logo_youtube, "https://www.youtube.com/@ErenBlaze"));
    ErenBlazeSocials.add(new Social("YouTube ReUpload", logo_youtube, "https://www.youtube.com/ezalbnere"));
    ErenBlazeSocials.add(new Social("Instagram", logo_instagram, "https://www.instagram.com/erenblaze/"));
    ErenBlazeSocials.add(new Social("TikTok", logo_tiktok, "https://www.tiktok.com/@erenblaze?lang=it-IT"));
    ErenBlazeSocials.add(new Social("Discord", logo_discord, "https://discord.erenblaze.it/"));
    ErenBlazeSocials.add(new Social("Canale Telegram", logo_telegram, "https://t.me/newserenblaze"));
    ErenBlazeSocials.add(new Social("Amazon", logo_amazon, "https://www.amazon.it/?tag=erenblaze-21"));

    return (
        <div className={styles.social__socialBox}>
            {ErenBlazeSocials.renderer()}
        </div>
    );
}

export default ErenSocialPage;