import './Post.css';
import {useParams} from "react-router-dom";
import Categories from "@/commons/objects/category/Categories"
import ReactQuill from "react-quill";
import React, {useEffect, useRef, useState} from "react";
import 'react-quill/dist/quill.snow.css';
import ApiInterface from "@/commons/api/ApiInterface";
import '../tickets/ReactQuill-Custom.css'
import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';
import {useInfoBox} from "@/commons/infoBox/InfoBox";
import Redirector from "@/commons/utils/Redirects";
import Redirects from "@/commons/utils/Redirects";
import {isStaffer} from "@/commons/api/apiPages/Details";
import {decoded} from "@/commons/base64/Decoder";
import BaseInfoBoxs from "@/commons/infoBox/BaseInfoBoxs";
import {getResponseText} from "@/commons/tickets/TicketsCommons";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import ReactDOM from "react-dom";
import ZoomImageModal from "@/reports/pages/post/ZoomImageModal";

const SinglePost = () => {
    const {addInfo} = useInfoBox();
    let [postJSON, setPostJSON] = useState(null);
    let {id} = useParams();
    let page = 0;
    let categoryId = 0;
    let maxPage = 1;
    let itemsPerPage = 15;
    let [reportStatus, setReportStatus] = useState("ndf");
    let [repostHash, setReportHash] = useState("");
    let [ready, setReady] = useState(false);

    const quillRef: any = useRef("");
    const [value, setValue] = useState('');
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'align': []}, {'indent': '-1'}, {'indent': '+1'}],
            [{'color': []}, {'background': []}],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['link', 'image', 'video'],
            ['blockquote', 'code-block'],
            [{'font': []}, {'size': ['small', false, 'large']}]
        ]
    };

    async function getTotalItems() {
        const getPage = loadComments();
        let pageInfo = await getPage;

        if (pageInfo === undefined) return

        if (pageInfo.status === 200) {
            const jsonText = await pageInfo.json();

            let totalElements = jsonText["totalElements"];
            maxPage = Math.ceil(totalElements / itemsPerPage);
            if (maxPage === 0) maxPage = 1;
        }
    }

    async function loadPostInfo() {
        const userToken = Cookies.get(environment.tokenName);
        if (userToken === undefined) return;
        const result = fetch(environment.apiUrlOne + '/posts/getPost/' + id, {
            method: 'GET',
            headers: {
                'Authorization': userToken
            }
        })

        const rest = await result;
        return rest
    }

    async function loadComments() {
        const userToken = Cookies.get(environment.tokenName);
        let pageData = {"page": page, "items": itemsPerPage};
        if (userToken === undefined) return;
        const result = fetch(environment.apiUrlOne + '/comment/getComments/' + id, {
            method: 'POST',
            headers: {
                'Authorization': userToken,
                'Content-Type': 'application/json',
            },
            body:
                JSON.stringify(pageData)
        })

        const rest = await result;
        return rest;
    }

    function loadPages() {
        let pageList = (document.getElementById("pageNumber") as HTMLElement);
        pageList.innerHTML = "";

        let li = document.createElement("span");
        li.classList.add("page-item");
        li.appendChild(document.createTextNode("<<"));
        li.addEventListener("click", () => {
            changePage(0)
        });
        pageList.appendChild(li);
        if (page - 1 >= 0 && page - 1 <= maxPage - 1) {
            li = document.createElement("span");
            li.classList.add("page-item");
            li.appendChild(document.createTextNode("<"));
            li.addEventListener("click", () => {
                changePage(page - 1)
            });
            pageList.appendChild(li);
        }

        for (let i = -3; i < 4; i++) {
            if (page + i >= 0 && page + i <= maxPage - 1) {
                let li = document.createElement("span");
                li.classList.add("page-item");

                if (page + i === page) {
                    li.classList.add("page-item-active");
                }

                let newPage = page + i;
                let viewPage = newPage + 1;
                li.appendChild(document.createTextNode(String(viewPage)));
                li.addEventListener("click", () => {
                    changePage(newPage)
                });
                pageList.appendChild(li);
            }
        }
        if (page + 1 >= 0 && page + 1 <= maxPage - 1) {
            let li = document.createElement("span");
            li.classList.add("page-item");
            li.appendChild(document.createTextNode(">"));
            li.addEventListener("click", () => {
                changePage(page + 1)
            });
            pageList.appendChild(li);
        }

        li = document.createElement("span");
        li.classList.add("page-item");
        li.appendChild(document.createTextNode(">>"));
        li.addEventListener("click", () => {
            changePage(maxPage - 1)
        });
        pageList.appendChild(li);
    }

    async function baseToText(base64: string) {
        let html
        // Decodificare la stringa base64 in una stringa HTML
        try {
            html = decoded(base64);
        } catch (e) {
            html = base64;
        }
        return (html);
    }

    async function loadAllLast() {
        await getTotalItems();
        await loadPages();
        changePage(maxPage - 1);
        loadButtons();
    }

    async function redirectToProfile(id: string, event: any) {
        let isStaff = await isStaffer();

        if (isStaff) {
            Redirects.domainSendClick("admin", "user/" + id, event)
        }
    }

    function openBigImage(imageSrc: string | null) {
        if (imageSrc === null) {
            return;
        }


        const container = document.createElement("div");
        document.body.appendChild(container);

        const handleClose = () => {
            ReactDOM.unmountComponentAtNode(container);
            container.remove();
        };

        ReactDOM.render(
            <ZoomImageModal imageSrc={imageSrc} onClose={handleClose}/>,
            container
        );
    }

    function clickableImages(element: HTMLElement) {
        const imgTags = element.getElementsByTagName('img');

        for (let i = 0; i < imgTags.length; i++) {
            const img = imgTags[i];

            img.onclick = function () {
                openBigImage(img.getAttribute("src"))
            }
        }
    }

    async function loadElementImage(elementID: string) {
        let element = (document.getElementById(elementID) as HTMLElement);
        let html = element.innerHTML;

        html = await getImage(html);
        element.innerHTML = html;

        clickableImages(element);
    }

    async function loadAll() {
        await getTotalItems();
        await loadPages();

        const comments: any = await loadComments();
        const jsonText: any = postJSON;

        if (jsonText == null) return

        let postTitle = (document.getElementById("postTitle") as HTMLElement);
        let postCategory = (document.getElementById("postCategory") as HTMLElement);
        let postStatus = (document.getElementById("postStatus") as HTMLElement);
        let postBody = (document.getElementById("postBody") as HTMLElement);
        let postAuthor = (document.getElementById("postAuthor") as HTMLElement);
        let postDate = (document.getElementById("postDate") as HTMLElement);

        categoryId = jsonText["categoryId"].id;

        let postTitleText = jsonText["title"];
        let category = jsonText["categoryId"];
        let user = jsonText["userId"];
        let postBodyText = jsonText["content"];
        let postStatusText: string = jsonText["status"];
        let postDateText = jsonText["time"];

        let notTime = new Date(postDateText);
        let notTimeFormatted = notTime.toLocaleDateString();

        let status = "";
        if (postStatusText.toLowerCase() === "open") {
            status = "<span style='color: green'>Aperto</span>";
            setReportStatus("open");
        } else {
            status = "<span style='color: red'>Chiuso</span>";
            setReportStatus("closed");
        }

        let base64Badge = await getBadge(user.role.alias);
        let text = await base64Badge.text();

        const imageSrc = `data:image/png;base64,${text}`;


        postTitle.innerHTML = postTitleText;
        postCategory.innerHTML = "Categoria: " + category.name;
        postStatus.innerHTML = status;
        postAuthor.innerHTML = "Autore: " + user.username + "  <img src='" + imageSrc + "' alt=\"Immagine\" />";
        postAuthor.addEventListener("click", (event) => {
            redirectToProfile(user.id, event)
        })
        postAuthor.classList.add("clickable");
        postBody.innerHTML = await baseToText(postBodyText);
        postDate.innerHTML = notTimeFormatted;

        //Load image async from everything else
        loadElementImage("postBody");


        if (comments.status === 200) {
            const jsonText = await comments.json();

            let commentsList = (document.getElementById("commentsList") as HTMLElement);
            commentsList.innerHTML = "";

            let lastCommendID = "";

            for (let i = 0; i < jsonText["numberOfElements"]; i++) {
                let comment = jsonText["content"][i];

                let commentText = comment["content"];
                let commentId = comment["id"];
                let commentDate = comment["time"];
                let userId = comment["userId"];

                lastCommendID = "comment_" + commentId;

                let base64Badge = await getBadge(userId.role.alias);
                let base64Image = await base64Badge.text();
                const imageSrc = `data:image/png;base64,${base64Image}`;

                //Decodifica il testo in base 64 di commentText
                let text = await baseToText(commentText);

                let div = document.createElement("div");
                div.classList.add("post_comment");
                div.id = "comment_" + commentId;

                let div2 = document.createElement("div");
                div2.classList.add("post_comment_header");
                div2.innerHTML = userId["username"] + "  <img src='" + imageSrc + "' alt=\"Immagine\" />";
                div2.addEventListener("click", (event) => {
                    redirectToProfile(userId["id"], event)
                })
                div2.classList.add("clickable");
                div.appendChild(div2);

                let div3 = document.createElement("div");
                div3.classList.add("post_comment_body");
                div3.innerHTML = text;
                div.appendChild(div3);

                let div4 = document.createElement("div");
                div4.classList.add("post_comment_date");

                let notTime = new Date(commentDate);
                let notTimeFormatted = notTime.toLocaleDateString() + " " + notTime.toLocaleTimeString()

                div4.innerHTML = notTimeFormatted;
                div.appendChild(div4);

                let div5 = document.createElement("div");
                div5.classList.add("post_comment_separator");
                commentsList.appendChild(div5);

                commentsList.appendChild(div);

                loadElementImage("comment_" + commentId)
            }
            let lastComment = document.getElementById(lastCommendID);
            if (lastComment != null) {
                lastComment.scrollIntoView({block: "center"})
            }
        }
    }

    async function getBadge(role: any) {
        const result = fetch(environment.apiUrlOne + '/utils/images/roles/' + role, {
            method: 'GET'
        })

        const rest = await result;
        return rest
    }

    async function loadButtons() {
        let closeOpenButton = (document.getElementById("changeReportStatus") as HTMLButtonElement);
        if (closeOpenButton == null) return

        let buttonReply = (document.getElementById("responseButton") as HTMLButtonElement);
        if (buttonReply == null) return

        closeOpenButton.disabled = false;
        buttonReply.disabled = false;
        await updateButtons();
    }

        async function updateButtons() {
            let closeOpenButton = (document.getElementById("changeReportStatus") as HTMLButtonElement);
            if (closeOpenButton == null) return

            let buttonReply = (document.getElementById("responseButton") as HTMLButtonElement);
            if (buttonReply == null) return

            let text = await getResponseText(quillRef.current.getEditor().root.innerHTML);
            if (text === null || text == "empty") {
                text = "";
            }
            if (text === "") {
                if (reportStatus.toLowerCase() === "open") {
                    closeOpenButton.innerHTML = "Chiudi Report";
                    closeOpenButton.onclick = () => {
                        closeOpenButton.disabled = true;
                        buttonReply.disabled = true;
                        closeReport().then(() => {
                            checkUpdate();
                        });
                    }
                } else {
                    closeOpenButton.innerHTML = "Riapri Report";
                    closeOpenButton.onclick = () => {
                        closeOpenButton.disabled = true;
                        buttonReply.disabled = true;
                        openReport().then(() => {
                            checkUpdate();
                        });
                    }
                }
            } else {
                if (reportStatus.toLowerCase() === "open") {
                    closeOpenButton.innerHTML = "Rispondi e chiudi";
                    closeOpenButton.onclick = () => {
                        closeOpenButton.disabled = true;
                        buttonReply.disabled = true;
                        addResponse().then(() => {
                            closeReport().then(() => {
                                checkUpdate();
                            });
                        });
                    };
                } else {
                    closeOpenButton.innerHTML = "Riapri rispondendo";
                    closeOpenButton.onclick = () => {
                        closeOpenButton.disabled = true;
                        buttonReply.disabled = true;
                        addResponse().then(() => {
                            checkUpdate();
                        });
                    };
                }
            }


        buttonReply.innerHTML = "Rispondi";
        if (text === "") {
            buttonReply.disabled = true;
        } else {
            buttonReply.disabled = false;
            if (reportStatus.toLowerCase() === "open") {
                buttonReply.disabled = false;
            } else {
                buttonReply.disabled = true;
            }
        }
        buttonReply.onclick = () => {
            closeOpenButton.disabled = true;
            buttonReply.disabled = true;
            addResponse().then(() => {
                checkUpdate();
            })
        };
    }

    function changePage(value: number) {
        page = value;
        loadAll();
    }

    function switchCategory() {
        let selector = (document.getElementById("categorySelectionAdmin") as HTMLSelectElement);
        let categoryId = selector.options[selector.selectedIndex].value;
        ApiInterface.editCategory(id, categoryId, "Categoria aggiornata<br/> %old% -> %new%").then(r => {
            if (r.status === 200) {
                addInfo("success", "categoria cambiata", "la categoria è stata cambiata con successo.", 5)
                checkUpdate();
            } else {
                addInfo("alert", "Errore", "C'è stato un errore durante il cambio della categoria.")
            }
        }).catch(e => {
            addInfo("alert", "Errore", "C'è stato un errore durante il cambio della categoria.")
        })
    }

    async function loadAdminZone() {
        let adminZone = (document.getElementById("post_adminButtons") as HTMLElement);
        adminZone.innerHTML = "";
        let isStaff = await ApiInterface.isStaffer();
        if (!isStaff) {
            return
        }

        let categoryName = document.createElement("span");
        categoryName.setAttribute("id", "categoryName");
        categoryName.innerHTML = "Modifica categoria: ";
        adminZone.appendChild(categoryName);

        let categorySelection = document.createElement("select");
        categorySelection.setAttribute("id", "categorySelectionAdmin");
        categorySelection.innerHTML = "";

        let option = document.createElement("option");
        option.setAttribute("value", "0");
        option.innerText = "Seleziona una categoria";
        option.disabled = true;
        option.selected = true;
        categorySelection.appendChild(option);


        let cats = await ApiInterface.getCategories();
        if (cats.status !== 200) {
            return
        }

        let json = await cats.json();

        // crea un'istanza della classe Categories
        let allCats = new Categories();
        allCats.addAllCat(json)

        // per ogni elemento dell'array catToLoad...
        for (const cat of allCats.categories) {
            const option = document.createElement("option");

            option.setAttribute("value", cat.id);
            if (cat.id === categoryId) {
                option.selected = true;
            }

            option.innerText = cat.name;

            if (cat.orderId <= -1) {
                continue;
            }
            switch (cat.usable.toLowerCase()) {
                case "separator":
                    option.disabled = true;
                    break;
                default:
                    break;
            }
            categorySelection.appendChild(option);
        }
        categorySelection.addEventListener("change", switchCategory);
        adminZone.appendChild(categorySelection);
    }

    async function loadPostJson() {
        await updateHash();

        const postInfo: any = await loadPostInfo();
        if (postInfo.status !== 200) {
            Redirector.send("/", null, {popupData: BaseInfoBoxs("noReportFound")})
        }

        let json = await postInfo.json();

        setPostJSON(json);
    }


    function checkUpdates() {
        //RUN THIS EVERY 5 SECONDS
        checkUpdate();
        const interval = setInterval(async () => {
            checkUpdate();
        }, 5000);
        return () => clearInterval(interval); // This line clears the interval when the component is unmounted
    }

    function checkUpdate(){
        updateHash();
    }

    async function updateHash() {
        let hashReturn = await ApiInterface.getHash(id);
        if(hashReturn.status === 200){
            let text = await hashReturn.text()
            if (!ready) {
                setReady(true)
            }
            setReportHash(text);
            return;
        }
        return;
    }

    useEffect(() => {
        setReady(false);
        loadPostJson();
        checkUpdates();
    }, []);

    useEffect(() => {
        if(ready){
            loadPostJson();
        }
    }, [repostHash]);

    useEffect(() => {
        if (postJSON !== null && ready) {
            setReady(false)
            loadAllLast().then(() => {
                loadAdminZone();
            });
        }
    }, [postJSON]);

    useEffect(() => {
        loadButtons();
    }, [reportStatus])

    async function getImage(text: string) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const imgTags = doc.getElementsByTagName('img');

        for (let i = 0; i < imgTags.length; i++) {
            const img = imgTags[i];
            const UUID = img.getAttribute('src');
            if (UUID == null) continue

            if (UUID.includes("data:image")) {
                continue;
            }
            const result = await ApiInterface.getImage(UUID);
            if (result.status !== 200) {
                img.setAttribute('src', UUID)
            } else {
                let json = await result.json();

                img.setAttribute('src', json.value);
            }
        }
        return doc.documentElement.innerHTML;
    }

    async function addResponse() {
        let button = (document.getElementById("responseButton") as HTMLButtonElement);
        button.disabled = true;
        button.innerHTML = "Pubblico la risposta...";
        button.classList.add("button--loading");
        let text = await getResponseText(quillRef.current.getEditor().root.innerHTML);
        if (text === null) {
            button.innerHTML = "Errore";
            button.classList.remove("button--loading");
            addInfo("alert", "Errore", "C'è stato un errore imprevisto!")
            return
        }
        if (text === "empty") {
            button.innerHTML = "Errore";
            button.classList.remove("button--loading");
            addInfo("alert", "Dati mancanti", "Il commento non può essere vuoto!")
            return
        }
        let opened;
        if (reportStatus.toLowerCase() === "open") {
            opened = true;
        } else {
            opened = await openSilently();
        }
        if (opened === true) {
            let response = await ApiInterface.addComment(text, id)

            if (response.status === 200) {
                quillRef.current.getEditor().root.innerHTML = "";
                button.disabled = false;
                button.innerHTML = "Rispondi";
                button.classList.remove("button--loading");
                return;
            } else if (response.status === 418) {
                let json = await response.json();
                if (json != null && json !== "") {
                    addInfo(json["object"]["type"], json["object"]["title"], json["object"]["text"], 7);
                }
                button.disabled = true;
                button.innerHTML = "Sei bannatə";
                button.classList.remove("button--loading");
                return;
            } else {
                addInfo("alert", "Errore", "Si è verificato un errore durante l'aggiunta del commento.")
                button.disabled = false;
                button.innerHTML = "Rispondi";
                button.classList.remove("button--loading");
                return;
            }
        }
    }

    function handleDropDescription(e: any) {
        e.preventDefault();
        e.stopPropagation();

        const file = e.dataTransfer.files[0];

        // Check if dropped file is an image
        if (file && file.type.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = () => {
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection();
                const img = `${reader.result}`;

                // Insert image at cursor position
                quill.insertEmbed(range.index, "image", img);
            };
            reader.readAsDataURL(file);
        } else {
            addInfo("alert", "Errore", "Il file caricato non ci risulta essere un immagine.")
        }
    }

    useEffect(() => {
        updateButtons();
    }, [value]);

    async function closeReport() {
        const text = "<span style='color: #b70000'>Report chiuso da %user%</span>";

        let response = await ApiInterface.closeReport(id, text)
        if (response.status === 418) {
            let json = await response.json();
            if (json != null && json !== "") {
                addInfo(json["object"]["type"], json["object"]["title"], json["object"]["text"], 7);
                return;
            } else {
                if (json != null && json !== "") {
                    addInfo("alert", "Sei bannato!", "Risulti essere bannato dal sito EBLCraft", 7);
                    return;
                }
            }
        } else if (response.status !== 200) {
            addInfo("alert", "Errore", "Si è verificato un errore durante la chiusura del report.")
            return;
        } else {
            return;
        }
    }

    async function openReport() {
        const text = "<span style='color: #52ea00'>Report riaperto da %user%</span>";

        let response = await ApiInterface.openReport(id, text)
        if (response.status === 418) {
            let json = await response.json();
            if (json != null && json !== "") {
                addInfo(json["object"]["type"], json["object"]["title"], json["object"]["text"], 7);
                return;
            } else {
                if (json != null && json !== "") {
                    addInfo("alert", "Sei bannato!", "Risulti essere bannato dal sito EBLCraft", 7);
                    return;
                }
            }
        } else if (response.status !== 200) {
            addInfo("alert", "Errore", "Si è verificato un errore durante l'apertura del report.")
            return;
        }
    }

    async function openSilently() {
        const text = "<span style='color: #52ea00'>Report riaperto automaticamente</span>";

        let status = await ApiInterface.openReport(id, text).then((response) => {
            if (response.status !== 200) {
                return false;
            } else {
                return true;
            }
        });
        return status;
    }

    if (postJSON === null) {
        return (<></>);
    } else {
        return (
            <>
                <meta name="description" content={"EBLCraft report #" + id}/>
                <Background/>
                <div className={"page__body"}>
                    <Header marginTop={1} marginBottom={1}/>
                    <div className={"page__content"} id={"page__content"}>
                        <div>
                            <div className={"Post_ReportsMainBox"}><br/>
                                <div>
                                    <div className={"PostInfo"}>
                                        <div id={"postTitle"} className={"postTitle"}></div>
                                        <div id={"postDate"} className={"postDate"}></div>
                                        <div id={"postStatus"} className={"postStatus"}></div>
                                        <div id={"postCategory"} className={"postCategory"}></div>
                                        <div id={"postAuthor"} className={"postAuthor"}></div>
                                        <div id={"postBody"} className={"postBody"}></div>
                                    </div>
                                    <div className={"PostComments"} id={"commentsList"}>
                                    </div>
                                    <br/>
                                    <b>Rispondi:</b>
                                    <br/>
                                    <br/>
                                    <div id="formDescription" onDrop={handleDropDescription}>
                                        <ReactQuill
                                            ref={quillRef}
                                            theme="snow"
                                            value={value}
                                            onChange={setValue}
                                            modules={modules}/>
                                    </div>
                                    <div id={"post_adminButtons"} className={"post_adminButtons"}>
                                    </div>
                                    <div className={"post_buttons"}>
                                        <button className={"postResponse"} id={"responseButton"}></button>
                                        <button id={"changeReportStatus"}></button>
                                    </div>
                                    <div id={"pageNumber"} className={"pageSelection"}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </>
        )
    }
}

export default SinglePost;