import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";

//PAGES
import HomePage from "@/www/pages/homepage/HomePage";
import Faq from "@/www/pages/faq/Faq";
import PanelHome from "@/panel/pages/homePage/PanelHome";

const PanelApp: React.FC = () => {
    let path = window.location.pathname;

    if (!path.toLocaleLowerCase().includes("error")) {
        localStorage.setItem("errorName", "pageerror");
    }

    const appContent = createBrowserRouter(
        [{
            path: '/', element: <PanelHome/>
        }, {
            path: '/faq', element: <Faq/>
        }]
    )
    return (
        <RouterProvider router={appContent}/>
    )
}

export default PanelApp;