import React from 'react';
import './DropdownMenu.css';

class DropdownElement {
    constructor(id, htmlElement, callback, removeHover = false) {
        this.id = id;
        this.htmlElement = htmlElement;
        this.callback = callback;
        this.removeHover = removeHover;
    }

    render(key) {
        if (this.removeHover === true) {
            return (
                <div key={key} className={this.id + "dropdownElement " + this.id + "dropdownSpace"}
                     onClick={(e) => this.callback(e)}>
                    {this.htmlElement}
                </div>
            );
        } else {
            return (
                <div key={key} className={this.id + "dropdownElement"} onClick={(e) => this.callback(e)}>
                    {this.htmlElement}
                </div>
            );
        }
    }
}

export default DropdownElement;