export const getSubDomain = (hostname: string) => {
    const locationParts = hostname.toLowerCase().split(".");
    let sliceTill = -2;

    //for localhost
    const isLocalHost = locationParts.slice(-1)[0] === "localhost";
    if (isLocalHost) sliceTill = -1;

    return locationParts.slice(0, sliceTill).join("");
}

export const getMainDomain = (location: Location) => {
    // Estrai il nome host (hostname) dall'oggetto location
    const hostname = location.hostname.toLowerCase();

    // Controlla se il nome host è 'localhost', se sì, restituisci 'localhost' con la porta
    if (hostname.includes("localhost")) {
        return `localhost${location.port ? ':' + location.port : ''}`;
    }

    // Altrimenti, rimuovi il sottodominio se presente e restituisci il dominio principale
    const domainParts = hostname.split('.').slice(-2).join('.');

    return domainParts;
}