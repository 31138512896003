import {getMainDomain} from "@/commons/utils/Domains";

function send(url, e, parameters = {}) {
    if (e !== undefined && e !== null) {
        if (e.ctrlKey) {
            window.open(url + params(parameters), '_blank')
            return;
        }
    }
    urlSend(url, parameters);
}

function urlSend(url, parameters = {}) {
    if (url.startsWith("htt")) {
        window.open(url + params(parameters), '_blank');
    } else {
        window.location.href = url + params(parameters);
    }
}

function domainSend(newSubdomain, page = "", parameters = {}) {
    let protocol = window.location.protocol;
    let domain = getMainDomain(window.location);
    window.location.href = protocol + "//" + newSubdomain + "." + domain + "/" + page + params(parameters);

}

function domainSendClick(newSubdomain, page = "", e = null, parameters = {}) {
    let protocol = window.location.protocol;
    let domain = getMainDomain(window.location);

    if (e !== undefined && e !== null) {
        if (e.ctrlKey) {
            window.open(protocol + "//" + newSubdomain + "." + domain + "/" + page + params(parameters), '_blank')
            return;
        }
    }
    window.location.href = protocol + "//" + newSubdomain + "." + domain + "/" + page + params(parameters);
}

function params(parameters = {}) {
    let params = new URLSearchParams(parameters).toString();
    if (params !== undefined && params !== null && params !== "") {
        return "?" + params;
    }
    return "";
}


export default {
    send,
    urlSend,
    domainSend,
    domainSendClick
}