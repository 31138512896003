class Roles {
    constructor() {
        this.roles = [];
    }

    addAllRoles(roles) {
        this.roles = roles;
    }

    addRole(role) {
        this.roles.push(role);
    }

    getRoles() {
        return this.roles;
    }

    clear() {
        this.roles = [];
    }

    getLength() {
        return this.roles.length;
    }

    getRoleByAlias(alias) {
        let role = null;
        this.roles.forEach((r) => {
            if (r.alias === alias) {
                role = r;
            }
        })
        return role;
    }

    getRole(index) {
        return this.roles[index];
    }
}

export default Roles;