import Redirects from "@/commons/utils/Redirects";

const HeaderButton = (props) => {
    let title = props.title;
    let link = props.link;
    let domain = props.domain;
    let type = props.type;

    if (type === "evidenced") {
        return (
            <div className={"header__button header__evidenced"} onClick={(e) => {
                if (link === "" || link === undefined || link === null) {
                    if (domain === "" || link === undefined || link === null) {
                        return;
                    }
                    Redirects.domainSend(domain)
                } else {
                    Redirects.send(link, e)
                }
            }}>{title}</div>
        )
    }
    if (isPage()) {
        return (
            <div className={"header__button header__active"} onClick={(e) => {
                Redirects.send(link, e)
            }}>{title}</div>
        )
    }
    return (
        <div className={"header__button"} onClick={(e) => {
            Redirects.send(link, e)
        }}>{title}</div>
    )

    function hasDomain() {
        return (!(domain === "" || domain === undefined || domain === null))
    }

    function isPage() {

        if (document.location.pathname.toLowerCase().startsWith(link.toLowerCase())) {
            if (!hasDomain())
                return true;
        }
        return false;
    }
}

export default HeaderButton;