import './SocialsStyle.css';

class Socials {
    constructor() {
        this.title = "";
        this.socials = [];
    }

    setTitle(title) {
        this.title = title;
    }

    add(social) {
        this.socials.push(social);
    }

    renderer() {
        return (
            <socialsContainer>
                <socialsTitle>{this.title}</socialsTitle>
                <socialsBody>
                    {this.socials.map((social) => {
                        return social.renderer();
                    })}
                </socialsBody>
            </socialsContainer>
        )
    }
}

export default Socials;