class FormModel {
    static formScope;

    static type;
    static name;
    static label;
    static defaultValue;

    constructor(json) {
        if (typeof (json) != "object") {
            json = JSON.parse(json);
        }

        if (Array.isArray(json)) {
            let contentObjs = [];
            json.forEach((element) => {
                contentObjs.push(element);
            });
            return json;
        }

        this.formScope = json["formScope"];
        this.type = json["type"];
        this.name = json["name"];
        this.label = json["label"];
        this.defaultValue = json["defaultValue"];

        if (Array.isArray(json["childrens"])) {
            let contentObjs = [];
            json["childrens"].forEach((element) => {
                contentObjs.push(element);
            });
            this.childrens = contentObjs;
        } else {
            this.childrens = [];
        }
    }
}

export default FormModel;
