const context = require.context('.', true, /.png$/);

const obj = {};
let i = 0;
context.keys().forEach((key) => {
    obj[i] = context(key);
    i += 1;
});

obj["max"] = i - 1;

export default obj;