import './Footer.css'
import Redirects from "@/commons/utils/Redirects"
import {environment} from "@/commons/configs/Config";

const Footer = () => {
    let currentYear = () => {
        return new Date().getFullYear()
    }
    return (
        <>
            <div className={"footer___Background"}>
                <div className={"footer__content"}>
                    <div className={"footer___box"}>
                            <span className={"footer___title"}>
                                HAI BISOGNO DI AIUTO?
                            </span>
                        <span className={"footer___text"}>
                            <a onClick={(e) => {
                                Redirects.send("/faq", e)
                            }}>Consulta le FAQ</a><br/>
                            <a onClick={(e) => {
                                Redirects.send("/report", e)
                            }}>Richiedi supporto</a><br/>
                            <a onClick={(e) => {
                                Redirects.send("/tos", e)
                            }}>Consulta i T.O.S.</a><br/>
                            </span>
                    </div>
                    <div className={"footer___box"}>
                        <span className={"footer___title"}>LINKS UTILI</span>
                        <span className={"footer___text"}>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("https://www.eblcraft.it/map/mondo1")
                                }}>Mappa Mondo 1</span>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("https://www.eblcraft.it/map/mondo2")
                                }}>Mappa Mondo 2</span>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("https://twitchlink.erenblaze.it")
                                }}>TwitchLink</span>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("https://store.eblcraft.it")
                                }}>Store</span>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("https://discord.erenblaze.it")
                                }}>Discord</span>
                                <span className={"footer___links"} onClick={(e) => {
                                    Redirects.send("/social")
                                }}>Social</span>
                            </span>
                    </div>
                    <div className={"footer___box"}>
                        <span className={"footer___title"}>Supportaci</span>
                        <span className={"footer___text"}>
                                Vuoi supportare il server? <br/>
                                Acquistando un pacchetto sul nostro store oltre a supportarci avrai dei vantaggi in game!<br/>
                            </span>

                        <button className={"footer___button"} onClick={(e) => {
                            Redirects.send("https://store.eblcraft.it", e)
                        }}>Store
                        </button>
                    </div>
                </div>
            </div>
            <div className="footer__footer">
                <div className={"footer__footer_left"}>
                    Not an official Minecraft Server.<br/>
                    Not appoved by or associated with Mojang or Microsoft.
                </div>

                <div className={"footer__footer_center"}>
                    Copyright © ErenBlaze 2019 - {currentYear()}
                </div>
                <div className={"footer__footer_right"}>
                    P.IVA 09262890966<br/>
                    <a href={"mailto:supporto@eblcraft.it"}>supporto@eblcraft.it</a>
                </div>
            </div>
        </>
    );
}

export default Footer;