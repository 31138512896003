import React from "react";
import Redirects from "@/commons/utils/Redirects";

type Link = {
    to?: string,
    domain?: string,
    parameters?: {}
}
const Redirect: React.FC<Link> = ({to, domain, parameters}) => {
    if (domain !== undefined) {
        Redirects.domainSend(domain, to, parameters)
        return null;
    }
    if (to !== undefined) {
        Redirects.send(to, null, parameters)
        return null;
    }
    return null;
}

export default Redirect;
