import {useParams} from 'react-router-dom';
import {allFiles} from '@/commons/resources/tos/files';
import PDF from "./PDF";
import React from 'react';

const PDFViewer = () => {
    let {nome} = useParams<{ nome?: string }>();

    // If nome is undefined, return a message or handle accordingly
    if (typeof nome !== 'string') {
        return <div>No file name provided.</div>;
    }

    let name: string = nome.toLowerCase();
    const selectedFile = allFiles.find(file => file.path.includes(name));

    return (
        <>
            {selectedFile ? <PDF file={selectedFile}/> : <div>File not found.</div>}
        </>
    );
}

export default PDFViewer;