import styles from './AdminReports.module.css';
import reportStyles from "@/commons/styles/ReportStyles.module.css";
import React, {useEffect} from "react";
import Post from "@/commons/objects/post/Post";
import Posts from "@/commons/objects/post/Posts";
import moment from 'moment';
import {getMinecraftOther, getUser} from "@/commons/utils/others/Account";
import Categories from "@/commons/objects/category/Categories";
import Cookies from "js-cookie";
import Redirector from "@/commons/utils/Redirects";
import {environment} from '@/commons/configs/Config';

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import {getMinecraftHead} from "@/commons/utils/others/Users";

const MainUserReports = () => {
    let page = 0;
    let maxPage = 1;
    let itemsPerPage = 5;
    let user;
    let totalItems = 0;

    let visiblePosts = new Posts();
    let categories = new Categories();

    async function loadData() {
        user = await getUser(Cookies.get(environment.tokenName));
        //await loadCatFilters();
    }

    //Gestione dei filtri
    async function searchFilter() {
        let input = document.getElementById("searchInput");
        let filter = input.value.toUpperCase();

        if (filter.length === 0) {
            console.log("EMpty")
            if (itemsPerPage >= totalItems)
                await updatePageItemsValue(itemsPerPage);
        } else {
            if (itemsPerPage < totalItems)
                await updatePageItemsValue(totalItems);
        }

        let table = document.getElementById("reportsTable");
        let reportDiv = table.getElementsByClassName(reportStyles.reportMainBox);

        for (let i = 0; i < reportDiv.length; i++) {
            let reportTitle = reportDiv[i].querySelector("#report_title");
            let reportUsername = reportDiv[i].querySelector("#report_username");
            let reportCategory = reportDiv[i].querySelector("#report_category");

            let titleValue = reportTitle.textContent || reportTitle.innerText;
            let categoryValue = reportCategory.textContent || reportCategory.innerText;
            let usernameValue = reportUsername.textContent || reportUsername.innerText;
                if (titleValue.toUpperCase().indexOf(filter) > -1 || categoryValue.toUpperCase().indexOf(filter) > -1 || usernameValue.toUpperCase().indexOf(filter) > -1) {
                    reportDiv[i].style.display = "";
                } else {
                    reportDiv[i].style.display = "none";
                }
        }
        loadPageBox();
    }

    async function updatePageItems() {
        //Chiamato quando si cambia il numero di elementi per pagina
        let selector = document.getElementById("itemsPerPage");
        let selectedItems = selector.value;
        updatePageItemsValue(selectedItems);
    }

    async function updatePageItemsValue(value) {
        itemsPerPage = value;

        clearReports();
        await updateFilters(getFilters());
    }

    async function updatePage(selectedPage) {
        page = selectedPage;

        if (page < 0) page = 0;
        if (page >= maxPage) page = maxPage - 1;

        clearReports();
        await updateFilters(getFilters());

        let pageView = document.getElementById("currentPage");
        pageView.innerHTML = (page + 1) + "/" + (maxPage);
    }

    async function updateFilters(filter) {
        let items = await getLoadFilterItems(filter);
        await elaboratePosts(items).then(r => {
            renderTickets();
        });
        loadPageBox();
    }

    //Caricamento dei post da mostrare
    async function getLoadFilterItems(filter) {
        let url;

        url = environment.apiUrlTwo + '/posts/getAllUserFiltered/';

        const userToken = Cookies.get(environment.tokenName);
        let pageData = {"page": page, "items": itemsPerPage};
        const result = fetch(url + "?status=" + filter, {
            method: 'POST',
            headers: {
                'Authorization': userToken,
                'Content-Type': 'application/json',
            },
            body:
                JSON.stringify(pageData)
        })

        const rest = await result;
        return rest
    }

    async function elaboratePosts(items) {
        visiblePosts.clear();
        let json = await items.json();

        let posts = json["content"];
        let totalElements = json["totalElements"];
        totalItems = totalElements;
        for (let i = 0; (i < posts.length) && (i < itemsPerPage); i++) {
            let post = new Post(posts[i]);
            visiblePosts.addPost(post);
        }
    }

    //Display dei ticket userreports
    function clearReports() {
        let table = document.getElementById('reportsTable');
        table.innerHTML = "";
    }

    function coloredStatus(status) {
        switch (status.toUpperCase()) {
            case "HIDED":
                return "<span style='color:gray; font-weight:bold'>NASCOSTO</span>"
            case "CLOSED":
                return "<span style='color:darkred; font-weight:bold'>CHIUSO</span>"
            case "OPEN":
                return "<span style='color:lime; font-weight:bold'>APERTO</span>"
            default:
                return `<span style='color:goldenrod; font-weight:bold'>${status.toUpperCase()}</span>`
        }
    }

    async function renderTickets() {
        let posts = visiblePosts.getPosts();
        let totalElements = posts.length;
        let table = document.getElementById('reportsTable');
        table.innerHTML = "";

        for (let i = 0; i < totalElements; i++) {
            let user = posts[i].user_id;
            let title = posts[i]["title"];
            let category = posts[i]["category_id"];
            let status = posts[i]["status"];
            let postID = posts[i]["id"];

            let mcData = await getMinecraftOther(user.id);
            let userName = user["username"];
            let userAvatar = await getMinecraftHead(mcData, userName);

            let localDateTime = posts[i]["time"];
            const parsedDate = moment(localDateTime);
            const millis = parsedDate.valueOf();
            const time = moment(millis).format('DD-MM-YYYY HH:mm:ss');

            let mainDiv = document.createElement("div");
            mainDiv.className = reportStyles.reportMainBox;
            mainDiv.id = "reportBox" + i;

            let userAvatarImage = document.createElement("img");
            userAvatarImage.className = reportStyles.reportImage;
            userAvatarImage.src = userAvatar;

            let reportUsername = document.createElement("div")
            reportUsername.className = reportStyles.reportUsername;
            let reportTime = document.createElement("div");
            reportTime.className = reportStyles.reportTime;
            let reportCategory = document.createElement("div");
            reportCategory.className = reportStyles.reportCategory;
            let reportTitle = document.createElement("div");
            reportTitle.className = reportStyles.reportTitle;
            let reportStatus = document.createElement("div");
            reportStatus.className = reportStyles.reportStatus;

            //Per la ricerca
            reportUsername.id = "report_username";
            reportTitle.id = "report_title";
            reportCategory.id = "report_category";

            let reportUsernameSpan = document.createElement("span")
            reportUsernameSpan.innerHTML = user.username;
            let reportTimeSpan = document.createElement("span");
            reportTimeSpan.innerHTML = time;
            let reportCategorySpan = document.createElement("span");
            reportCategorySpan.innerHTML = category["name"];
            let reportTitleSpan = document.createElement("span");
            reportTitleSpan.innerHTML = title;
            let reportStatusSpan = document.createElement("span");
            reportStatusSpan.innerHTML = coloredStatus(status);

            reportUsername.appendChild(userAvatarImage)
            reportUsername.appendChild(reportUsernameSpan)
            reportTime.appendChild(reportTimeSpan);
            reportCategory.appendChild(reportCategorySpan);
            reportTitle.appendChild(reportTitleSpan);
            reportStatus.appendChild(reportStatusSpan);

            mainDiv.appendChild(reportUsername);
            mainDiv.appendChild(reportTitle);
            mainDiv.appendChild(reportCategory);
            mainDiv.appendChild(reportStatus);
            mainDiv.appendChild(reportTime);

            mainDiv.onclick = (event) => Redirector.send(environment.reportsUrl + "post/" + postID, event);
            table.appendChild(mainDiv);
        }
    }

    function loadPageBox() {
        maxPage = Math.ceil(totalItems / itemsPerPage);
        let pageView = document.getElementById("currentPage");
        pageView.innerHTML = (page + 1) + "/" + (maxPage);

        let itemsBox = document.getElementById("currentItems");
        itemsBox.innerHTML = "Reports: " + totalItems;
    }

    useEffect(() => {
        loadData().then(async r => {
            document.getElementById("filtersOpened").checked = true;
            switchFilter("open");
        });
    });

    function getFilters() {
        let isOpen = document.getElementById("filtersOpened").checked;
        let isClosed = document.getElementById("filtersClosed").checked;

        if (isOpen && !isClosed) {
            return "open";
        } else if (!isOpen && isClosed) {
            return "closed";
        } else {
            return "all";
        }
    }

    function switchFilter(input) {
        let isOpen = document.getElementById("filtersOpened").checked;
        let isClosed = document.getElementById("filtersClosed").checked;

        if (isOpen && isClosed) {
            if (input === "open") {
                document.getElementById("filtersClosed").checked = false;
            } else {
                document.getElementById("filtersOpened").checked = false;
            }
        } else if (isOpen && !isClosed) {
            document.getElementById("filtersClosed").checked = false;
        } else if (!isOpen && isClosed) {
            document.getElementById("filtersOpened").checked = false;
        }
        updateFilters(getFilters()).then();
    }

    const options = [];
    for (let i = 5; i <= 20; i += 5) {
        options.push(<option key={i} value={i}>{i}</option>);
    }

    return (
        <>
            <Background/>
            <div className={styles.page__body}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={styles.page__content}>
                    <div className={styles.MainAdmin_ReportsMainBox}><br/>
                        <span className={styles.MainAdmin_ReportsTitle}>
                        I Reports degli utenti
                        </span><br/>
                        <span id={"currentItems"}>Reports:</span>
                        <div className={styles.MainAdmin_reportSuperBox}>
                            <div name={"filters"} id={"filters"} className={styles.MainAdmin_filtersBox}>
                                <span className={styles.MainAdmin_filtersTitle}>Stato</span>
                                <span className={"smallerInput"}><input type="checkbox" id={"filtersOpened"} value={"1"}
                                                                        onChange={() => switchFilter("open")}/> Aperto</span>
                                <span className={"smallerInput"}><input type="checkbox" id={"filtersClosed"} value={"1"}
                                                                        onChange={() => switchFilter("close")}/> Chiuso</span>
                            </div>
                            <div>
                                <div className={styles.MainAdmin_reportsTop}>
                                    <input type="text" placeholder="Cerca Report" id="searchInput"
                                           onChange={searchFilter}/>
                                </div>
                                <br/>
                                <div id={"reportsTable"} className={styles.MainAdmin_ReportsTableBox}>

                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className={styles.MainAdmin_pageSelector}>
                            <label>Reports per pagina: </label>
                            <select id={"itemsPerPage"} onChange={updatePageItems}>
                                {options}
                            </select>
                        </div>
                        <div className={styles.MainAdmin_pageSelector}>
                            <label>Pagina: </label>
                            <button onClick={() => updatePage(0)}> &lt;&lt; </button>
                            {
                                //Mostrare il selettore << che porta alla prima pagina, < che porta alla pagina precedente, > che porta alla pagina successiva e >> che porta all'ultima pagina
                                <button onClick={() => updatePage(page - 1)}> &lt; </button>
                            }
                            <span id={"currentPage"}>{page + 1}/{maxPage}</span>
                            {
                                //Mostrare il selettore << che porta alla prima pagina, < che porta alla pagina precedente, > che porta alla pagina successiva e >> che porta all'ultima pagina
                                <button onClick={() => updatePage(page + 1)}> &gt; </button>
                            }
                            <button onClick={() => updatePage(maxPage)}> &gt;&gt; </button>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default MainUserReports;