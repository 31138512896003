import {useParams} from 'react-router-dom';
import ApiInterface from "@/commons/api/ApiInterface";
import React, {useEffect} from "react";

import "./style.css";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

const EmailConfirm = () => {
    let {token} = useParams();

    async function verifyEmail() {
        let text = (document.getElementById("emailVerifyText") as HTMLElement);

        ApiInterface.getToken(token).then(async (res) => {
            if (res.status !== 200) {
                if (res.status === 408) {
                    text.innerHTML = "" +
                        "<h3>Token Scaduo</h3>" +
                        "<p>Il token è scaduto, prova a richiederne uno nuovo.</p>";
                } else {
                    text.innerHTML = "" +
                        "<h3>Token Non Valido</h3>" +
                        "<p>Il token non è valido, prova a richiederne uno nuovo.</p>";
                }
            }
            let result = await res.json();
            let scope = result["scope"];

            if (scope !== "EMAIL_VERIFICATION") {
                localStorage.errorName = "invalidScope";
                window.location.href = "/error";
            }

            await ApiInterface.verifyEmail(token).then(async (verificationRes) => {
                if (verificationRes.status !== 200) {
                    localStorage.errorName = "invalidToken";
                    window.location.href = "/error";
                } else {
                    window.location.href = "/profile";
                }
            });
        });
    }

    useEffect(() => {
        verifyEmail();
    }, []);
    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div>
                        <h1>Verifica E-Mail</h1><br/><br/>
                        <div id={"emailVerifyText"}>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default EmailConfirm;