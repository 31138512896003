import './Social.css';

import logo_youtube from "@/commons/resources/images/socials/youtube_icon_white.png";
import logo_instagram from "@/commons/resources/images/socials/instagram_icon_white.png";
import logo_tiktok from "@/commons/resources/images/socials/tiktok_icon_white.png";
import logo_discord from "@/commons/resources/images/socials/discord_icon_white.png";
import logo_threads from "@/commons/resources/images/socials/threads_icon_white.png";

import Socials from "@/commons/objects/social/Socials";
import Social from "@/commons/objects/social/Social";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import React from 'react';

const SocialPage = () => {
    let EBLCraftSocials = new Socials();
    EBLCraftSocials.setTitle("I social EBLCraft");
    EBLCraftSocials.add(new Social("Instagram", logo_instagram, "https://www.instagram.com/eblcraft/"));
    EBLCraftSocials.add(new Social("YouTube", logo_youtube, "https://www.youtube.com/@EblCraft"));
    EBLCraftSocials.add(new Social("TikTok", logo_tiktok, "https://www.tiktok.com/@eblcraft?lang=it-IT"));
    EBLCraftSocials.add(new Social("Threads", logo_threads, "https://www.threads.net/@eblcraft"));
    EBLCraftSocials.add(new Social("Discord", logo_discord, "https://discord.erenblaze.it/"));

    document.title = "EBLCraft - Social"

    return (
        <>
            <Background/>
            <div className={"social__body"}>
                <Header marginTop={2} marginBottom={8}/>

                <div className={"social__socialBox"}>
                    {EBLCraftSocials.renderer()}
                </div>
                <Footer/>
            </div>
        </>
    );

}

export default SocialPage;