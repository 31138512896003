import './Access.css';
import React, {useEffect} from "react";
import ApiInterface from "@/commons/api/ApiInterface";
import Cookies from 'js-cookie';
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import {environment} from "@/commons/configs/Config";
import {sha256Encrypt} from "@/commons/crypt/Encryption";

const Access = () => {
    function setErrorMessage(message: string) {
        let element = document.getElementById("accessMessage");

        if (element === null) throw Error("Errore nel codice! Un elemento risulta Nullo!")
        element.innerHTML = "<span style='color:red'>" + message + "</span>";
    }

    async function updateForgotData() {
        let element: HTMLInputElement = (document.getElementById("emailForgot") as HTMLInputElement)
        let email = element.value;

        try {
            return {
                userId: email,
                scope: "PASSWORD_RESET"
            };
        } catch (error) {
            console.error(error);
        }
    }

    async function forgotSubmit() {
        let element: HTMLInputElement = (document.getElementById("emailForgot") as HTMLInputElement)
        let email = element.value;

        let button = (document.getElementById("sendButtonForgot") as HTMLButtonElement);

        button.classList.add("button--loading");

        if (email === "" || email === undefined) {
            setErrorMessage("Inserisci una email valida!");
            return;
        }
        fetchForgot().then(async r => {
            if (r.status === 200) {
                const token = await r.text();

                Cookies.set('token', token, {expires: 30, path: '/', domain: 'eblcraft.it'});

                button.style.backgroundColor = '#307239';
                button.disabled = true;
                button.innerText = 'Inviato';
                button.classList.remove("button--loading");
            } else {
                if (r.status === 404) {
                    setErrorMessage("L'Email non è registrata!");
                } else {
                    setErrorMessage("Errore interno durante l'invio della mail!");
                }
                button.style.backgroundColor = '#7b3a3a';
                button.disabled = false;
                button.classList.remove("button--loading");
            }
        }).catch(function () {
            setErrorMessage("Si è verificato un errore interno!<br/>Se il problema persiste contatta gli admin!");

            button.style.backgroundColor = '#b34141';
            button.disabled = false;
            button.classList.remove("button--loading");
        })
    }

    async function fetchForgot() {
        let forgotData = await updateForgotData();
        const result = fetch(environment.apiUrlOne + '/token/create/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': ''
            },
            body: JSON.stringify(forgotData),
        })

        return await result
    }

    async function updateLoginData() {
        let username = (document.getElementById("usernameLogin") as HTMLInputElement).value;
        let password = (document.getElementById("passwordLogin") as HTMLInputElement).value;

        try {
            let p = await sha256Encrypt(password);
            return {
                username: username,
                password: p
            };
        } catch (error) {
            console.error(error);
        }
    }

    async function loginSubmit() {
        let userLogin = document.getElementById("usernameLogin");
        let passwordLogin = document.getElementById("passwordLogin");

        let usernameAfter = document.getElementById("usernameLogin-After");
        let passwordAfter = document.getElementById("passwordLogin-After");


        fetchLogin().then(async r => {
            if (userLogin === null || passwordLogin === null || usernameAfter === null || passwordAfter == null) throw Error("Errore nel codice! Un elemento risulta Nullo!");
            if (r.status === 200) {
                const token = await r.text();
                Cookies.set('token', token, {expires: 30, path: '/', domain: 'eblcraft.it'});
                if (window.location.host === "localhost:3000") {
                    Cookies.set('token', token, {expires: 30, path: '/', domain: 'localhost'});
                }


                userLogin.classList.remove("errorInput");
                passwordLogin.classList.remove("errorInput");
                usernameAfter.innerHTML = ""
                passwordAfter.innerHTML = ""

                setTimeout(homeRedirect, 0);
            }
            if (r.status === 404) {
                userLogin.classList.add("errorInput");
                usernameAfter.innerHTML = "<div class='exclamationMark'> </div> Username non presente sul database"
            }
            if (r.status === 401) {
                passwordLogin.classList.add("errorInput");
                passwordAfter.innerHTML = "<div class='exclamationMark'> </div> Password non corrispondente a quella nel database"
            }
        }).catch(function () {
            setErrorMessage("Si è verificato un errore interno! <br/>Se il problema persiste contatta gli Admin!")
        })
    }

    function homeRedirect() {
        window.location.href = "/";
    }

    async function fetchLogin() {
        let loginData = await updateLoginData();

        const result = fetch(environment.apiUrlOne + '/auth/login/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData)
        })

        return await result
    }

    async function updateRegisterData() {
        let username = (document.getElementById("usernameRegister") as HTMLInputElement).value;
        let password = (document.getElementById("passwordRegister") as HTMLInputElement).value;
        let email = (document.getElementById("email") as HTMLInputElement).value;

        try {
            let shaPassword = await sha256Encrypt(password);
            return {
                username: username,
                email: email,
                password: shaPassword
            };
        } catch (error) {
            console.error(error);
        }
    }


    async function registerSubmit() {
        let password = (document.getElementById("passwordRegister") as HTMLInputElement).value;
        let confirmPassword = (document.getElementById("confirmPassword") as HTMLInputElement).value;

        if (password !== confirmPassword) {
            setErrorMessage("Errore durante la registrazione!<br/>Le password non corrispondono!");
            return;
        }

        let registerData = await updateRegisterData();

        ApiInterface.fetchRegister(registerData).then(async r => {
            if (r.status === 200) {
                const token = await r.text();

                Cookies.set('token', token, {expires: 30, path: '/', domain: 'eblcraft.it'});
                setTimeout(homeRedirect, 0);
            } else {
                if (r.status === 302) {
                    const message = await r.text();
                    const value = message.toLowerCase();
                    await resetErrors();
                    if (value === "username") {
                        let usernameElement = document.getElementById("usernameRegister");
                        if (usernameElement === null) throw Error("Errore nel codice! Un elemento risulta Nullo!");
                        usernameElement.classList.add("inputError")
                    }
                    if (value === "email") {
                        let emailElement = document.getElementById("email")
                        if (emailElement === null) throw Error("Errore nel codice! Un elemento risulta Nullo!");
                        emailElement.classList.add("inputError")
                    }
                    setErrorMessage("Email o Nome utente già registrati!");
                } else {
                    setErrorMessage("Errore durante la registrazione!<br/>Potrebbe essere dovuto a un problema del server!");
                }
            }
        }).catch(() => {
            setErrorMessage("Si è verificato un errore interno!<br/>Se il problema persiste contatta gli admin!");
        })
    }


    let type = 0;
    let loginBox: HTMLElement;
    let registerBox: HTMLElement;
    let forgotBox: HTMLElement;

    async function loadData() {
        let element: HTMLElement | null;
        element = document.getElementById("loginBox");
        if (element === null) throw Error("Errore nel codice! Un elemento risulta Nullo!");
        loginBox = element
        element = document.getElementById("registerBox");
        if (element === null) throw Error("Errore nel codice! Un elemento risulta Nullo!");
        registerBox = element
        element = document.getElementById("forgotBox");
        if (element === null) throw Error("Errore nel codice! Un elemento risulta Nullo!");
        forgotBox = element
    }


    async function loadForgot() {
        loginBox.classList.remove("visible");
        registerBox.classList.remove("visible");
        forgotBox.classList.add("visible");
    }

    async function loadLogin() {
        registerBox.classList.remove("visible");
        forgotBox.classList.remove("visible");
        loginBox.classList.add("visible");
    }

    async function loadRegister() {
        loginBox.classList.remove("visible");
        forgotBox.classList.remove("visible");
        registerBox.classList.add("visible");
    }

    async function resetErrors() {
        let usernameElement = document.getElementById("usernameRegister");
        if (usernameElement === null) throw Error("Errore nel codice! Un elemento risulta Nullo!");
        usernameElement.classList.remove("inputError")

        let emailElement = document.getElementById("usernameRegister");
        if (emailElement === null) throw Error("Errore nel codice! Un elemento risulta Nullo!");
        emailElement.classList.remove("inputError")
    }

    async function switchType() {
        if (type === 0) {
            type = 1;
            loadRegister();
        } else {
            type = 0;
            loadLogin();
        }
    }

    useEffect(() => {
        loadData();
        loadLogin();
    });

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === 'Enter') {
            if (registerBox.classList.contains('visible')) {
                registerSubmit();
            } else if (loginBox.classList.contains('visible')) {
                loginSubmit();
            } else if (forgotBox.classList.contains('visible')) {
                forgotSubmit();
            } else {
                console.error("There was an error during the login / register Event. Please use the manual button")
            }
        }
    };

    function removeRead(element: { target: { removeAttribute: (arg0: string) => void; }; }) {
        element.target.removeAttribute("readonly");
    }

    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div>
                        <div className={"mainBox"}>
                            <div className={"innerBox"} id={"innerBox"}
                                 onKeyDown={handleKeyDown}>
                                <div id="accessMessage"></div>
                                <div id={"forgotBox"} className={"formBox"}>
                                    <span className={"typeAccess"}>RESET PASSWORD</span>

                                    <div className={"formContainer"}>

                                        <input type={"email"} id={"emailForgot"} placeholder={"Email"}/>
                                    </div>
                                    <a className={"tinyLine"} onClick={loadLogin}>Vuoi effettuare il Login?</a><br/>
                                    <button onClick={forgotSubmit} id={"sendButtonForgot"}>Invia</button>
                                    <br/>
                                    <br/>
                                </div>
                                <div id={"loginBox"} className={"formBox"}>
                                    <span className={"typeAccess"}>ACCEDI</span>

                                    <div className={"formContainer"}>
                                        <input type={"text"} id={"usernameLogin"} readOnly onFocus={removeRead}
                                               placeholder={"Username"}/>
                                        <span id={"usernameLogin-After"} className={"inputAfterError"}></span>

                                        <input type={"password"} id={"passwordLogin"} readOnly onFocus={removeRead}
                                               placeholder={"Password"}/>
                                        <span id={"passwordLogin-After"} className={"inputAfterError"}></span>
                                    </div>
                                    <button onClick={loginSubmit}>Accedi</button>
                                    <br/>
                                    <a className={"tinyLine"} onClick={loadForgot}>Hai dimenticato la tua
                                        password?</a><br/>
                                    <br/>
                                    <hr/>
                                    <span className={"tinyLine"}>Non hai ancora un account? <a
                                        onClick={switchType}>Registrati qui</a></span>
                                    <br/>
                                    <br/>
                                </div>
                                <div id={"registerBox"} className={"formBox"}>
                                    <span className={"typeAccess"}>REGISTRATI</span>

                                    <div className={"formContainer"}>
                                        <input type={"text"} id={"usernameRegister"} placeholder={"Username"}/>
                                        <input type={"text"} id={"email"} placeholder={"E-Mail"}/>
                                        <input type={"password"} id={"passwordRegister"} placeholder={"Password"}/>
                                        <input type={"password"} id={"confirmPassword"}
                                               placeholder={"Conferma Password"}/>
                                    </div>
                                    <br/>
                                    <div className={"checkBoxContainer"}>
                                        <input className={"checkbox"} type={"checkbox"} id={"termsAndCond"}/>
                                        <label> Accetto i <a href={"https://www.eblcraft.it/tos"} target={"_blank"}
                                                             rel={"noreferrer"}>Termini
                                            e
                                            Condizioni d'uso</a> di
                                            questo
                                            sito</label>
                                    </div>
                                    <br/><br/>
                                    <button onClick={registerSubmit}>Registrati</button>
                                    <br/><br/>
                                    <hr/>
                                    <span className={"tinyLine"}>Hai già un account? <a
                                        onClick={switchType}>Accedi qui</a></span>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Access;