import {environment} from '@/commons/configs/Config';
import Cookies from "js-cookie";


export async function closeReport(reportId, text) {
    let data = {"id": reportId, "text": text}
    const result = fetch(environment.apiUrlOne + '/posts/close/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': Cookies.get(environment.tokenName)
        },
        body: JSON.stringify(data),
    })
    return await result;
}

export async function openReport(reportId, text) {
    let data = {"id": reportId, "text": text}
    const result = fetch(environment.apiUrlOne + '/posts/open/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': Cookies.get(environment.tokenName)
        },
        body: JSON.stringify(data),
    })
    return await result;
}

export async function getHash(reportId) {
    const result = fetch(environment.apiUrlTwo + '/posts/getHash/'+reportId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': Cookies.get(environment.tokenName)
        }
    })
    return await result;
}