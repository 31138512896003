import ApiInterface from "@/commons/api/ApiInterface";
import "./Faq.css"
import React, {useEffect} from "react";
import {useInfoBox} from "@/commons/infoBox/InfoBox";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

const Faq = () => {
    const {addInfo} = useInfoBox();

    function loadFaq() {

        ApiInterface.getFaqs(0, 5).then(async r => {
            if (r.status === 200) {
                let details = await r.json();
                let totalPages = details["totalPages"];
                let isStaffer = await ApiInterface.isStaffer();

                for (let i = 0; i < totalPages; i++) {
                    ApiInterface.getFaqs(i, 5).then(async r => {
                        if (r.status === 200) {
                            document.getElementById("faqMainContent").innerHTML = "";
                            let details = await r.json();

                            let faqs = details["content"];
                            for (let j = 0; j < faqs.length; j++) {
                                let faq = faqs[j];
                                let question = faq["title"];
                                let answer = faq["content"];
                                let id = faq["id"];

                                let faqDiv = document.createElement("div");
                                faqDiv.className = "faqBox";
                                faqDiv.id = id;
                                let questionDiv = document.createElement("div");
                                questionDiv.className = "question";
                                questionDiv.innerHTML = question;
                                questionDiv.innerHTML += "<span class='faqArrow'></span>";
                                let answerDiv = document.createElement("div");
                                answerDiv.className = "answer";
                                answerDiv.style.display = "none";
                                answerDiv.innerHTML = answer;

                                if (isStaffer) {
                                    let deleteButton = document.createElement("button");
                                    deleteButton.className = "adminButton";
                                    deleteButton.innerHTML = "Elimina";
                                    deleteButton.onclick = function () {
                                        ApiInterface.deleteFaq(id).then(async r => {
                                            if (r.status === 200) {
                                                faqDiv.remove();
                                            }
                                        })
                                    }
                                    answerDiv.innerHTML += "<br>";
                                    answerDiv.innerHTML += "<br>";
                                    answerDiv.appendChild(deleteButton);


                                    let copyButton = document.createElement("button");
                                    copyButton.className = "adminButton";
                                    copyButton.innerHTML = "Copia";
                                    copyButton.onclick = function () {
                                        navigator.clipboard.writeText(answer)
                                        addInfo("success", "OK", "Il testo è stato copiato!", 5);
                                    }
                                    answerDiv.appendChild(copyButton);
                                }

                                faqDiv.appendChild(questionDiv);
                                faqDiv.appendChild(answerDiv);

                                questionDiv.addEventListener("click", function () {
                                    if (answerDiv.style.display === "none") {
                                        answerDiv.style.display = "inline-block";
                                    } else {
                                        answerDiv.style.display = "none";
                                    }
                                });

                                document.getElementById("faqMainContent").appendChild(faqDiv);
                                let hr = document.createElement("hr");
                                document.getElementById("faqMainContent").appendChild(hr);
                            }
                        }
                    });
                }
            }
        });
    }

    async function loadAdminForm() {
        let isStaffer = await ApiInterface.isStaffer();
        if (isStaffer) {
            let mainDiv = document.createElement("div");
            mainDiv.className = "faqForm";

            let questionDiv = document.createElement("div");
            questionDiv.className = "faqFormQuestion";

            let questionLabel = document.createElement("label");
            questionLabel.innerHTML = "Titolo: ";
            let questionInput = document.createElement("textarea");
            questionInput.id = "faqTitle";
            questionDiv.appendChild(questionLabel);
            questionDiv.appendChild(questionInput);

            let answerDiv = document.createElement("div");
            answerDiv.className = "faqFormAnswer";
            let answerLabel = document.createElement("label");
            answerLabel.innerHTML = "Risposta: ";
            let answerInput = document.createElement("textarea");
            answerInput.id = "faqAnswer";
            answerDiv.appendChild(answerLabel);
            answerDiv.appendChild(answerInput);

            let buttonDiv = document.createElement("div");
            buttonDiv.className = "faqFormButton";
            let button = document.createElement("button");
            button.innerHTML = "Aggiungi";
            button.onclick = () => {
                addFaq();
            }
            buttonDiv.appendChild(button);

            mainDiv.appendChild(questionDiv);
            mainDiv.appendChild(answerDiv);
            mainDiv.appendChild(buttonDiv);

            let title = document.createElement("div");
            title.className = "faqFormTitle";
            title.innerHTML = "<h3>Aggiungi una nuova FAQ</h3></br>";

            document.getElementById("faqAddAdmin").innerHTML = "";
            document.getElementById("faqAddAdmin").appendChild(title);
            document.getElementById("faqAddAdmin").appendChild(mainDiv);
        }
    }

    function addFaq() {
        let question = document.getElementById("faqTitle").value;
        let answer = document.getElementById("faqAnswer").value;
        ApiInterface.createFaq(question, answer).then(async r => {
            if (r.status === 200) {
                window.location.reload()
            }
        });
    }

    useEffect(() => {
        loadAdminForm();
        loadFaq();
    });

    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div className={"faqMainBox"}>
                        <div className={"faqContentBox"}>
                            <div className={"faqTitle"}>
                                FAQ
                            </div>
                            <div className={"faqMainContent"} id={"faqMainContent"}>

                            </div>
                            <div className={"faqBottom"}>
                                <div id={"faqAddAdmin"}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default Faq;