import './ServersList.css';

class ServersList {
    constructor() {
        this.server = []
    }

    addServer(server) {
        this.server.push(server)
    }

    renderer() {
        return (
            <div className={"serverPage__modalitiesBox"}>
                {this.server.map((server) => {
                    return server.renderer()
                })}
            </div>
        )
    }

}

export default ServersList;