import {environment} from '@/commons/configs/Config';
import Cookies from "js-cookie";

export async function createNotification(title, message, uidReceiver, redirectUrl) {
    let token = Cookies.get(environment.tokenName);

    const result = fetch(environment.apiUrlOne + '/notifications/create/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: token
        },
        body: JSON.stringify({
            title: title,
            message: message,
            uid: uidReceiver,
            redirect: redirectUrl
        })
    })
    return await result;
}

export async function getUnreadNotifications(page = 0, items = 1) {
    let token = Cookies.get(environment.tokenName);

    const result = fetch(environment.apiUrlOne + '/notifications/getUnread/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: token
        },
        body: JSON.stringify({
            page: page,
            items: items
        })
    })
    return await result;
}

export async function readNotification(id) {
    let token = Cookies.get(environment.tokenName);

    const result = fetch(environment.apiUrlOne + '/notifications/read/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: token
        },
        body: JSON.stringify({
            id: id
        })
    })
    return await result;
}
export async function readAll() {
    let token = Cookies.get(environment.tokenName);

    const result = await fetch(environment.apiUrlOne + '/notifications/readAll/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            authorization: token
        }
    })
    return result;
}