import Skins from "@/commons/resources/images/minecraft/defaults/skins";
import {isMinecraft} from "./Account";
import {calcCharsMax} from "./Utility";
import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';

export function getMcHead(mcUID) {
    return "https://mc-heads.net/avatar/" + mcUID + ".png";
}

export async function getMcInfo(mcUID) {
    const result = fetch('https://playerdb.co/api/player/minecraft/' + mcUID, {
        method: 'GET'
    })

    const rest = await result;
    return rest
}

export function isBedrockUID(mcUID) {
    return mcUID.startsWith("00000000-");
}

export async function getAccountName() {
    let token = Cookies.get(environment.tokenName);
    let account = await getDetails(token);

    let accountJSON = await account.json();
    let accountName = accountJSON["username"];

    return accountName;
}

export async function getDetails(token) {
    if (token === null || token === undefined || token === "") {
        return null;
    }
    const result = fetch(environment.apiUrlOne + '/details/account/', {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })

    const rest = await result;
    return rest
}

export async function getMinecraftHead(mcData, username) {
    let isMC = false;
    let mcUID = "";
    if (mcData !== null && mcData !== undefined && mcData.status === 200) {
        try {
            let mcJSON = await mcData.json();
            mcUID = mcJSON["_id"];
            isMC = await isMinecraft(mcData);
        } catch (e) {
            console.error("Mc not connected to twitch")
        }
    }
    let skinVal = calcCharsMax(username, Skins["max"]);

    if (isMC) {
        let bedrock = isBedrockUID(mcUID);

        if (bedrock) {
            return Skins[skinVal];
        } else {
            return getMcHead(mcUID);
        }
    } else {
        return Skins[skinVal];
    }
}

export async function getFinalHead(mcUID, username) {
    let skinVal = calcCharsMax(username, Skins["max"]);
    let bedrock = isBedrockUID(mcUID);

    if (bedrock) {
        return Skins[skinVal];
    } else {
        return getMcHead(mcUID);
    }
}