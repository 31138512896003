import {decode} from 'js-base64';

export function decoded(text) {
    if (text.startsWith("V2:")) {
        text = text.substring(3);
        let decoded = decode(text);
        return decoded;
    } else {
        return atob(text);
    }
}