import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import HomePage from "@/link/pages/homePage/HomePage";

//PAGES

const LinkApp: React.FC = () => {
    let path = window.location.pathname;

    if (!path.toLocaleLowerCase().includes("error")) {
        localStorage.setItem("errorName", "pageerror");
    }

    const appContent = createBrowserRouter(
        [{
            path: '/', element: <HomePage/>
        }]
    )
    return (
        <RouterProvider router={appContent}/>
    )
}

export default LinkApp;