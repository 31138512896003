export function randomRange(end) {
    return Math.floor(Math.random() * (end + 1));
}

export function calcCharsMax(string, maxValue) {
    let finalVal = 0;
    for (let i = 0; i < string.length; i++) {
        let c = string.charAt(i);
        let v = c.charCodeAt(0);
        finalVal += v;
    }
    while (finalVal > maxValue) {
        finalVal = finalVal - maxValue;
    }
    return finalVal;
}


export function sendToError(errorName) {
    localStorage.setItem("errorName", errorName);
    window.location.pathname = "/error";
}