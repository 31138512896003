import "@/commons/resources/styles/general.css"
import React, {useEffect, useState} from "react";

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import {useParams} from "react-router-dom";
import {useInfoBox} from "@/commons/infoBox/InfoBox";

const Audios = (): any => {
    const {addInfo} = useInfoBox();

    const fadeDuration = 100;  // Durata del fade in millisecondi.
    const fadeInterval = 5;  // Intervallo di aggiornamento in millisecondi.

    let {connectionID} = useParams();
    let [connected, setConnected] = useState(false);
    let [webSocket, setWebSocket] = useState<WebSocket | null>(null);
    let [baseVolume, setBaseVolume] = useState<number>(100);
    const [songs, setSongs] = useState(new Map());


    function loadSockedEvents() {
        if (webSocket === null) return
        if (connectionID === undefined) return;

        webSocket.onmessage = function (event: any) {
            let commands: any = event.data.split("|");
            commands.forEach((command: string) => {
                let args = command.split(";");
                let cmd = args[0];
                switch (cmd) {
                    case "audio":
                        manageSongs(args)
                        break
                    case "link": {
                        let value = args[1];
                        switch (value.toLowerCase()) {
                            case "ok":
                                addInfo("info", "Connessione Audio", "Richiesta di connessione inviata al server", 5)
                                addInfo("alert", "NON CHIUDERE", "Non chiudere la pagina per poter sentire gli audio riprodotti dal server!")
                                break;
                            default:
                                disconnectWithError("Errore", value);
                                break;
                        }
                        break
                    }
                    case "disconnected":
                        break
                }
            });
        }
        webSocket.onclose = () => {
            stopAllSongs();
            setWebSocket(null);
            songs.clear();
        }
        webSocket.onopen = function () {
            if (webSocket === null || connectionID === undefined) return
            webSocket.send(connectionID);
            setConnected(true)
        };
    }

    function stopAllSongs() {
        for (let song of songs.values()) {
            if (song.audio !== undefined) {
                song.audio.pause();
                song.audio.currentTime = 0;
            }
        }
    }

    function connect() {
        setWebSocket(new WebSocket("wss://audiowss.hydromc.org"));
        if (connectionID === undefined) {
            addInfo("alert", "Token Error", "Il token fornito per la connessione al servizio audio di EBLCraft non è valido<br>" + connectionID, 10)
            return
        }
    }

    useEffect(() => {
        if (webSocket === null) {
            setConnected(false);
        } else {
            loadSockedEvents();
            setConnected(true);
        }
    }, [webSocket]);

    function disconnect() {
        if (webSocket !== undefined && webSocket !== null)
            webSocket.close();
    }

    function disconnectWithError(title: string, message: string) {
        if (webSocket !== undefined && webSocket !== null)
            webSocket.close();

        addInfo("alert", title, message, 10)
    }

    function switchAudioConnection() {
        if (connected) {
            disconnect();
        } else {
            connect();
        }
    }

    function changeVolume(value: any) {
        let volumeReference = Number(value);
        if (volumeReference === -1) volumeReference = baseVolume;
        else setBaseVolume(volumeReference);


        for (let song of songs.values()) {
            if (song.audio !== undefined) {
                let currentVolume = song.volume;
                let finalVolume = (currentVolume / 100) * volumeReference;

                if (finalVolume < 0) finalVolume = 0;
                if (finalVolume > 1) finalVolume = 1;

                fadeVolume(song, currentVolume, finalVolume);
            }
        }
    }

    function fadeVolume(song: any, currentVolume: number, finalVolume: number) {
        if (song && song.audio) {
            let delta = (finalVolume - currentVolume) / 10;
            let volume = currentVolume;

            // Definizione dell'intervalllo temporale (1 ms) per l'aggiornamento del volume
            let interval = setInterval(() => {
                if (volume !== finalVolume) {
                    volume += delta;

                    // Controllo per evitare che il volume superi i limiti 0.0 - 1.0
                    volume = Math.min(1, Math.max(0, volume));

                    song.audio.volume = volume;
                } else {
                    clearInterval(interval);
                }
            }, 1);
        }
    }

    function manageSongs(args: any){
        let groupId = args[1];
        let songURL = args[2];
        let volume = args[3];
        let leftChannel = args[4];
        let rightChannel = args[5];
        let sync = args[6];
        let song = songs.get(groupId);
        if(song === undefined){
            song = {
                "url": songURL,
                "volume": volume,
                "leftChannel": leftChannel,
                "rightChannel": rightChannel,
                "sync": sync,
                "status": 0,
                "audio": undefined,
                "panner": undefined,
            }
        }else {
            song = {
                "url": songURL,
                "volume": volume,
                "leftChannel": leftChannel,
                "rightChannel": rightChannel,
                "sync": sync,
                "status": song.status,
                "audio": song.audio,
                "panner": song.panner
            };
        }if(song.audio===undefined && song.status===0){
            console.log(song.url)
            song.status = 1;
            songs.set(groupId, song);
            fetch(song.url, {mode: 'cors'})
                .then(response => {
                    if(!response.ok){
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.blob();
                })
                .then(blob => {
                    const audioContext = new (window.AudioContext)();
                    const audio = new Audio(URL.createObjectURL(blob));
                    const track = audioContext.createMediaElementSource(audio);
                    const panner = audioContext.createStereoPanner();

                    track.connect(panner).connect(audioContext.destination);
                    audio.loop=true;

                    song.panner = panner;
                    song.audio = audio;
                    audio.currentTime=songs.get(groupId).sync/1000;
                    audio.volume = 0;
                    audio.play();
                    song.status=2;
                    songs.set(groupId, song);
                });
        }
        if(song.status===2){
            if(song.audio.volume===0.0 && volume>0.0){
                song.audio.sync=sync;
                song.audio.play();
            }
            if(volume==-1.0 || volume==-2.0){
                song.audio.pause();
                song.audio.volume=0.0;
            }else{
                song.audio.volume = volume
            }
            if(Math.abs(song.audio.currentTime*1000-sync)>200){
                song.audio.currentTime=sync/1000;
            }
            song.panner.pan.value = rightChannel-leftChannel;
            songs.set(groupId, song);
        }

    }


    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={4} marginBottom={12}/>
                <div className={"page__content"}>
                    <h1>BROLand Music</h1><br/>
                    <input id="unlockaudio" type="button" value={
                        connected ? "Disconnetti Audio" : "Connetti Audio"
                    } onClick={() => {
                        switchAudioConnection()
                    }}/><br/>
                    {baseVolume}%<input type="range" min={"0"} max={100} value={baseVolume} onChange={(event) => {
                    changeVolume(event.target.value);
                }}></input>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default Audios