import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import React from 'react';
import {environment} from "@/commons/configs/Config";

const Home = () => {
    return (
        <>
            <Background/>
            <div className={"home__body"}>
                <Header marginTop={4} marginBottom={12}/>
                <div className={"page__content"}>
                    <div className={"homeBox"}>
                        <center>
                            <span className="mainTitle">EBLCraft</span>
                        </center>
                        <br/>
                        Benvenutə nel sito ufficiale di EBLCraft!<br/>
                        Da qui potrai accedere alla <a href={environment.reportsUrl}>Sezione Reports</a> per chiedere
                        supporto al nostro Staff per qualsiasi
                        tipologia di problema.<br/>
                        <br/>
                        Puoi sostenere il server tramite il nostro <a href={"https://store.eblcraft.it"}> Store
                        ufficiale</a> e l'acquisto dei pacchetti disponibili!
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );

}

export default Home;