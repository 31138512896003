import ApiInterface from "@/commons/api/ApiInterface"
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import React, {useEffect} from "react";
import style from "./chats.module.css";

const Chats = () => {
    useEffect(() => {
        ApiInterface.getFiles("logs/chat/public/").then((filesResult) => {
            console.log(filesResult);
        } );
    }, []);
    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Chats;