import styles from './StillBuilding.module.css';

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import React from 'react';

const StillBuilding = () => {

    return (
        <>
            <Background/>
            <div className={styles.body}>
                <Header marginTop={4} marginBottom={8}/>
                <div className={styles.container}>
                    <div className={styles.title}>Cooming Soon</div>
                    <div className={styles.subtitle}>Questa pagina del sito è ancora in allestimento, stiamo
                        lavorando per migliorare la vostra esperienza<br/>
                        Nel mentre qui trovi i tutorial per accedere al server in <a href={"/java"}>Java
                            Edition</a> e <a
                            href={"/bedrock"}>Bedrock Edition</a>
                        <br/><br/>
                        <div className={styles.subtitle}>
                            Qui invece potrai trovare le mappe dei mondi vanilla <a href={"/map/mondo1"}>Mondo 1</a> e
                            <a href={"/map/mondo2"}> Mondo 2</a>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )

}

export default StillBuilding;