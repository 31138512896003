import styles from './InfoPage.module.css';
import React, {useEffect, useState} from "react";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import infoJson from "./Infos.json"; // Importa il JSON direttamente

// Definizione del tipo per il JSON
interface Info {
    type: string;
    name: string;
    inputName?: string;
    apiRequest?: string;
}

type InfoOption = {
    key: string;
    info: Info;
};

type InfoJson = {
    [key: string]: Info;
};

const typedInfoJson: InfoJson = infoJson; // Tipizzazione corretta

const InfoPage = () => {
    let [option, setOption] = useState<string | undefined>(undefined);
    let [inputValue, setInputValue] = useState<string>("");
    let [isSelectable, setIsSelectable] = useState<boolean>(false);
    let [inputPlaceholder, setInputPlaceholder] = useState<string>("Seleziona un Opzione");
    let [apiUrl, setApiUrl] = useState<string | undefined>(undefined);
    let [options, setOptions] = useState<InfoOption[]>([]);
    let [apiResult, setApiResult] = useState<any>(null); // Per visualizzare il risultato

    useEffect(() => {
        loadSelector();
    }, []);

    function loadSelector() {
        // Estrai direttamente le opzioni dall'importazione JSON
        const options: InfoOption[] = Object.keys(typedInfoJson).map(key => ({
            key,
            info: typedInfoJson[key]
        }));
        setOptions(options);
    }

    function loadSelection(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedOption = options.find(o => o.key === e.target.value);
        if (selectedOption) {
            setOption(selectedOption.info.name);
            if (selectedOption.info.type === "selectable") {
                setIsSelectable(true);
                setInputPlaceholder(selectedOption.info.inputName || "");
                setApiUrl(selectedOption.info.apiRequest);
            } else {
                setIsSelectable(false);
                setInputPlaceholder("Seleziona un Opzione");
                setApiUrl(undefined);
            }
        }
    }

    async function sendRequest() {
        setApiResult("");
        if (apiUrl && inputValue) {
            const url = apiUrl.replace("{var}", inputValue);

            try {
                const response = await fetch(url, {
                    method: 'GET'
                });

                if (response.status === 204) {
                    setApiResult(response.body);
                    return;
                }

                // Controlla se la richiesta ha successo
                if (!response.ok) {
                    throw new Error(`Errore nella richiesta: ${response.status}`);
                }

                // Leggi il risultato come JSON
                const result = await response.json();
                setApiResult(result); // Salva il risultato per visualizzarlo

            } catch (error: any) {
                console.error("Errore nella richiesta API", error);
                alert(`Errore durante la richiesta: ${error.message}`);
            }
        }
    }

    return (
        <>
            <Background/>
            <div className={styles.admin__body}>
                <Header marginTop={0} marginBottom={0}/>
                <div className={styles.admin__content}>
                    <div className={styles.infoForm}>
                        <h2>Informazioni degli Utenti</h2>
                        <div className={styles.break}/>
                        <select id={"InfoSelector"} onChange={loadSelection}>
                            <option selected disabled={true}>Seleziona un Opzione</option>
                            {options.map(option => (
                                <option
                                    key={option.key}
                                    value={option.key}
                                    disabled={option.info.type === "selector"} // Disabilita se il tipo è "selector"
                                >
                                    {option.info.type === "selector"
                                        ? `]--- ${option.info.name} ---[`
                                        : option.info.name
                                    }
                                </option>
                            ))}
                        </select>
                        <input
                            type={"text"}
                            id={"InfoText"}
                            disabled={!isSelectable}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder={inputPlaceholder}
                        />
                        <div className={styles.break}/>
                        <button onClick={sendRequest} id={"InfoButton"} disabled={!isSelectable || !inputValue}>Invia
                            richiesta
                        </button>
                        <div className={styles.break}/>
                        {/* Visualizza il risultato */}
                        {apiResult && (
                            <>
                                <h3>Risultato:</h3>
                                <div className={styles.break}/>
                                <div className={styles.result}>
                                    <pre>{JSON.stringify(apiResult, null, 2)}</pre>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default InfoPage;
