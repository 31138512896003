export class DragNDropFunctions {

    static state = {
        drag: null
    };

    static handleDragStart = (event) => {
        event.dataTransfer.setData('text/plain', event.target.id);
        const img = new Image();
        img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
        event.dataTransfer.setDragImage(img, 0, 0);
        event.target.classList.add("dragging");
        this.setState({drag: event.target});
    }
    static handleDragOver = (event) => {
        event.preventDefault();
        const drag = this.state.drag;
        const drop = event.target;

        if (drop.tagName.toUpperCase() === 'DIV' && drop.id) {
            try {
                const dropRect = drop.getBoundingClientRect();
                // Se il mouse si trova nella parte superiore del div di drop,
                // che corrisponde al 20% della grandezza del div
                if (event.clientY < dropRect.top + dropRect.height * 0.15) {
                    drop.parentNode.insertBefore(drag, drop);
                }
                    // Se il mouse si trova nella parte inferiore del div di drop,
                // che corrisponde al 20% della grandezza del div
                else if (event.clientY > dropRect.bottom - dropRect.height * 0.15) {
                    drop.parentNode.insertBefore(drag, drop.nextSibling);
                }
                    // Altrimenti, il mouse si trova nella parte centrale del div di drop
                // e quindi l'elemento viene aggiunto come children del div di drop
                else {
                    drop.appendChild(drag);
                }
            } catch (e) {
                // Gestisci l'eccezione
            }
        }
    }

    static handleDrop = (event) => {
        event.preventDefault();
        const drag = this.state.drag;
        const drop = event.target;

        if (drop.tagName.toUpperCase() === 'DIV' && drop.id) {
            try {
                const dropRect = drop.getBoundingClientRect();
                // Se il mouse si trova nella parte superiore del div di drop,
                // che corrisponde al 20% della grandezza del div
                if (event.clientY < dropRect.top + dropRect.height * 0.15) {
                    drop.parentNode.insertBefore(drag, drop);
                }
                    // Se il mouse si trova nella parte inferiore del div di drop,
                // che corrisponde al 20% della grandezza del div
                else if (event.clientY > dropRect.bottom - dropRect.height * 0.15) {
                    drop.parentNode.insertBefore(drag, drop.nextSibling);
                }
                    // Altrimenti, il mouse si trova nella parte centrale del div di drop
                // e quindi l'elemento viene aggiunto come children del div di drop
                else {
                    drop.appendChild(drag);
                }
            } catch (e) {
                // Gestisci l'eccezione
            }
        }
        drag.classList.remove("dragging");

        this.setState({drag: null});
    }

    static setState(param) {
        this.state = param;
    }
}