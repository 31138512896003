import styles from './AdminCategories.module.css';
import React, {useEffect, useRef} from "react";
import {DragNDropManager} from "@/commons/objects/dragNDrop/DragNDropManager";
import Cookies from "js-cookie";
import {environment} from "@/commons/configs/Config"
import {useInfoBox} from "@/commons/infoBox/InfoBox";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

const AdminCategories = () => {
    const {addInfo} = useInfoBox();
    let loadingCats = false;
    let dragNDropManagerRef = new useRef(null);

    async function getCats() {
        const userToken = Cookies.get(environment.tokenName);
        const result = fetch(environment.apiUrlOne + '/category/getAll/', {
            method: 'GET', headers: {
                'Authorization': userToken
            }
        })
        const rest = await result;
        return rest
    }

    async function getUsabilites() {
        const userToken = Cookies.get(environment.tokenName);
        const result = fetch(environment.apiUrlOne + '/category/usabilities/', {
            method: 'GET', headers: {
                'Authorization': userToken
            }
        })
        const rest = await result;
        return rest
    }

    function loadCatTitle() {
        let doc = document.getElementById("categoryListTitle");
        doc.innerHTML = "Visualizza Categorie";
        doc.addEventListener("click", () => {
            loadCatList();
        });
    }

    async function updateCat(data) {
        const userToken = Cookies.get(environment.tokenName);
        const result = fetch(environment.apiUrlOne + '/category/edit/', {
            method: 'POST', headers: {
                'Authorization': userToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
        const rest = await result;
        return rest
    }

    async function deleteCategory(id) {
        const userToken = Cookies.get(environment.tokenName);
        const result = fetch(environment.apiUrlOne + '/category/delete/' + id, {
            method: 'POST', headers: {
                'Authorization': userToken
            },
        })
        const rest = await result;
        return rest
    }

    function changeCatStatus(categoryID, status, orderID) {
        let data = {"id": categoryID, "hidden": !status, orderId: orderID};
        updateCat(data).then(r => {
            if (r.status === 200) {
                reloadCats();
                addInfo("success", "Categoria aggiornata", "La categoria " + categoryID + " è ora " + status ? "visibile." : "nascosta.", 5);
            } else {
                reloadCats();
                addInfo("alert", "Errore", "C'è stato un errore durante l'aggiornamento della categoria!", 5);
            }
        });
    }

    function changeUsability(categoryID, value, orderID) {
        let data = {"id": categoryID, "usable": value, orderId: orderID};
        updateCat(data).then(r => {
            if (r.status === 200) {
                reloadCats();
                addInfo("success", "Categoria aggiornata", "La categoria " + categoryID + " è ora utilizzabile da " + value + ".", 5);
            } else {
                reloadCats();
                addInfo("alert", "Errore", "C'è stato un errore durante l'aggiornamento della categoria!", 5);
            }
        });
    }

    function changeOrder(categoryID, value) {
        let data = {"id": categoryID, "orderId": value};
        updateCat(data).then(r => {
            if (r.status === 200) {
                reloadCats();
                addInfo("success", "Categoria aggiornata", "La categoria " + categoryID + " è ora ordinata con id:" + value + ".", 5);
            } else {
                reloadCats();
                addInfo("alert", "Errore", "C'è stato un errore durante l'aggiornamento della categoria!", 5);
            }
        });
    }

    function reloadCats() {
        hideCatList();
        loadCatList();
    }


    function openEditCategory(category) {
        //Scroll to top of the page
        window.scrollTo(0, 0, {behavior: "smooth"});
        // Popolare i dati nel "Form della categoria"
        document.getElementById("categoryName").value = category.name;
        document.getElementById("categoryUsabilities").value = category.usable;
        document.getElementById("categoryID").value = category.id;

        // Popolare il "Form della categoria" con il JSON formattato
        dragNDropManagerRef.current.updateSubmitName("Modifica Categoria");
        dragNDropManagerRef.current.loadJson(category.formattedForm);
        dragNDropManagerRef.current.submitAction = (e) => {
            editCategory(e);
        };
    }

    function clearAll() {
        document.getElementById("categoryName").value = "";
        document.getElementById("categoryUsabilities").value = "";
        document.getElementById("categoryID").value = undefined;

        dragNDropManagerRef.current.updateSubmitName("Crea Categoria");
        dragNDropManagerRef.current.loadJson(undefined);
        dragNDropManagerRef.current.submitAction = (e) => {
            createCategory(e);
        };
    }

    async function loadCatList() {
        if (!loadingCats) {
            loadingCats = true;
        } else {
            return;
        }
        let doc = document.getElementById("categoryListTitle");

        let catList = document.getElementById("categoryListBox");
        let cats = await getCats();
        let jsonText = await cats.json();

        for (let i = 0; i < jsonText.length; i++) {
            let cat = document.createElement("div");
            cat.className = styles.categoryBox;

            //category Name
            let catName = document.createElement("div");
            catName.title = "[" + jsonText[i]["id"] + "] " + jsonText[i]["name"];
            catName.className = styles.categoryName;
            catName.innerHTML = "[" + jsonText[i]["id"] + "] " + jsonText[i]["name"];
            //APPEND
            cat.appendChild(catName);


            //category Usability
            let catUsab = document.createElement("div");
            catUsab.className = styles.categoryUsab;

            let usable = jsonText[i]["usable"];
            let selector = document.createElement("select");
            selector.className = styles.categorySelector;

            let usabilities = await getUsabilites();
            let usabJson = await usabilities.json();

            for (let j = 0; j < usabJson.length; j++) {
                let option = document.createElement("option");
                let n = usabJson[j];

                option.value = n;
                option.innerHTML = n;

                if (n.toLowerCase() === usable.toLowerCase()) {
                    option.selected = true;
                }
                selector.appendChild(option);
            }
            selector.addEventListener("change", () => {
                changeUsability(jsonText[i]["id"], selector.value, jsonText[i]["orderId"]);
            });
            catUsab.appendChild(selector);
            //APPEND
            cat.appendChild(catUsab);


            //CategoryOrder
            let catOrder = document.createElement("div");
            catOrder.className = styles.categoryOrder;

            let order = jsonText[i]["orderId"];
            if (order <= -1) {
                continue;
            }
            let orderValue = document.createElement("input")
            orderValue.type = "number"
            orderValue.value = order;
            orderValue.step = "1";

            orderValue.addEventListener("change", () => {
                changeOrder(jsonText[i]["id"], orderValue.value);
            });
            catOrder.appendChild(orderValue);
            //APPEND
            cat.appendChild(catOrder);

            //CategoryEdit
            let catEdit = document.createElement("div");
            catEdit.className = styles.categoryEdit;

            let edit = document.createElement("div");
            edit.innerHTML = "Modifica";
            edit.addEventListener("click", () => {
                openEditCategory(jsonText[i]);
            });
            catEdit.appendChild(edit);
            cat.appendChild(catEdit);

            //CategoryDisable
            let catDisable = document.createElement("div");
            catDisable.className = styles.categoryDisable;

            let status = jsonText[i]["hidden"];
            if (status === false) {
                catDisable.innerHTML = "Disabilita";
                cat.classList.add(styles.categoryEnabled);
                catDisable.addEventListener("click", () => {
                    changeCatStatus(jsonText[i]["id"], status, orderValue.value);
                });
            } else {
                let activate = document.createElement("div");
                activate.innerHTML = "Attiva";

                activate.addEventListener("click", () => {
                    changeCatStatus(jsonText[i]["id"], status, orderValue.value);
                });

                let deleteCat = document.createElement("div");
                deleteCat.innerHTML = "Elimina";

                deleteCat.addEventListener("click", () => {
                    deleteCategory(jsonText[i]["id"]).then(r => {
                        if (r.status === 200) {
                            reloadCats();
                            addInfo("alert", "Eliminata", "Categoria eliminata correttamente!", 5);
                        } else {
                            reloadCats();
                            addInfo("alert", "Errore", "C'è stato un errore durante l'eliminazione della categoria!", 5);
                        }
                    });
                });

                catDisable.appendChild(activate);
                catDisable.appendChild(deleteCat);
                cat.classList.add(styles.categoryDisabled);
            }
            //APPEND
            cat.appendChild(catDisable);


            catList.appendChild(cat);
        }

        doc.innerHTML = "Nascondi Categorie";
        let old_element = doc;
        let new_element = old_element.cloneNode(true);
        old_element.parentNode.replaceChild(new_element, old_element);

        new_element.addEventListener("click", () => {
            hideCatList();
        });
    }

    function hideCatList() {
        loadingCats = false;
        let catList = document.getElementById("categoryListBox");
        catList.innerHTML = "";

        let doc = document.getElementById("categoryListTitle");


        doc.innerHTML = "Visualizza Categorie";
        let old_element = doc;
        let new_element = old_element.cloneNode(true);
        old_element.parentNode.replaceChild(new_element, old_element);

        new_element.addEventListener("click", () => {
            loadCatList();
        });
    }

    async function loadCreation() {
        let doc = document.getElementById("categoryUsabilities");

        let usabilities = await getUsabilites();
        let usabJson = await usabilities.json();
        let option = document.createElement("option");
        option.value = "none";
        option.innerHTML = "Seleziona una usabilità";
        option.selected = true;
        doc.appendChild(option);

        for (let j = 0; j < usabJson.length; j++) {
            let option = document.createElement("option");
            let n = usabJson[j];

            option.value = n;
            option.innerHTML = n;
            doc.appendChild(option);
        }


    }

    useEffect(() => {
        loadCatTitle();
        loadCreation();
        loadCatList();
    }, []);

    function createCategory(e) {
        let jsonFormat = e;
        let name = document.getElementById("categoryName").value;
        let usability = document.getElementById("categoryUsabilities").value;

        if (name === "" || usability === "none") {
            addInfo("alert", "Dati mancanti", "Compila tutti i campi con i dati richiesti!", 5);
            return;
        }

        if (usability === "separator") {
            if (!name.startsWith("---]") && !name.endsWith("[---")) {
                name = "---]" + name + "[---";
            }
        }

        let categoryJSON = {
            "name": name,
            "usable": usability,
            "hidden": false,
            "formattedForm": JSON.stringify(jsonFormat)
        };


        fetch(environment.apiUrlOne + '/category/create/', {
            method: 'POST', headers: {
                'Authorization': Cookies.get(environment.tokenName),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryJSON)
        }).then(r => {
            if (r.status === 200) {
                addInfo("success", "Categoria Creata", "La categoria è stata creata correttamente!", 5);
                reloadCats();
            } else {
                addInfo("alert", "Errore", "C'è stato un errore durante la creazione della categoria!", 5);
            }
        })
    }

    function editCategory(e) {
        let jsonFormat = e;
        let name = document.getElementById("categoryName").value;
        let id = document.getElementById("categoryID").value;
        let usability = document.getElementById("categoryUsabilities").value;

        if (name === "" || usability === "none") {
            addInfo("alert", "Dati mancanti", "Compila tutti i campi con i dati richiesti!", 5);
            return;
        }

        if (usability === "separator") {
            if (!name.startsWith("---]") && !name.endsWith("[---")) {
                name = "---]" + name + "[---";
            }
        }

        let categoryJSON = {
            "id": id,
            "name": name,
            "usable": usability,
            "hidden": null,
            "formattedForm": JSON.stringify(jsonFormat),
            "orderId": -1
        };


        fetch(environment.apiUrlOne + '/category/edit/', {
            method: 'POST', headers: {
                'Authorization': Cookies.get(environment.tokenName),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(categoryJSON)
        }).then(r => {
            if (r.status === 200) {
                clearAll();
                addInfo("success", "Categoria modificata", "La categoria è stata modificata correttamente", 5);
                reloadCats();
            } else {
                addInfo("alert", "Errore", "C'è stato un errore durante la modifica della categoria!", 5);
            }
        })
    }


    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div className={styles.categoriesMainBox}><br/>
                        <div className={styles.catsMain}>
                            <div id={"categoryList"} className={styles.categoryList}>
                                <div className={styles.categoryListTitle} id={"categoryListTitle"}>

                                </div>
                                <div id={"categoryListBox"} className={styles.categoryListBox}>

                                </div>
                            </div>
                            <div id={"categoryCreate"}>
                                <div className={styles.categoryCreateTitle} id={"categoryCreateTitle"}>
                                    Crea Categoria
                                </div>
                                <div className={styles.categoryCreateBox}>
                                    <div className={styles.categoryCreateBoxTopLeft}>
                                        <div className={styles.categoryClearButton} id={"categoryClearButton"}
                                             onClick={() => clearAll()}>x
                                        </div>
                                        <div className={styles.categoryCreateBoxNames}>
                                            <label className={styles.categoryCreateLabel}>Nome Categoria</label>
                                            <label className={styles.categoryCreateLabel}>ID</label>
                                            <input className={styles.categoryCreateInput} id={"categoryName"}/>
                                            <input type={"number"} disabled={true}
                                                   className={styles.categoryCreateInput}
                                                   id={"categoryID"}/>
                                        </div>
                                        <label className={styles.categoryCreateLabel}>Usabilità</label><br/>
                                        <select className={styles.categoryCreateSelector}
                                                id={"categoryUsabilities"}></select><br/><br/>
                                    </div>
                                    <div className={styles.categoryCreateBoxBottom} id={"categoryCreateBoxBottom"}>
                                        <DragNDropManager mainLabel={"Form della Categoria"}
                                                          mainLine2={"Trascina i campi per creare il form della categoria"}
                                                          onSubmit={createCategory} submitName={"Crea Categoria"}
                                                          ref={dragNDropManagerRef}/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default AdminCategories;