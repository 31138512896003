import './ServerHelp.css';
import "@/commons/resources/styles/general.css"
import eblDERP from '@/commons/resources/images/emotes/eblDERP.webp'
import appStore from '@/commons/resources/images/button/button_appstore.png'
import playStore from '@/commons/resources/images/button/button_playstore.png'
import React from "react";

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

const Bedrock = () => {
    return (
        <>
            <Background/>
            <div className={"serverHelp__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"serverHelp__content"}>
                    <div className={"info-mainBox"}>
                        <div className={"boxTitle"}>
                            Come entro nel server da Bedrock?
                        </div>
                        <div className={"boxContent"}>
                            <>
                                <img src={eblDERP} alt={"Bedrock"} className={"eblDERP"}/><br/>
                            </>
                            <div className={"text"}>Se giochi da XBOX, PLAYSTATION, SWITCH, MOBILE o qualsiasi altro
                                dispositivo
                                compatibile con Minecraft Bedrock dovrai scaricare l'app <span
                                    className={"italic"}>MC Server Connector</span>.<br/><br/>
                                <div className={"images"}>
                                    <a href={"https://apps.apple.com/ch/app/mc-server-connector/id1548251304"}
                                       target={"_blank"} rel={"noreferrer"}><img src={appStore} alt={"App Store"}
                                                                                 className={"appStore"}/>
                                    </a>
                                    <a href={"https://play.google.com/store/apps/details?id=com.smokiem.mcserverconnector"}
                                       target={"_blank"} rel={"noreferrer"}><img src={playStore} alt={"App Store"}
                                                                                 className={"appStore"}/>
                                    </a>
                                </div>
                                <br/>

                                Apri l'app, inserisci l'ip <span
                                    style={{"color": "green", "font-weight": "bold"}}>play.eblcraft.it</span> senza
                                modificare la porta.<br/>
                                Clicca start server nell'app e attendi che si connetta.<br/>
                                Se hai seguito tutti i passaggi correttamente potrai entrare nel server dal tuo
                                dispositivo
                                accedendo alla partita in lan.<br/><br/>
                                <span style={{"color": "#d80000", "font-weight": "bold"}}>ATTENZIONE: Assicurati che telefono e console siano sulla stessa rete!</span>
                                <br/><br/>
                                <span style={{"color": "#d80000", "font-weight": "bold"}}>ATTENZIONE: Per alcuni dispositivi potrebbe essere necessario utilizzare l'abbonamento della console stessa per connettersi online!</span><br/>
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default Bedrock