import './DropdownMenu.css';
import React from "react";

class DropdownMenu {
    constructor(id, dropdownID, dropdownContent = dropdownID, char = "▼", isSubmenu = false) {
        this.id = id;
        this.active = false;
        this.elements = [];
        this.dropdownText = dropdownID;
        this.dropdownContent = dropdownContent;
        this.isSubmenu = isSubmenu;

        this.char = char;
    }

    clickDropdown = () => {
        this.active = !this.active;

        this.updateContainer();
        this.updateChar();
    }

    updateContainer() {
        let container = document.getElementById(this.dropdownText + "_elementContainer");
        if (this.active) {
            container.style.display = "block";
        } else {
            container.style.display = "none";
        }
    }

    updateChar() {
        let currentChar = document.getElementById("currentChar");
        if (this.active) {
            currentChar.classList.add("dropdownInverted");
        } else {
            currentChar.classList.remove("dropdownInverted");
        }
    }

    addElement = (element) => {
        this.elements.push(element);
    }

    render() {
        if (!this.isSubmenu) {
            return (
                <div className={this.id + "dropdownMenu"}>
                    <div className={this.id + "dropdownText"} onClick={this.clickDropdown}>
                        {this.dropdownContent}<span id={"currentChar"} className={"dropdownChar"}>{this.char}</span>
                    </div>
                    <div className={this.id + "dropdownElements"} id={this.dropdownText + "_elementContainer"}>
                        {this.elements.map((element, index) => {
                            return element.render(index);
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={this.id + "dropdownElement"}>
                    <div className={this.id + "dropdownTextElement"} onClick={this.clickDropdown}>
                        {this.dropdownContent}<span id={"currentChar"} className={"dropdownChar"}>{this.char}</span>
                    </div>
                    <div className={this.id + "dropdownElements"} id={this.dropdownText + "_elementContainer"}>
                        {this.elements.map((element, index) => {
                            return element.render(index);
                        })}
                    </div>
                </div>
            );
        }
    }
}

export default DropdownMenu;
