let types = {
    "warning": "warning",
    "alert": "alert",
    "success": "success",
    "info": "info",
}

let BaseInfoBoxsTable = {
    "invalidPassword": {
        title: 'Accesso Fallito',
        text: 'I dati di accesso inseriti non sono corretti.',
        type: types.alert,
        time: 5,
    },
    "alreadyLogged": {
        title: 'Pagina non disponibile',
        text: 'La pagina richiesta non è disponibile se sei già Loggato.',
        type: types.info,
        time: 5,
    },
    "notLogged": {
        title: 'Accesso Negato',
        text: 'Non sei Loggato con nessun account.<br>Assicurati di essere Loggato con l\'account corretto.',
        type: types.alert,
        time: 8,
    },
    "pageAccessDenied": {
        title: 'Accesso Negato',
        text: 'Non hai i permessi necessari a visualizzare la pagina richiesta.' +
            '<br>Assicurati di essere Loggato con l\'account corretto e di avere i permessi necessari.',
        type: types.alert,
        time: 8,
    }
}
const getBaseInfoBox = (id: any) => {
    // @ts-ignore
    let baseInfoBox = BaseInfoBoxsTable[id];

    let jsonString = JSON.stringify(baseInfoBox);

    return btoa(jsonString);
}

export default getBaseInfoBox;