import Redirects from "@/commons/utils/Redirects";

class ServerObj {
    constructor(title, description, image, link) {
        this.title = title;
        this.description = description;
        this.image = image;
        this.link = link;
    }

    renderer() {
        return (
            <div className={"serverPage__mod"} onClick={(e) => {
                Redirects.send(this.link, e)
            }}>
                <div className={"serverPage__mod_image"}>
                    <img src={this.image} alt={"server_image"}/>
                </div>
                <div className={"serverPage__mod_text"}>
                    <span className={"serverPage__mod_title"}>{this.title}</span>
                    <span className={"serverPage__mod_description"}
                          dangerouslySetInnerHTML={{__html: this.description}}></span>
                </div>
            </div>
        )
    }
}

export default ServerObj;