import {environment} from '@/commons/configs/Config';
import Cookies from "js-cookie";

export async function getToken(tokenValue) {
    const result = fetch(environment.apiUrlOne + '/token/getToken/' + tokenValue, {
        method: 'GET'
    })
    return await result;
}

export async function sendVerifyEmail() {
    const result = fetch(environment.apiUrlOne + '/token/create', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + Cookies.get(environment.tokenName)
        }, body: JSON.stringify({"scope": "EMAIL_VERIFICATION"})
    })
    return await result;
}

export async function verifyEmail(tokenValue) {
    const result = fetch(environment.apiUrlOne + '/account/verifyEmail/' + tokenValue, {
        method: 'GET'
    })
    return await result;
}

export async function recoverPassword(tokenValue, password) {
    const result = fetch(environment.apiUrlOne + '/account/recoverPassword/' + tokenValue, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: password,
    })
    return await result;
}

export async function useToken(tokenValue) {
    const result = fetch(environment.apiUrlOne + '/token/useToken/' + tokenValue, {
        method: 'GET'
    })
    return await result;
}