import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';

export async function fetchRegister(registerData) {
    const result = fetch(environment.apiUrlOne + '/auth/register/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(registerData),
    })
    const rest = await result;

    if (rest.status === 200) {
        const token = await rest.clone().text();
        await fetchEmailChange(token, "skip");
        window.location.href = "/profile";
    }

    return rest;
}

export async function fetchEmailChange(token, currentPassword) {
    if (currentPassword !== "skip") {
        let r = await changeAccountData(token, {"emailVerified": "false", "currentPassword": currentPassword});
    }

    await fetch(environment.apiUrlOne + '/token/create/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({"scope": "EMAIL_VERIFICATION"}),
    })
}

export async function changeAccountData(token, data) {
    const result = await fetch(environment.apiUrlOne + '/account/changeData/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(data),
    });
    return result;
}

export async function getSelf() {
    let token = Cookies.get(environment.tokenName);
    let account = await getDetails(token);

    if (account === null || account === undefined || account === "") {
        Cookies.remove(environment.tokenName);
        return null;
    }
    try {
        return await account.json();
    } catch (e) {
        Cookies.remove(environment.tokenName);
        return null;
    }
}

export async function getDetails(token) {
    if (token === null || token === undefined || token === "") {
        return null;
    }
    const result = fetch(environment.apiUrlOne + '/details/account/', {
        method: 'GET',
        headers: {
            'Authorization': token
        }
    })

    const rest = await result;
    return rest
}

export async function connectTwitch(id) {
    let token = Cookies.get(environment.tokenName);
    return await fetch(environment.apiUrlOne + '/twitch/connect/' + id, {
        method: 'POST',
        headers: {
            'Authorization': token
        }
    });
}

export async function checkLogged() {
    const res = await getSelf();
    if (res === null) {
        Cookies.remove(environment.tokenName);
        return false;
    }

    return true;
}