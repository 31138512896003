import styles from './ErenBlaze.module.css';
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import ErenSocialPage from "./subPages/social/SocialPage";
import React, {useState} from "react";
import WhoAmIPage from "./subPages/who/WhoAmIPage";
import ErenBlazeDownloads from "./subPages/downloads/ErenBlaze-Downloads";
import ErenGuides from "./subPages/guide/ErenGuides";

type SubPageButtonProps = {
    name: string;
    element: any;
};

const ErenBlaze = () => {
    const [currentSubPage, setCurrentSubPage] = useState(<WhoAmIPage/>);
    const [currentSubPageName, setCurrentSubPageName] = useState("Chi sono?");

    document.title = "EBLCraft - ErenBlaze"

    let SubPageButton: React.FC<SubPageButtonProps> = ({name, element}) => {
        return (
            <div className={`${styles.subPageSelector__item} ${currentSubPageName === name ? styles.activeItem : ""} `}
                 onClick={() => {
                     setCurrentSubPage(element)
                     setCurrentSubPageName(name)
                 }}>
                {name}
            </div>
        );
    }

    return (
        <>
            <Background/>
            <div className={styles.erenblaze__body}>
                <Header marginTop={1} marginBottom={5}/>
                <div className={styles.erenblaze__content}>
                    <div className={styles.subPageSelector}>
                        <SubPageButton name={"Chi sono?"} element={<WhoAmIPage/>}/>
                        <SubPageButton name={"I miei social"} element={<ErenSocialPage/>}/>
                        <SubPageButton name={"Guide"} element={<ErenGuides/>}/>
                        <SubPageButton name={"I miei downloads"} element={<ErenBlazeDownloads/>}/>
                    </div>
                    <div id={"subPage"} className={styles.subPages}>
                        {currentSubPage}
                    </div>

                </div>
                <Footer/>
            </div>
        </>
    );

}

export default ErenBlaze;