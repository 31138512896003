class Categories {
    static categories = [];

    constructor() {
    }

    addAllCat(categories) {
        this.categories = [...categories];
    }

    addCat(categories) {
        this.categories.push(categories);
    }

    getAllCats() {
        return this.categories;
    }

    clear() {
        this.categories = [];
    }

    getById(id) {
        return this.categories.find((cat) => cat.id == id);
    }

    getAllByUsability(usability) {
        this.categories = this.categories.filter(
            (cat) => cat.usable.toLowerCase() === usability.toLowerCase()
        );
        return this;
    }

    getAllByHidden(hidden) {
        this.categories = this.categories.filter((cat) => cat.hidden === hidden);
        return this;
    }

    sortCategoriesByOrderId() {
        //Sort from highest to lowest
        this.categories.sort((a, b) => b.orderId - a.orderId);
        return this;
    }

    clone() {
        const newCategories = new Categories();
        newCategories.addAllCat(this.categories);
        return newCategories;
    }
}

export default Categories;