import './ProfilesManager.css';
import ApiInterface from "@/commons/api/ApiInterface"
import React, {useEffect, useState} from "react";
import UserProfile from "@/commons/objects/user/UserProfile";
import Roles from "@/commons/objects/role/Roles";
import Role from "@/commons/objects/role/Role";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import {useInfoBox} from "@/commons/infoBox/InfoBox";


const ProfilesManager = () => {
    const {addInfo} = useInfoBox();
    let [getTotalUsers, setTotalUsers] = useState(0);
    let [getRegisteredUsers, setRegisteredUsers] = useState(0);
    let [getProfiles, setProfiles] = useState([]);
    let [roles, setRoles] = useState(new Roles());
    let [getSearch, setSearch] = useState("");
    let [searchInitialized, setSearchInitialized] = useState(false);
    let [viewer, setViewer] = useState();
    let [viewerInitialized, setViewerInitialized] = useState(false);
    let page = 0;
    let maxPages = 0;
    let itemsPerPage = 5;

    const api = ApiInterface;

    function updateProfile(index, newProfile) {
        let cProfiles = getProfiles;
        cProfiles[index] = newProfile;
        setProfiles(cProfiles);


        let userElement = document.querySelector(`[data-uid="${newProfile.uid}"]`);
        if (userElement === null || userElement === undefined) return;
        createUser(newProfile).then((newElement) => {
                userElement.replaceWith(newElement);
            }
        );
    }

    function updateRole(newRole) {
        let cRoles = roles;
        cRoles.addRole(newRole);
        setRoles(cRoles);
    }

    async function loadSelf() {
        const self = await ApiInterface.getSelf();
        await setViewer(self)
    }

    async function loadRoles() {
        const res = await ApiInterface.getRoles();
        if (res.status === 200) {
            const resJson = await res.json();
            resJson.forEach((roleData) => {
                const role = new Role(roleData.alias, roleData.power);
                updateRole(role);
            });
        }
    }

    useEffect(() => {
        loadSelf();
    }, []);

    useEffect(() => {
        if (!viewerInitialized) {
            setViewerInitialized(true);
            return;
        }
        const loadData = async () => {
            await calcProfiles();
            await loadRoles();
            await loadProfiles();
            await loadPages();
        };

        loadData();
    }, [viewer]);

    useEffect(() => {
        if (getTotalUsers === getRegisteredUsers && getTotalUsers !== 0) {
            let doc = document.getElementById("searchUserInput");
            doc.disabled = false;
            doc.placeholder = "Cerca un utente";
        }
    }, [getTotalUsers]);

    useEffect(() => {
        if (!searchInitialized) {
            setSearchInitialized(true);
            return;
        }
        if (viewer === null || viewer === undefined) return;
        let input = document.getElementById("searchUserInput").value;
        setTimeout(() => {
            if (input.length > 0) {
                if (getSearch === input) {
                    search(input);
                } else {
                    return;
                }
            } else {
                loadItems();
            }
        }, 700)
    }, [getSearch]);

    const calcProfiles = async () => {
        await api.getLightUsers(itemsPerPage, 0, true).then(async (res) => {
            if (res.status !== 200) {
                api.sendToError("apiError");
            }
            const resJson = await res.json();
            setRegisteredUsers(resJson["totalElements"]);
            maxPages = resJson["totalPages"];
        })
    }

    const loadProfiles = async () => {
        let totUsers = 0;
        for (let i = 0; i < maxPages; i++) {
            api.getLightUsers(itemsPerPage, i).then(async (res) => {
                if (res.status !== 200) {
                    api.sendToError("apiError");
                }
                const resJson = await res.json();
                let numberOfElements = resJson["numberOfElements"];
                totUsers += numberOfElements;
                setTotalUsers(totUsers);
                for (let x = 0; x < numberOfElements; x++) {
                    let currentPage = resJson["number"];
                    let itemInPage = x;

                    let itemNumber = (currentPage * itemsPerPage) + itemInPage;
                    let profile = new UserProfile();
                    profile.loadFromJson(resJson["content"][x]);

                    updateProfile(itemNumber, profile)
                }
                if (i === 0) {
                    await loadItems(0);
                }
            })
        }
    }
    const loadPages = async () => {
        document.getElementById("userPrevPages").innerHTML = "";
        document.getElementById("userNextPages").innerHTML = "";

        //Start Page
        let startPageDiv = document.createElement("div");
        startPageDiv.className = "profileManager__userPageButton";
        startPageDiv.innerText = "<<";
        startPageDiv.addEventListener("click", () => {
            page = 0;
            loadItems();
            loadPages();
        });

        //Previous Page if not first
        let previousPageDiv = document.createElement("div");
        previousPageDiv.className = "profileManager__userPageButton";
        previousPageDiv.innerText = "<";
        previousPageDiv.addEventListener("click", () => {
            page--;
            loadItems();
            loadPages();
        });

        //Next Page if not last
        let nextPageDiv = document.createElement("div");
        nextPageDiv.className = "profileManager__userPageButton";
        nextPageDiv.innerText = ">";
        nextPageDiv.addEventListener("click", () => {
            page++;
            loadItems();
            loadPages();
        });

        //End Page
        let endPageDiv = document.createElement("div");
        endPageDiv.className = "profileManager__userPageButton";
        endPageDiv.innerText = ">>";
        endPageDiv.addEventListener("click", () => {
            page = maxPages - 1;
            loadItems();
            loadPages();
        });
        await updatePage();

        document.getElementById("userPrevPages").appendChild(startPageDiv);
        if (page !== 0) {
            document.getElementById("userPrevPages").appendChild(previousPageDiv);
        }
        if (page !== maxPages - 1) {
            document.getElementById("userNextPages").appendChild(nextPageDiv);
        }
        document.getElementById("userNextPages").appendChild(endPageDiv);
    }

    const updatePage = async () => {
        document.getElementById("userPages").innerHTML = "";

        //Show current page / max pages
        let currentPageDiv = document.createElement("div");
        currentPageDiv.className = "profileManager__userPage";
        currentPageDiv.innerText = (page + 1) + "/" + maxPages;

        document.getElementById("userPages").appendChild(currentPageDiv);
    }

    const search = async (input) => {
        await clearItems();
        for (let i = 0; i < getTotalUsers; i++) {
            let profile = getProfiles[i];

            if (profile === undefined) {
                continue;
            }

            let username = profile.username.toLowerCase();
            if (username.includes(input.toLowerCase())) {
                await loadUser(profile);
            }
        }
    }

    const loadItems = async () => {
        await clearItems();

        for (let i = 0; i < itemsPerPage; i++) {
            let itemN = i + (page * itemsPerPage);

            let profile = getProfiles[itemN];
            if (profile !== undefined) {
                loadUser(profile);
            }
        }
    }

    const clearItems = async () => {
        document.getElementById("userList").innerHTML = "";
    }

    async function updateUser(profile) {
        //Get the index of the profile in the array of profiles
        let index = getProfiles.indexOf(profile);

        let userId = profile.id;

        let profileReturn = await api.getUser(userId)
        let newProfile = await profileReturn.json();

        updateProfile(index, newProfile);
    }

    const loadUser = async (profile) => {
        let item = await createUser(profile);

        document.getElementById("userList").appendChild(item);
    }

    const createUser = async (profile) => {
        let item = document.createElement("div");
        item.className = "profileManager__userItem";
        item.setAttribute("data-uid", profile.uid);


        let itemUsername = document.createElement("div");
        itemUsername.className = "profileManager__userItemUsername";
        itemUsername.innerHTML = profile.username + "<br><a style='color: #b90000' href='/user/" + profile.id + "/'>   Visualizza profilo</a>";

        let itemEmail = document.createElement("div");
        itemEmail.className = "profileManager__userItemEmail";
        itemEmail.innerText = profile.email;

        let itemRole = document.createElement("div");
        itemRole.className = "profileManager__userItemRole";
        let role = profile.role["alias"];

        let image = roles.getRoleByAlias(role).getBadge();

        const imageSrc = `data:image/png;base64,${image}`;
        let roleImageDiv = document.createElement("img");
        roleImageDiv.src = imageSrc;
        roleImageDiv.className = "profileManager__userItemRoleImage";

        itemRole.appendChild(roleImageDiv);

        let itemActions = document.createElement("div");
        itemActions.className = "profileManager__userItemActions";

        let itemActionTitle = document.createElement("div");
        itemActionTitle.className = "profileManager__userItemActionTitle";
        itemActionTitle.innerText = "Gestione utente";
        itemActions.appendChild(itemActionTitle);

        let status;
        if (profile.status === null || profile.status === undefined) {
            status = "Normal"
        } else {
            status = profile.status.toUpperCase();
        }
        let itemActionBan = document.createElement("button");
        itemActionBan.className = "profileManager__userItemActionBan";
        if (status !== "BANNED") {
            itemActionBan.innerText = "Banna";
            itemActionBan.addEventListener("click", () => {
                ApiInterface.banuser(profile.uid).then(async (res) => {
                    if (res.status !== 200) {
                        addInfo("alert", "Errore", "C'è stato un errore durante la richiesta!", 5)
                    } else {
                        addInfo("success", "Utente Bannato", "L'utente è stato bannato correttamente!", 5)
                    }
                });
            });
        } else {
            itemActionBan.innerText = "Sbanna";
            itemActionBan.addEventListener("click", () => {
                ApiInterface.unbanuser(profile.uid).then(async (res) => {
                    if (res.status !== 200) {
                        addInfo("alert", "Errore", "C'è stato un errore durante la richiesta!", 5)
                    } else {
                        addInfo("success", "Utente Sbannato", "L'utente è stato sbannato correttamente!", 5)
                    }
                });
            });
        }
        itemActions.appendChild(itemActionBan);

        let itemActionRole = document.createElement("div");
        itemActionRole.className = "profileManager__userItemActionRole";
        let roleSelect = document.createElement("select");
        roleSelect.className = "profileManager__userItemActionRoleSelect";
        let v = viewer;
        let viewerPower = 0;
        if (v !== undefined && v !== null) {
            viewerPower = viewer.role.power;
        }

        let userPower = profile.role["power"];

        for (let i = 0; i < roles.getLength(); i++) {
            let role = roles.getRole(i);
            let option = document.createElement("option");
            option.value = role["alias"];
            option.innerText = role["alias"];
            if (userPower > viewerPower) {
                option.disabled = true;
            } else {
                if (viewerPower <= role["power"]) {
                    option.disabled = true;
                }
            }
            if (role["alias"] === profile.role["alias"]) {
                option.selected = true;
            }
            roleSelect.appendChild(option);
        }
        roleSelect.addEventListener("change", () => {
            let newRole = roleSelect.value;
            ApiInterface.updateRole(profile.uid, newRole).then((res) => {
                if (res.status !== 200) {
                    addInfo("alert", "Errore", "C'è stato un errore durante l'aggiornamento del ruolo dell'utente!", 5)
                } else {
                    addInfo("success", "Ruolo aggiornato", "Il ruolo dell'utente è stato aggiornato correttamente!", 5)
                    updateUser(profile);
                }
            })
        });

        itemActionRole.appendChild(roleSelect);
        itemActions.appendChild(itemActionRole);

        item.appendChild(itemUsername);
        item.appendChild(itemEmail);
        item.appendChild(itemRole);
        item.appendChild(itemActions);

        return item;
    }

    async function preSearch() {
        let input = document.getElementById("searchUserInput").value;
        if (input.length === 0) {
            setSearch("");
        } else {
            setSearch(input);
        }
    }

    return (
        <>
            <Background/>
            <div className={"profilesManager__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"profilesManager__content"}>
                    <div className="profilesManager__main">
                        <div className={"profileManager__title"}>Gestione profili utente</div>
                        <div className={"profileManager__description"}>Qui puoi gestire i profili degli utenti</div>
                        <div className={"profileManager__search"}><input disabled={true} type={"text"}
                                                                         placeholder={"Attendi il caricamento"}
                                                                         id={"searchUserInput"} onInput={() => {
                            preSearch();
                        }}/></div>
                        <div className={"profileManager_totalAccounts"}>Utenti
                            registrati: {getTotalUsers === 0 ? <><span
                                className={"loadingCircleTiny"}/></> : getTotalUsers}</div>
                        <div className={"profileManager__userList"} id={"userList"}>

                        </div>
                        <div className={"profileManager__userPages"}>
                            <div id={"userPrevPages"}></div>
                            <div id={"userPages"}></div>
                            <div id={"userNextPages"}></div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}
export default ProfilesManager;