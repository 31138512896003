import React, {useEffect, useState} from 'react';
import {allFiles} from '@/commons/resources/tos/files';
import styles from './Tos.module.css';
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

interface FileType {
    date: string;
    path: string;
    file: any; // Consider specifying a more precise type if possible
}

interface DateListProps {
    dates: string[];
    onDateClick: (date: string) => void;
}

const PDFViewer: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<FileType | undefined>();

    const handleDateClick = (date: string) => {
        const file = allFiles.find(file => file.date === date);
        setSelectedFile(file);
        const dateButtons = document.getElementsByClassName(styles.dateButton)[0];
        if (!dateButtons) {
            return;
        }
        const allDates = dateButtons.children;

        for (let i = 0; i < allDates.length; i++) {
            allDates[i].classList.remove(styles.selected);
        }

        const selectedButton = document.getElementById("button_date_" + date) as HTMLButtonElement;
        if (selectedButton) {
            selectedButton.classList.add(styles.selected);
        }
    }

    useEffect(() => {
        if (allFiles.length > 0) {
            handleDateClick(allFiles[allFiles.length - 1].date);
        }
    }, []);

    return (
        <>
            <Background/>
            <div className={styles.tos__body}>
                <div className={"pdfMain"}>
                    <Header marginTop={2} marginBottom={2}/>
                    <div className={styles.tos__content}>
                        <div className={"pdfViewer"}>
                            {selectedFile && <PDF file={selectedFile}/>}
                        </div>
                        <div className={"pdfDate"}>
                            <DateList dates={allFiles.map(file => file.date)} onDateClick={handleDateClick}/>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

const DateList: React.FC<DateListProps> = ({dates, onDateClick}) => (
    <div className={styles.dateButton}>
        {dates.map((date: string) => (
            <button key={date} id={"button_date_" + date} onClick={() => onDateClick(date)}>
                Versione: {date}
            </button>
        ))}
    </div>
);

interface PDFProps {
    file: FileType;
}

const PDF: React.FC<PDFProps> = ({file}) => (
    <object data={file.path} type="application/pdf" height="1300px" width="100%">
        <p>Your browser does not support PDFs. Please download the PDF to view it: <a href={file.path}>Download PDF</a>
        </p>
    </object>
);

export default PDFViewer;