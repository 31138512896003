import Cookies from "js-cookie";
import './AccountHeader.css';
import {getDetails, getMinecraft, getToken, isLoginValid, validateLogin} from "@/commons/utils/others/Account";
import ApiInterface from "@/commons/api/ApiInterface";
import {getMinecraftHead} from "@/commons/utils/others/Users";
import {useEffect} from "react";
import ReactDOM from "react-dom";
import DropdownMenu from "@/commons/utils/Dropdown/DropdownMenu";
import DropdownElement from "@/commons/utils/Dropdown/DropdownElement";
import Redirects from "@/commons/utils/Redirects";
import { createRoot } from "react-dom/client";

import notificationImage from "@/commons/resources/images/header/notifications.webp";

const AccountHeader = () => {
    const notLoggedBtnHTML = '<a href="/access"><div class=loginButton>Accedi' +
        '</div></a>';

    async function loadUserButton() {
        let element = document.getElementById('accountHeader__account');
        let elementReact = createRoot(element);

        let token = getToken();
        let login = await validateLogin(token);
        let isStaff = await ApiInterface.isStaffer();
        let validLogin = isLoginValid(login);

        if (validLogin) {
            let mcData = await getMinecraft(token);
            let account = await getDetails(token);

            let accountJSON = await account.json();
            let accountName = accountJSON["username"];

            let finalSkin = await getMinecraftHead(mcData, accountName).then(value => value);

            let accountMenu = new DropdownMenu("accountHeader__", "Menu", <div className='accountHeader__avatarName'>
                <div className='accountHeader__avatar'><img src={finalSkin} alt={"profilePicture"}/></div>
                <span className='accountHeader__name'>{accountName}</span></div>, "");

            let adminPage = new DropdownElement("accountHeader__", <div>Admin</div>, (e) => {
                window.location.href = "/admin"
            });
            let accountProfile = new DropdownElement("accountHeader__", <div>Area Personale</div>, () => {
                window.location.href = "/profile"
            });
            let accountLogout = new DropdownElement("accountHeader__", <div>Logout</div>, () => {
                Cookies.set('token', null, {expires: 0, path: '/', domain: 'eblcraft.it'});
                Cookies.remove('token')
                window.location.href = "/";
            });

            accountMenu.addElement(accountProfile);
            if (isStaff) {
                accountMenu.addElement(adminPage);
            }
            accountMenu.addElement(accountLogout);


            elementReact.render(accountMenu.render())
        } else {
            element.innerHTML = notLoggedBtnHTML;
        }
    }

    async function loadNotifications() {
        let element = document.getElementById('accountHeader__notification');
        let elementReact = createRoot(element);

        let token = getToken();
        let login = await validateLogin(token);
        let validLogin = isLoginValid(login);

        if (validLogin) {
            let account = await getDetails(token);

            let accountJSON = await account.json();
            let accountName = accountJSON["username"];

            let notifications = await ApiInterface.getUnreadNotifications();
            let notificationsJSON = await notifications.json();
            let maxNotifications = notificationsJSON["totalPages"];

            let notificationMenu = new DropdownMenu("notificationHeader__", "Notifications", <div className='accountHeader__avatarName'>
                <div className='accountHeader__avatar'><img src={notificationImage} alt={"notificationImage"}/><span className={"notification_number"}>{maxNotifications}</span></div></div>, "");

            if (maxNotifications > 0) {
                let readAllElement = new DropdownElement("accountHeader__", <div
                    className={"accountHeader__notification_box"}>
                    <div className={"accountHeader__notification_title accountHeader__notification_readAll"}>Segna tutto come Letto
                    </div>
                </div>, () => {
                    ApiInterface.readAll().then(() => loadUserButton());
                });

                notificationMenu.addElement(readAllElement);
            }

            for (let i = 0; i < maxNotifications; i++) {
                let not = await ApiInterface.getUnreadNotifications(i, 1);
                let notJSON = await not.json();

                let notID = notJSON["content"][0]["id"];
                let notTitle = notJSON["content"][0]["title"];
                let notMessage = notJSON["content"][0]["message"];
                let notRedirect = notJSON["content"][0]["redirect"];
                let notTimeSeconds = notJSON["content"][0]["received"];

                let postId = notRedirect.split("/")[2]

                let notTime = new Date(notTimeSeconds * 1000);
                let notTimeFormatted = notTime.toLocaleString();

                let notificationElement = new DropdownElement("accountHeader__", <div
                    className={"accountHeader__notification_box"}>
                    <div className={"accountHeader__notification_title"}><span className={"accountHeader__notification_postId"}>#{postId} - </span>{notTitle}
                    </div>
                    <div className={"accountHeader__notification_text"}>{notMessage}</div>
                    <div className={"accountHeader__notification_time"}>{notTimeFormatted}</div>
                </div>, (event) => {
                    ApiInterface.readNotification(notID).then(
                        Redirects.send(notRedirect, event));
                });

                notificationMenu.addElement(notificationElement);
            }
            if (maxNotifications === 0) {
                let notificationElement = new DropdownElement("accountHeader__", <div
                    className={"accountHeader__notification_box"}>
                    <div className={"accountHeader__notification_title"}></div>
                    <div className={"accountHeader__notification_text"}>Nessuna Notifica</div>
                    <br/>
                </div>, () => {
                }, true);

                notificationMenu.addElement(notificationElement);
            }

            elementReact.render(notificationMenu.render())
        }
    }

    useEffect(() => {
        loadUserButton();
        loadNotifications()
    }, []);
    return (<>
            <div className={"accountHeader__container"} id={"accountHeader__account"}/>
            <div className={"accountHeader__container"} id={"accountHeader__notification"}/>
        </>
    )
}

export default AccountHeader;