class Post {
    static id;
    static category_id;
    static user_id;
    static title;
    static content;
    static status;
    static time;

    constructor(json) {
        if (typeof (json) != "object") {
            json = JSON.parse(json);
        }
        this.id = json["id"];
        this.category_id = json["categoryId"];
        this.user_id = json["userId"];
        this.title = json["title"];
        this.content = json["content"];
        this.status = json["status"];
        this.time = json["time"];

    }
}

export default Post;