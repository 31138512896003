import './ServerHelp.css';
import "@/commons/resources/styles/general.css"
import eblDERP from '@/commons/resources/images/emotes/eblDERP.webp'
import React from "react";

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

const Java = () => {
    return (
        <>
            <Background/>
            <div className={"serverHelp__body"}>
                <Header marginTop={1} marginBottom={1}/>
                <div className={"serverHelp__content"}>
                    <div className={"info-mainBox"}>
                        <div className={"boxTitle"}>
                            Come entro in EBLCraft da Java Edition?
                        </div>
                        <div className={"boxContent"}>
                            <>
                                <img src={eblDERP} alt={"Bedrock"} className={"eblDERP"}/><br/>
                            </>
                            <div className={"text"}>EBLCraft si sta evolvendo! Proprio come i pokemon nella nuova
                                modalità coEBLmon!
                                <br/>Se vuoi vivere un avventura in stile pokemon puoi seguire questo tutorial su come
                                entrare nel server! <a href={"https://www.youtube.com/watch?v=xfGNgZQIBn0"}>Clicca
                                    qui</a><br/>
                                In questo caso ti basterà aprire il modpack e sarà già tutto pronto per te!
                            </div>
                            <br/><br/>
                            <div className={"text"}>Se invece ti piace il caro classico Vanilla segui questi
                                passaggi:<br/><br/>
                                Avvia minecraft con la versione 1.20.1.<br/>
                                Apri il menu multiplayer e clicca su "Add Server".<br/>
                                Inserisci l'ip <span
                                    style={{"color": "green", "font-weight": "bold"}}>play.eblcraft.it</span><br/><br/>
                                Se hai seguito tutti i passaggi correttamente potrai entrare nel server!<br/><br/>
                            </div>
                            <div className={"text"}>Che aspetti?! Noi aspettiamo solamente te!</div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default Java