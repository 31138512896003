import {APPS} from "@/appsManager/costants";
import {getSubDomain} from "@/commons/utils/Domains";

export const getApp = () => {
    const subdomain = getSubDomain(window.location.hostname);

    const mainApp = APPS.find(app => app.main);
    if (!mainApp) throw new Error("MUST HAVE A MAIN APP!")

    if (subdomain === "") return mainApp.app;

    const app = APPS.find(app => subdomain === app.subdomain)
    if (!app) return mainApp.app;
    return app.app;
}