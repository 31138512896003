class Category {
    static id;
    static name;
    static usable;
    static hidden;
    static formattedForm;

    static orderId;

    constructor(json) {
        if (typeof (json) != "object") {
            json = JSON.parse(json);
        }

        this.id = json["id"];
        this.name = json["name"];
        this.usable = json["usable"];
        this.hidden = json["hidden"];
        this.orderId = json["orderId"];
        this.formattedForm = json["formattedForm"];
    }
}

export default Category;