import React, {useEffect, useState} from 'react';

// @ts-ignore
const ZoomImageModal = ({imageSrc, onClose}) => {
    const [zoom, setZoom] = useState(1);

    function zoomImage() {
        setZoom(Math.round((zoom + 0.2) * 10) / 10)
    }

    function deZoomImage() {
        setZoom(Math.round((zoom - 0.2) * 10) / 10)
    }

    useEffect(() => {
        let zoomableImage = document.getElementById("zoomableImage");
        if (zoomableImage) {
            zoomableImage.style.transform = "scale(" + zoom + ")";
        }
    }, [zoom]);

    return (
        <div className="postBigImageBackground" onClick={onClose}>
            <div
                className="imageContainer"
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className="imageButtons">
                    <button className="imageZoom" onClick={zoomImage}>+</button>
                    Zoom: {zoom}
                    <button className="imageZoom" onClick={deZoomImage}>-</button>
                </div>
                <div className="imageBox">
                    <img id="zoomableImage" src={imageSrc} alt={"image"}></img>
                </div>
            </div>
        </div>
    );
};

export default ZoomImageModal;