import {useParams} from 'react-router-dom';
import ApiInterface from "@/commons/api/ApiInterface";
import React, {useEffect} from "react";
import {sha256Encrypt} from "@/commons/utils/others/Encyption";

import "./style.css";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

const RecoverPassword = () => {
    let {token} = useParams();

    async function verifyPasswordChange() {
        let text = (document.getElementById("passwordChangeText") as HTMLElement);
        ApiInterface.getToken(token).then(async (res) => {
            if (res.status !== 200) {
                if (res.status === 408) {
                    text.innerHTML = "" +
                        "<h3>Token Scaduo</h3>" +
                        "<p>Il token è scaduto, prova a richiederne uno nuovo.</p>";
                } else {
                    text.innerHTML = "" +
                        "<h3>Token Non Valido</h3>" +
                        "<p>Il token non è valido, prova a richiederne uno nuovo.</p>";
                }
            }
            let result = await res.json();
            let scope = result["scope"];

            if (scope !== "PASSWORD_RESET") {
                text.innerHTML = "" +
                    "<h3>Token Non Valido</h3>" +
                    "<p>Il token non è valido, prova a richiederne uno nuovo.</p>";
            }

            let inputPassword = document.createElement("input");
            inputPassword.type = "password";
            inputPassword.id = "password";
            inputPassword.placeholder = "Password";

            let inputPasswordConfirm = document.createElement("input");
            inputPasswordConfirm.type = "password";
            inputPasswordConfirm.id = "passwordConfirm";
            inputPasswordConfirm.placeholder = "Conferma Password";

            let button = document.createElement("button");
            button.innerHTML = "Cambia Password";
            button.id = "changePasswordButton";
            button.onclick = () => {
                changePassword();
            }

            let br = document.createElement("br");

            text.innerHTML = "";

            text.appendChild(inputPassword);
            text.appendChild(br);
            text.appendChild(inputPasswordConfirm);
            text.appendChild(br);
            text.appendChild(button);
        });
    }

    async function changePassword() {
        let button = (document.getElementById("changePasswordButton") as HTMLButtonElement);
        let text = (document.getElementById("passwordChangeText") as HTMLElement);
        button.classList.add("button--loading");
        button.disabled = true;

        let password = (document.getElementById("password") as HTMLInputElement).value;
        let passwordConfirm = (document.getElementById("passwordConfirm") as HTMLInputElement).value;

        if (password !== passwordConfirm) {
            button.classList.remove("button--loading");
            return;
        }

        let p = await sha256Encrypt(password);

        await ApiInterface.recoverPassword(token, p).then(async (verificationRes) => {
            if (verificationRes.status !== 200) {
                button.style.backgroundColor = '#7b3a3a';
                button.disabled = false;
                button.classList.remove("button--loading");
            } else {
                button.style.backgroundColor = '#307239';
                button.disabled = true;
                button.innerText = 'Inviato';
                button.classList.remove("button--loading");

                text.innerHTML = "" +
                    "<h3>Password Modificata</h3>" +
                    "<p>La tua password è stata modificata.</p>";
            }
        }).catch((err) => {
            button.style.backgroundColor = '#7b3a3a';
            button.disabled = true;
            button.classList.remove("button--loading");
        });
    }

    useEffect(() => {
        verifyPasswordChange();
    });
    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div>
                        <h1>Recupera Password</h1><br/><br/>
                        <div id={"passwordChangeText"} className={"formBoxRecover"}>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default RecoverPassword;