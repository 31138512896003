import Cookies from "js-cookie";
import {environment} from '@/commons/configs/Config';


export async function getCategories() {
    const userToken = Cookies.get(environment.tokenName);
    const result = fetch(environment.apiUrlOne + '/category/getAll/', {
        method: 'GET', headers: {
            'Authorization': userToken
        }
    })

    const rest = await result;
    return rest
}