import ApiInterface from "../../api/ApiInterface";

class Role {
    constructor(alias, power) {
        this.alias = alias;
        this.power = power;
        this.loadBadge();
    }

    async loadBadge() {
        let badge = await ApiInterface.getBadge(this.alias);
        this.roleBadge = await badge.text();
    }

    getBadge() {
        return this.roleBadge;
    }
}

export default Role;