import './Error.css';
import eblMON from '@/commons/resources/images/emotes/eblMON.webp';
import React, {useEffect} from "react";
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

const Error = (props : React.ComponentProps<any>) => {
    let errorProp : string | undefined = props.errorCode;

    let error: string | undefined;
    console.log(errorProp)
    if(errorProp === undefined){
        if (localStorage.errorName !== undefined) {
            error = localStorage.errorName;
        }
        if (error === undefined) {
            error = "pageerror";
        }
    }else{
        error = errorProp;
    }
    let pageName = "Errore";
    let pageTitle = "Errore Sconosciuto"
    let pageMessage = "Messaggio d'errore non disponibile";
    let finalMessage = "Se il problema dovesse persistere in maniera prolungata contatta un membro dello staff.";
    let errorCode = "#ENDATS";
    let image;
    error = error.toLowerCase();

    async function showError() {
        switch (error) {
            case "apierror":
                pageName = "Errore Interno";
                pageTitle = "Service Error";
                pageMessage = "Si è verificato un problema interno dei nostri servizi.<br/>Al momento non è possibile utilizzare il sito."
                errorCode = "#APIEOL";
                image = eblMON;
                break;
            case "maperror":
                pageName = "Map Error";
                pageTitle = "Errore Mappa - Server offline";
                pageMessage = "Il server della mappa che vuoi vedere è attualmente offline<br/>Riprova più tardi."
                errorCode = "#MASEOF";
                image = eblMON;
                break;
            case "notfound":
            default:
                pageName = "Errore 404";
                pageTitle = "Errore 404";
                pageMessage = "La pagina che stavi cercando non è stata trovata.<br/>Assicurati che l'indirizzo inserito sia corretto."
                errorCode = "#PAGENF";
                image = eblMON;
                break;
        }

        (document.getElementById("errorTitle") as HTMLElement).innerHTML = "<div class='errorTitle'>" + pageTitle + "</div>";
        (document.getElementById("pageMessage") as HTMLElement).innerHTML = "<div class='errorInfo'>" + pageMessage + "</div>";
        (document.getElementById("errorImage") as HTMLImageElement).src = image;
        (document.getElementById("finalMessage") as HTMLElement).innerHTML = finalMessage;
        (document.getElementById("errorCode") as HTMLElement).innerHTML = errorCode;

        document.title = pageName;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        showError().then(r => console.log("Error page loaded"));
    });

    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={2} marginBottom={2}/>
                <div className={"page__content"}>
                    <div className="error">
                        <div className="error__container">
                            <h1 className="error__title" id={'errorTitle'}>Errore 404</h1>
                            <img className="error__img" id={'errorImage'} src={eblMON} alt="errorImage"/><br/><br/><br/>
                            <p className="error__text" id={'pageMessage'}>La pagina che stavi cercando non esiste</p>
                            <br/><br/>
                            <p className="error__finalMessage" id={'finalMessage'}>La pagina che stavi cercando non
                                esiste</p><br/><br/>
                            <p className="error__finalMessage" id={'errorCode'}>PAGNTF</p>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    )
}

export default Error;