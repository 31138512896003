import './ServerPage.css';

import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";

import previewVanilla from "@/commons/resources/images/servers/vanilla/preview.png";
import previewCobblemon from "@/commons/resources/images/servers/cobblemon/preview.png";
import previewFortcraft from "@/commons/resources/images/servers/fortcraft/preview.png";

import ServersList from "./objects/Main/ServersList";
import ServerObj from "./objects/Main/ServerObj";
import React from 'react';

const ServerPage = () => {
    let servers = new ServersList();

    let vanillaServer = new ServerObj("Vanilla",
        "La modalità Vanilla è la più vecchia e classica modalità di minecraft," +
        "ma grazie all'intervento dei nostri Developer siamo riusciti a renderla unica e spettacolare." +
        "<br/>Partiamo dal punto fondamentale: NON è un mondo unico." +
        "<br/>Potrete infatti trovare mondo1 e mondo2, due mondi dedicati alla creazione di città e ai contest." +
        "<br/>Abbiamo lo Stargate, un mondo dedicato unicamente al farming dove tutto è permesso.",
        previewVanilla, "/server/vanilla");

    let cobblemonServer = new ServerObj("CoEBLmon",
        "La cobblemon, una nuova modalità con un sistema completamente nuovo di pokemon, " +
        "<br/>Ci sono i pokemon BELLIII CUSTOM E FIGHI",
        previewCobblemon, "/server/cobblemon");

    let fortcraftServer = new ServerObj("FortCraft",
        "La Fortcraft è una modalità completamente custom e unica del suo genere!<br/>" +
        "Creata dai nostri Developer la Fortcraft riprende lo stile di gioco di Fortnite™ e lo porta su Minecraft!",
        previewFortcraft, "/server/fortcraft");

    servers.addServer(vanillaServer);
    servers.addServer(cobblemonServer);
    servers.addServer(fortcraftServer);

    return (
        <>
            <Background/>
            <div className={"serverPage__body"}>
                <Header marginTop={2} marginBottom={5}/>
                <>
                    {servers.renderer()}
                </>
                <Footer/>
            </div>
        </>
    );

}

export default ServerPage;