import {useParams} from "react-router-dom";
import ApiInterface from "@/commons/api/ApiInterface"
import React, {useEffect, useState} from "react";
import './User.css';
import Background from "@/commons/pages/borders/background/Background";
import Header from "@/commons/pages/borders/header/Header";
import Footer from "@/commons/pages/borders/footer/Footer";
import Redirects from "@/commons/utils/Redirects"
import {getMinecraftOther, getTelegramOther, getTwitchOther,} from "@/commons/utils/others/Account";

const User = () => {
    let {id} = useParams();
    let [user, setUser] = useState();

    const verifiedEmail = () => {

    }

    async function loadUser() {
        const rb = await ApiInterface.getBadge(user.role.alias);
        const roleBadge = await rb.text();
        const imgSrc = `data:image/png;base64,${roleBadge}`;

        const usView = (
            <div className="user-view">
                <h3 className="connection-title">Account EBLNetwork</h3>
                <div className="badge-username">
                    <img src={imgSrc} alt="roleBadge" className="role-badge"/>
                    <span className="username selectable">{user.username}</span>
                </div>
                <div className="email selectable">Email: {user.email} {user.emailVerified === true ? "✅" : "❌"}</div>
                <div className="status">Stato: {user.status === "banned" ?
                    <span className="status-red">Utente bannato</span> :
                    <span className="status-green">Nessuna restrizione</span>}</div>
            </div>
        );
        setUserView(usView);
    }

    async function loadTwitch() {
        const twitchInfo = await getTwitchOther(user.id);

        let twitchName = undefined;
        let subStatus = undefined;
        let subTier = undefined;
        let isVip = undefined;
        let twBanned = undefined;
        if (twitchInfo.status === 200) {
            const twitchJson = await twitchInfo.json();
            twitchName = twitchJson.login;
            subStatus = twitchJson.subActive;
            subTier = twitchJson.tier;
            isVip = twitchJson.vip;
            twBanned = twitchJson.banned;
        }

        const twConn = (
            <div className="twitch-connection">
                <h3 className="connection-title">Account Twitch</h3>
                <div className="connection-info selectable">Username: {twitchName ||
                    <span className="missing-data">❌</span>}</div>
                <div className="connection-info">Stato: {twBanned ?
                    <span className="status-red">Account bannato</span> :
                    <span className="status-green">Nessuna restrizione</span>}</div>
                <div className="connection-info">Sub: {subStatus ?
                    <span className="sub-status-green">attiva (livello: {subTier / 1000})</span> :
                    <span className="sub-status-red">non attiva</span>}</div>
                <div className="connection-info">Vip: {isVip ? <span className="status-green">attivo</span> :
                    <span className="status-red">non attivo</span>}</div>
            </div>
        );
        setTwitchConnection(twConn);
    }

    async function loadMinecraft() {
        const minecraftInfo = await getMinecraftOther(user.id);
        let minecraftJson = undefined;
        let mcFirstLogin = undefined;
        let mcLastLogout = undefined;
        let mcPastNames = undefined;
        let mcName = undefined;
        let id = undefined;
        if (minecraftInfo.status === 200) {
            minecraftJson = await minecraftInfo.json();
            mcFirstLogin = minecraftJson.firstLogin;
            mcLastLogout = minecraftJson.lastLogout;
            mcPastNames = minecraftJson.pastUsernames;
            id = minecraftJson._id;
            mcName = minecraftJson.username;
        }

        const mcConn = (
            <div className="minecraft-connection">
                <h3 className="connection-title">Account Minecraft</h3>
                <div className="connection-info selectable">Username: {mcName ||
                    <span className="missing-data">❌</span>}</div>
                <div className="connection-info selectable">UUID: {id || <span className="missing-data">❌</span>}</div>
                <div className="connection-info selectable">Primo login: {formatDateTime(mcFirstLogin) ||
                    <span className="missing-data">❌</span>}</div>
                <div className="connection-info selectable">Ultimo logout: {formatDateTime(mcLastLogout) ||
                    <span className="missing-data">❌</span>}</div>
                {renderPastNames(mcPastNames)}
            </div>
        );
        setMinecraftConnection(mcConn);
    }

    async function loadTelegram() {
        const telegramInfo = await getTelegramOther(user.id);
        let tgName = undefined;
        let telegramJson = undefined;
        if (telegramInfo.status === 200) {
            telegramJson = await telegramInfo.json();
            tgName = telegramJson.username;
        }


        const tgConn = (
            <div className="telegram-connection">
                <h3 className="connection-title">Account Telegram</h3>
                <div className="connection-info selectable">Username: {tgName ||
                    <span className="missing-data">Username non registrato❌</span>}</div>
                {(tgName) ? (
                    <button onClick={(e) => Redirects.send("https://t.me/" + tgName, e)}>Scrivi all'utente</button>) : (
                    <span className="missing-data">❌Non Contattabile❌</span>)}
            </div>
        );

        setTelegramConnection(tgConn);
    }

    async function viewData() {
        loadUser();
        loadTwitch();
        loadMinecraft();
        loadTelegram();
    }


    function formatDateTime(dateString) {
        if (dateString == undefined)
            return "";
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    const renderPastNames = (mcPastNames) => {
        if (!mcPastNames) {
            return <div className="connection-info names">
                Nomi passati:<span className="missing-data">❌</span></div>;
        }

        const names = mcPastNames.toString().split(',');

        return (
            <div className="connection-info names">
                Nomi passati:
                {names.map((name, index) => (
                    <span key={index}>
          {name.trim()}
                        {(index + 1) % 2 === 0 && <br/>} {/* Aggiunta di <br /> ogni 2 nomi */}
        </span>
                ))}
            </div>
        );
    };

    useEffect(() => {
        async function loadUser() {
            const tUser = await ApiInterface.getUser(id);
            const u = await tUser.json();
            setUser(u);
        }

        loadUser();
    }, [id]);

    useEffect(() => {
        if (user != null) {
            viewData();
        }
    }, [user]);

    const [userView, setUserView] = useState(null);
    const [twitchConnection, setTwitchConnection] = useState(null);
    const [minecraftConnection, setMinecraftConnection] = useState(null);
    const [telegramConnection, setTelegramConnection] = useState(null);

    return (
        <>
            <Background/>
            <div className={"page__body"}>
                <Header marginTop={1} marginBottom={1}/>
                <div className={"page__content"}>
                    <div className={"info-container"}>
                        <h1 className={"info-container-title"}>
                            Pagina Utente
                            <br/>
                            {user !== undefined && user !== null ? user.username : ''}</h1>
                        <button onClick={(e) => {
                            Redirects.send("/profiles/", e)
                        }} className={"user_tickets-back-button"}>⬅ Indietro
                        </button>
                        {userView}
                        {twitchConnection}
                        {minecraftConnection}
                        {telegramConnection}
                    </div>
                    <div className={"info-container"}>
                        <button className={"info-container-button"} onClick={(e) => {
                            Redirects.send(`/user/tickets/${id}`, e)
                        }}>Visualizza Reports
                        </button>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
};

export default User;
